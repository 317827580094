<template>
    <div class="flex items-center min-h-[72px] py-3 px-1 sm:px-3 gap-2 box-border">
        <div class="w-12 h-12 bg-sky-100 rounded-full flex-none flex items-center justify-center">
            <!-- <Icon class="w-8 h-8 rounded-full p-2" :icon="icon"></Icon> -->
            <FontIcon
                :icon="design.icon"
                :size="20"
                class="text-primary-default"
            />
        </div>

        <div class="min-w-0 pl-1 sm:pl-2 w-full" :class="[expanded ? 'border-l border-light' : '']">
            <div class="flex items-center justify-between truncate">
                <div class="min-w-0">
                    <div class="leading-5 space-x-3">
                        <span class="font-medium">{{ design.title }} </span>
                    </div>
                    <div
                        class="flex items-center gap-x-2 text-sm truncate min-w-0 rounded-md leading-4 text-light-gray whitespace-nowrap mt-1"
                    >
                        <div class="flex place-self-start items-center gap-x-2">
                            <span class="">{{ $d(date, isToday ? "short" : "long") }}
                                {{ new Date(date).toLocaleTimeString(undefined, { hour: 'numeric', minute: '2-digit', hour12: true }) }}
                            </span>
                            <div class="w-1 h-1 flex-shrink-0 rounded-full bg-light-gray"></div>
                        </div>
                        <span class="truncate min-w-0 whitespace-pre-line">{{ design.description }}</span>
                    </div>
                </div>
            </div>
            <!-- <Transition
                enter-active-class="transition duration-100 ease-out overflow-hidden"
                leave-active-class="transition duration-100 ease-in overflow-hidden"
                leave-from-class="transform scale-100 opacity-100"
                leave-to-class="transform scale-95 opacity-0"
                enter-from-class="transform scale-95 opacity-0"
                enter-to-class="transform scale-100 opacity-100"
            >
                <div class="flex flex-col mt-2 text-sm font-" v-if="expanded === true">
                    <div class="flex bg-gray-50 rounded-lg mb-4">
                        <div class="basis-1/2 leading-6 border-r border-light p-2">
                            <div class="flex items-center gap-3">
                                <Icon class="w-3 h-3" :icon="'info'"></Icon>
                                <span>Details:</span>
                            </div>

                            <div class="text-gray-400 font-light text-xs pt-3">
                                {{ description }}
                            </div>
                        </div>
                    </div>
                </div>
            </Transition> -->
        </div>
    </div>
</template>
<script lang="ts">
import Badge from "@/components/Badge.vue";
import FontIcon from "@/ui/FontIcon.vue";
import { format, isEqual } from "date-fns";
import { PropType, defineComponent } from "vue";
import Icon from "../components/Icon.vue";
import { Event } from "../lib/events/events";

interface style {
    icon: string;
    title: string;
    description: string;
}

export default defineComponent({
    name: "EventCard",
    components: {
        Icon,
        Badge,
        FontIcon,
    },
    props: {
        event: {
            type: {} as PropType<Event>,
            required: true,
        },
    },
    data() {
        return {
            expanded: false,
            date: new Date(this.event.timestamp),
        };
    },
    methods: {
        handleClick() {
            this.expanded = !this.expanded;
        },
    },
    computed: {
        iconBgColor(): string {
            switch (this.event?.type) {
            case "final-report":
            case "screening":
                return "bg-red-100";
            case "self-declaration":
            case "id-control":
                return "bg-sky-100";
            case "add-person":
                return "bg-gray-100";
            default:
                return "bg-gray-100";
            }
        },
        isCurrentMonth(): boolean {
            if (this.date.getMonth() === new Date().getMonth()) {
                return true;
            }
            return false;
        },
        isToday(): boolean {
            return this.date ? isEqual(format(this.date, "yyyy-MM-dd"), format(new Date(), "yyyy-MM-dd")) : false;
        },
        design() : style {
            const data = this.event.data;
            switch (this.event.type) {
            case "project_created":
                return {
                    icon: "briefcase",
                    title: data?.resource?.name,
                    description: this.$t("eventText.customerCreated"),
                } as style;
            case "project_updated": {
                return {
                    icon: "briefcase",
                    title: data?.resource?.name,
                    description: this.$t("eventText.customerUpdated"),
                } as style;
            }
            case "person_created":
                return {
                    icon: "user-plus",
                    title: data?.resource?.name,
                    description: this.$t("eventText.newPersonAdded"),
                } as style;
            case "project_company_lookup":
                return {
                    icon: "magnifying-glass",
                    title: data?.resource?.name,
                    description: this.$t("eventText.companyLookupWitBlocks",
                        { blocks: data?.lookup?.blocks?.map((block: string) => this.$t("project.tabs.company.blocks.refereshTab." + block)).join(", ") }),
                } as style;

            case "link_created":
                return {
                    icon: "people-arrows",
                    title: `${data?.project?.name} ↔ ${data?.person?.name}`,
                    description: this.$t("eventText.personLinkedToProjectWithRoles",
                        { personName: data?.person?.name, projectName: data?.project?.name, roles: data?.roles?.map((role: string) => this.$t("core.roles." + role)).join(", ") }),
                } as style;
            case "link_updated":
                let msg = "";
                if (data?.roles?.added?.length) {
                    msg += this.$t("eventText.rolesAdded", { rolesAdded: data?.roles?.added?.map((role: string) => this.$t("core.roles." + role)).join(", "), issuerName: data?.issuer?.name });
                }
                if (data?.roles?.removed?.length) {
                    if (msg.length) {
                        msg += "\n";
                    }
                    msg += this.$t("eventText.rolesRemoved", { rolesRemoved: data?.roles?.removed?.map((role: string) => this.$t("core.roles." + role)).join(", "), issuerName: data?.issuer?.name });
                }
                return { icon: "people-arrows", title: `${data?.project?.name} ↔ ${data?.person?.name}`, description: msg } as style;
            case "person_monitor_toggled":
                return {
                    icon: data?.monitor ? "magnifying-glass-plus" : "magnifying-glass-minus",
                    title: this.$t("screening.title"),
                    description: data?.monitor
                        ? this.$t("eventText.personMonitoringStarted", { resourceName: data?.resource?.name })
                        : this.$t("eventText.personMonitoringStopped", { resourceName: data?.resource?.name }),
                };
            case "assessment_sealed":
                return {
                    icon: "file-zipper",
                    title: data?.project?.name,
                    description: this.$t("eventText.assessmentSealed"),
                } as style;
            case "assessment_unlocked":
                return {
                    icon: "lock-open",
                    title: data?.project?.name,
                    description: this.$t("eventText.assessmentUnlocked"),
                } as style;
            case "approver_approved": {
                const approvalName =
                this.$ct(this.$config.settings.approvalConfigs?.find(a => a.key === data?.approval?.name)?.name);
                return {
                    icon: "check",
                    title: data?.project?.name,
                    description: this.$t("eventText.approvalApproved", { approvalName: approvalName }),
                } as style;
            }
            case "approval_started":
            {
                const approvalName =
                this.$ct(this.$config.settings.approvalConfigs?.find(a => a.key === data?.approval?.name)?.name);
                return {
                    icon: "bell-concierge",
                    title: data?.project?.name,
                    description: this.$t("eventText.approvalStarted", { approvalName: approvalName }),
                } as style;
            }
            case "assessment_locked":
            {
                const approvalName =
                this.$ct(this.$config.settings.approvalConfigs?.find(a => a.key === data?.approval?.name)?.name);
                return {
                    icon: "lock",
                    title: data?.project?.name,
                    description: this.$t("eventText.assessmentLocked", { approvalName: approvalName }),
                } as style;
            }

            case "approver_denied":
            {
                const approvalName =
                this.$ct(this.$config.settings.approvalConfigs?.find(a => a.key === data?.approval?.name)?.name);
                return {
                    icon: "xmark",
                    title: data?.project?.name,
                    description: this.$t("eventText.approvalDenied", { approvalName: approvalName }),
                } as style;
            }

            case "approval_aborted": {
                const approvalName =
                this.$ct(this.$config.settings.approvalConfigs?.find(a => a.key === data?.approval?.name)?.name);
                return {
                    icon: "xmark",
                    title: data?.project?.name,
                    description: this.$t("eventText.approvalAborted", { approvalName: approvalName }),
                } as style;
            }

            case "form_completed":
                return {
                    icon: "file-circle-check",
                    title: data?.form?.name,
                    description: this.$t("eventText.formCompleted", { formName: data?.form?.name }),
                } as style;

            case "form_saved" :
                return {
                    icon: "file-circle-check",
                    title: data?.form?.name,
                    description: this.$t("eventText.formSaved", { formName: data?.form?.name }),
                } as style;

                case "form_submitted" :
                return {
                    icon: "file-circle-check",
                    title: data?.form?.name,
                    description: this.$t("eventText.formSubmitted", { formName: data?.form?.name }),
                } as style;

            default:
                return {
                    icon: "envelope",
                    title: this.event.type,
                    description: "",
                } as style;
            }
        },
    },
});
</script>
<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s ease;
}
.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
