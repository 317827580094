<template>
    <PopupDialog
        :is-visible="true"
        :header="$t('persons.addPerson')"
        :icon="'person'"
        :show-cancel-button="true"
        :show-action-button="true"
        :action-text="$t('common.actions.add')"
        :action-icon="'plus'"
        :is-action-disabled="isValidToSubmit"
        :progress="dialog"
        @action="addPerson"
        @close-modal="closeAddPersonModal"
    >
        <div class="add-person-popup w-full border border-solid border-light rounded-t-lg p-5">
            <div class="grid grid-cols-2 gap-3">
                <InputGroup :width="12">
                    <InputLabel
                        :text="$t('common.person_info.firstName')"
                        :unique-id="'first-name'"
                        required
                    />
                    <InputText
                        v-model="givenName"
                        :unique-id="'given-name'"
                        :placeholder="'John'"
                        required
                    />
                </InputGroup>
                <InputGroup :width="12">
                    <InputLabel
                        :text="$t('common.person_info.lastName')"
                        :unique-id="'last-name'"
                        required
                    />
                    <InputText
                        v-model="familyName"
                        :unique-id="'family-name'"
                        :placeholder="'Doe'"
                        required
                    />
                </InputGroup>

                <InputGroup :width="12">
                    <InputLabel :text="$t('common.person_info.dateOfBirth')" :unique-id="'date-of-birth'" />
                    <div class="">
                        <DatePicker v-model="dateOfBirth" />
                    </div>
                </InputGroup>

                <InputGroup :width="12">
                    <SelectBox
                        v-model="roles"
                        :label="$t('persons.role')"
                        :options="roleOptions"
                        :show-flag="false"
                        :placeholder="$t('common.placeholders.selectRole')"
                        required
                    />
                </InputGroup>
            </div>
        </div>
    </PopupDialog>
</template>

<script lang="ts">
import PopupDialog from "@/ui/PopupDialog.vue";
import DatePicker from "@/components/DatePicker.vue";
import { createPerson } from "@/lib/persons/create_person";
import { createRole } from "@/lib/role/create_role";
import { RoleCode, RoleDescriptionWithoutSource } from "@/lib/role/Role";
import { defineComponent } from "vue";
import InputLabel from "@/ui/inputs/InputLabel.vue";
import InputText from "@/ui/inputs/InputText.vue";
import InputGroup from "@/ui/inputs/InputGroup.vue";
import SelectBox from "@/components/SelectBox.vue";
import { PersonInfoBlock } from "@/lib/persons/person";
import { updateAsKeyRole } from "@/lib/role/update_role";
import { validateDateOfBirth } from "@/lib/date_object_formatter";

export default defineComponent({
    name: "AddPersonPopup",
    components: {
        DatePicker,
        InputLabel,
        InputText,
        InputGroup,
        SelectBox,
        PopupDialog,
    },
    props: {},
    data() {
        return {
            givenName: "",
            familyName: "",
            roles: [],
            dateOfBirth: "",
            RoleCode,
            dialog: { visible: true, valid: false, isWorking: false, statusText: "", isError: false, errorText: "" },
        };
    },
    emits: ["close-modal", "refreshPersons"],
    computed: {
        roleOptions(): { label: string; value: string }[] {
            const roles: { label: string; value: string }[] = [];
            for (const key in RoleCode) {
                if (RoleCode.hasOwnProperty(key)) {
                    const val = RoleCode[key as keyof typeof RoleCode] as string;
                    roles.push({ label: this.$t(`core.roles.${val}`), value: val });
                }
            }
            return roles;
        },
        isValidToSubmit(): boolean {
            return !this.givenName || !this.familyName || (Array.isArray(this.roles) ? !this.roles.length : !this.roles) || !this.isDateOfBirthValid;
        },
        isDateOfBirthValid(): boolean {
            if (this.dateOfBirth) {
                return validateDateOfBirth(this.dateOfBirth).isValid;
            }
            return true;
        },
    },
    methods: {
        closeAddPersonModal() {
            this.$emit("close-modal");
        },
        async addPerson() {
            try {
                this.dialog.isWorking = true;
                this.dialog.isError = false;
                if (!this.givenName || !this.familyName || (Array.isArray(this.roles) ? !this.roles.length : !this.roles)) {
                    return;
                }

                let dateObject;
                if (this.dateOfBirth) {
                    const dob = new Date(this.dateOfBirth);
                    dateObject = {
                        year: dob.getFullYear(),
                        month: dob.getMonth() + 1,
                        day: dob.getDate(),
                    };
                }

                const options: PersonInfoBlock = {
                    givenName: this.givenName,
                    familyName: this.familyName,
                    dateOfBirth: dateObject,
                };
                const person = await createPerson(options);
                const finalRole = Array.isArray(this.roles)
                    ? this.roles.map((r) => {
                        const roleDes: RoleDescriptionWithoutSource = { key: r as unknown as RoleCode };
                        return roleDes;
                    })
                    : [{ key: this.roles as unknown as RoleCode }];
                const projectId = this.$route.params.id.toString();
                await createRole(this.$route.params.id.toString(), person.id, finalRole);
                await updateAsKeyRole(projectId, person.id, true);
                this.$emit("refreshPersons");
                this.$emit("close-modal");
                this.dialog.isWorking = false;
            } catch {
                this.dialog.isError = true;
                this.dialog.errorText = "Unknown Error";
            }
        },
    },
});
</script>
<style>
.amlv3-dialog-content:has(.add-person-popup) {
    overflow: visible !important;
}
</style>
