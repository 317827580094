import i18n from "@/i18n";
import { AssessmentValue, IndicatorValue } from "@/lib/assessments/assessments";
import { RiskSource, RiskValueSource, RiskValueType } from "@/lib/risk/risk_matrix";
import { RegularSettings, RiskConfig, RiskThreshold } from "@/lib/settings/settings";
import { RiskAssets } from "./risk_assets";
import { getNaceCodeDescription } from "./special_types/nace_code";

export interface RiskScoreResult {
    score: number;
    isIndirect: boolean;
}

export const getRandomString = (length: number): string => {
    const chars = "abcdefghijklmnopqrstuvwxyz";
    let str = "";
    for (let i = 0; i < length; i++) {
        str += chars[Math.floor(Math.random() * (chars.length - 1) + 1)];
    }
    return str;
};

export const getValueTypeName = (valueType: RiskValueType): string => {
    const { t } = i18n.global;
    switch (valueType) {
    case RiskValueType.FreeText: return t("assessment.indicators.valueTypeName.freeText");
    case RiskValueType.Number: return t("assessment.indicators.valueTypeName.number");
    case RiskValueType.YesNo: return t("assessment.indicators.valueTypeName.yes/no");
    case RiskValueType.MultipleChoice: return t("assessment.indicators.valueTypeName.multipleChoice");
    case RiskValueType.DateAge: return t("assessment.indicators.valueTypeName.dateAge");
    case RiskValueType.Country: return t("assessment.indicators.valueTypeName.country");
    case RiskValueType.NaceCode: return t("assessment.indicators.valueTypeName.businessCode");
    case RiskValueType.HighRiskCountry: return t("assessment.indicators.valueTypeName.highRiskCountry");
    case RiskValueType.PepHit: return t("assessment.indicators.valueTypeName.pepHit");
    case RiskValueType.Listed: return t("assessment.indicators.valueTypeName.listed");
    case RiskValueType.CompanyType: return t("assessment.indicators.valueTypeName.companyType");
    default: return t("assessment.indicators.valueTypeName.unknown");
    }
};

export const getManualDisplayValue = (value: AssessmentValue, valueType: RiskValueType, assets: RiskAssets): string => {
    const { t } = i18n.global;

    if (valueType === RiskValueType.FreeText) {
        return value.value;
    }

    if (valueType === RiskValueType.Number) {
        return value.value;
    }

    if (valueType === RiskValueType.YesNo) {
        return value.value ? t("common.yes") : t("common.no");
    }

    if (valueType === RiskValueType.MultipleChoice) {
        return value.value;
    }

    if (valueType === RiskValueType.DateAge) {
        return value.value;
    }

    if (valueType === RiskValueType.Country) {
        return t("countries." + value.value);
    }

    if (valueType === RiskValueType.NaceCode) {
        return value.value + ": " + getNaceCodeDescription(value.value, assets, "en_EN");
    }

    if (valueType === RiskValueType.Listed) {
        return value.value ? t("common.yes") : t("common.no");
    }

    return value.value;
};

export const getSourceName = (source: RiskSource, settings: RegularSettings): string => {
    const { t } = i18n.global;
    if (source.source === RiskValueSource.CompanyInfo) {
        return t("assessment.indicators.sourceName.companyInfo");
    } else if (source.source === RiskValueSource.Role) {
        return t("assessment.indicators.sourceName.roles");
    } else if (source.source === RiskValueSource.Manual) {
        return t("assessment.indicators.sourceName.manual");
    } else if (source.source === RiskValueSource.Form) {
        if (settings.formTemplates) {
            for (const template of settings.formTemplates) {
                if (template.key === source.formKey) {
                    return template.name.en_EN || t("assessment.indicators.sourceName.form");
                }
            }
        }
        return t("assessment.indicators.sourceName.form");
    } else if (source.source === RiskValueSource.NotApplicable) {
        return t("risk.notApplicable.value");
    }
    return t("assessment.indicators.sourceName.unknown");
};

export const getValueSourceKeyName = (valueKey: string): string => {
    const { t } = i18n.global;
    switch (valueKey) {
    case "nace-codes": return t("assessment.indicators.valueTypeName.businessCode");
    }
    return valueKey;
};

export const getRiskValueSourceText = (value: IndicatorValue, settings: RegularSettings): string => {
    const { t } = i18n.global;
    let str = t("assessment.indicators.source") + ": " + getSourceName(value.source, settings);
    if (value.user) {
        str += " - " + value.user.name;
    }
    return str;
};

export const getThresholdFromScore = (score: number, config: RiskConfig): RiskThreshold => {
    let matchedThreshold = null;
    config.thresholds.forEach((threshold) => {
        if (score >= threshold.from && score < threshold.to) {
            matchedThreshold = threshold;
        }
    });
    if (!matchedThreshold) {
        matchedThreshold = config.thresholds[config.thresholds.length - 1];
    }
    return matchedThreshold;
};

export const getIndicatorActiveValueBgColor = (score: number, config: RiskConfig): string => {
    const threshold = getThresholdFromScore(score, config);
    return threshold.color;
};

export const getPossibleRiskThresholds = (settings: RegularSettings): RiskThreshold[] => {
    const thresholds: RiskThreshold[] = [];
    if (settings.riskConfigs) {
        settings.riskConfigs.forEach((config) => {
            config.thresholds.forEach((threshold) => {
                thresholds.push(threshold);
            });
        });
    }
    // todo: remove duplicates
    return thresholds;
};
