<template>
    <div
        class="border basis-0 grow shrink-0 border-light p-4 rounded-lg bg-background"
    >
        <div
            class="flex flex-col h-full pl-3"
            :class="[
                completionStateDetails?.completedStateStyle?.border,
            ]"
        >
            <div class="flex justify-between items-center mb-3">
                <span>{{ $t("idcontrol.title") }}</span>
                <div class="flex  gap-3">
                    <Icon
                        v-if="person.idControlDetails?.status === IDControlState.Completed"
                        class="w-4 h-4"
                        icon="eye"
                        @click="toggleIdControlDetails"
                    />
                    <!--
                    <Icon
                        v-if="person.idControlDetails?.status === IDControlState.Completed"
                        class="w-4 h-4"
                        icon="download"
                        @click="downloadReport"
                    />
-->
                    <Icon
                        v-if="person.idControlDetails?.status === IDControlState.Completed || person.idControlDetails?.status === IDControlState.InProgress"
                        class="w-4 h-4"
                        icon="delete"
                        @click="confirmIdDeletedialog.visible = true"
                    />
                </div>
            </div>
            <!-- <div class="px-3 py-2 bg-white rounded-md">-->
            <div
                v-if="person.idControlDetails"
                class="px-2 py-3 rounded-md border-light"
                :class="[
                    completionStateDetails?.completedStateStyle?.bgColor,
                ]"
            >
                <div class="flex items-center">
                    <Icon

                        :icon="completionStateDetails?.completionStateStyle?.icon ?? 'circle'"
                        class="w-3 h-3 rounded-full"
                    />

                    <span v-if="person.idControlDetails?.status === IDControlState.Completed" class="ml-2 text-base font-medium">{{ matchIndicatorText }}</span>
                    <span v-else class="ml-2 text-base font-medium">{{ completionStateDetails.completionStateSupportingText }}</span>
                </div>
                <div class="flex items-center gap-2 text-xs text-light-gray mt-1">

                    <div v-if="person.idControlDetails?.method">
                        {{ idControlMethodToName(person.idControlDetails?.method) }}
                    </div>
                    <div v-else>
                        {{ completionStateDetails.completionStateText }}
                    </div>
                    <span>|</span>
                    <div>
                        {{ $d(person.idControlDetails?.updatedAt, "longDateTime") }}
                    </div>
                </div>
            </div>
            <div class="flex flex-col sm:flex-row mt-auto justify-between gap-2">
                <div v-if="!person.idControlDetails" class="flex self-start">
                    <CustomButton
                        :text="$t('idcontrol.manualId')"
                        :on-click="toggleManualIdControl"
                        round-style="full"
                        bg-color="primary-default"
                        text-color="white"
                    />
                </div>
                <div v-if="!person.idControlDetails" class="flex self-end">
                    <CustomButton
                        :text="$t('idcontrol.send')"
                        :on-click="startForm"
                        round-style="full"
                        bg-color="primary-default"
                        text-color="white"
                    />
                </div>
            </div>

            <!--Uncomment this block to implement latest design-->
            <!-- <div v-if="!person.idControlDetails" class="bg-white px-3 py-2 rounded-md">
                    <div
                        class="flex gap-1 px-2 py-3 border border-light rounded-md items-center"
                    >
                        <Icon
                            icon="play"
                            class="w-4 h-4"
                            @click="startForm"
                        />
                        <CustomButton
                            :text="$t( 'idcontrol.send' )"
                            :on-click="startForm"
                            round-style="full"
                            bg-color="primary-default"
                            text-color="white"
                        />
                    </div>
                </div>-->
            <!-- </div>-->
        </div>
        <IDControlDialog
            v-if="openEidControlModal"
            :person="person"
            @close-modal="toggleEidControlModal"
            @refresh="refreshPersons"
        />
        <IdControlDetailsDialog
            v-if="showIdControlDetails"
            :id-contol-data="person.idControlDetails"
            @close-modal="toggleIdControlDetails"
        />
        <ManualIdControl
            v-if="openManualIdControl"
            :person="person"
            @refresh-persons="refreshPersons"
            @close-modal="toggleManualIdControl"
        />
        <PopupDialog
            :prompt="$t('idcontrol.confirmResetIdControl')"
            :is-visible="confirmIdDeletedialog.visible"
            :header="$t('idcontrol.deleteIdControl')"
            :icon="'address-card'"
            :progress="confirmIdDeletedialog"
            :show-cancel-button="true"
            :show-action-button="true"
            :action-text="$t('common.yes')"
            @close-modal="confirmIdDeletedialog.visible = false"
            @action="resetIdControl"
        />
    </div>
</template>

<script lang="ts">
import CustomButton from "@/components/CustomButton.vue";
import IdControlDetailsDialog from "@/components/customer/IdControlDetailsDialog.vue";
import Icon from "@/components/Icon.vue";
import { DropdownMenuOption } from "@/lib/common/MenuOption";
import { Person, PersonWithRole, IDControlState } from "@/lib/persons/person";
import { defineComponent, PropType } from "vue";
import IDControlDialog from "../forms/IDControlDialog.vue";
import { deleteForm } from "@/lib/forms/delete_form";
import { deleteIdControl } from "@/lib/persons/update_person";
import PopupDialog from "@/ui/PopupDialog.vue";
import { idControlMethodToName } from "@/lib/common/utility";
import ManualIdControl from "./ManualIdControl.vue";

const IdControlMenuOptions = [
    {
        label: "View Information",
        value: "info",
        icon: "info-transparent",
    },

    {
        label: "Re-send ID Control",
        value: "resend",
        icon: "reset",
    },
    {
        label: "Archive Current ID Control",
        value: "archive",
        icon: "delete",
    },
] as DropdownMenuOption[];

export default defineComponent({
    name: "IdControl",
    components: {
        CustomButton,
        Icon,
        IdControlDetailsDialog,
        IDControlDialog,
        PopupDialog,
        ManualIdControl,
    },
    data() {
        return {
            openEidControlModal: false,
            iframeUrl: "",
            IdControlMenuOptions,
            expandedInfo: false,
            IDControlState,
            showIdControlDetails: false,
            idControlMethodToName,
            confirmIdDeletedialog: { visible: false, isWorking: false, statusText: this.$t("common.loading"), isError: false, errorText: "" },
            openManualIdControl: false,
        };
    },
    props: {
        person: {
            type: Object as PropType<PersonWithRole | Person>,
            default: null,
        },
        showMenu: { type: Boolean, default: true },
    },
    emits: ["close-modal", "refresh-persons"],
    methods: {

        onClose() {
            this.$emit("close-modal");
        },
        toggleEidControlModal() {
            this.openEidControlModal = !this.openEidControlModal;
        },
        toggleIdControlDetails() {
            this.showIdControlDetails = !this.showIdControlDetails;
        },

        toggleManualIdControl() {
            this.openManualIdControl = !this.openManualIdControl;
        },

        async downloadReport() {
            console.log("download report");
        },
        async resetIdControl() {
            console.log("deleting id control");
            await deleteIdControl(this.person.id);
            await deleteForm(this.person.idControlDetails?.formId ?? "");
            this.refreshPersons();
            this.confirmIdDeletedialog.visible = false;
        },
        /*
        async menuItemSelected(option: DropdownMenuOption) {
            console.log(option);
            switch (option.value) {
            case "info":
                this.expandedInfo = true;
                break;
            case "resend":
                await deleteIdControl(this.person.id);
                await deleteForm(this.person.idControlDetails?.formId ?? "");
                this.refreshPersons();
                this.startForm();
                break;
            case "archive":
                await deleteIdControl(this.person.id);
                await deleteForm(this.person.idControlDetails?.formId ?? "");
                this.refreshPersons();
                break;
            case "download":
                break;
            default:
                break;
            }
        }, */

        refreshPersons() {
            this.$emit("refresh-persons");
        },

        resizeIframe(event: MessageEvent) {
            if (event.data && typeof event.data === "object") {
                const msg = event.data;
                if (msg.action && msg.action === "resize") {
                    const iframe = document.getElementById("testframe");
                    if (iframe) {
                        const height = msg.height;
                        iframe.style.height = height + "px";
                    }
                }
            }
        },

        async startForm() {
            this.openEidControlModal = true;
        },
    },
    computed: {
        completionStateDetails(): any {
            if (this.person.idControlDetails?.status === IDControlState.Completed) {
                return {
                    completionStateText: this.$t("common.idControlState.completed"),
                    completionStateSupportingText: this.$t("common.idControlState.eid-verified"),
                    completedStateStyle: this.completedStateStyle,
                };
            }

            if (this.person.idControlDetails?.status === IDControlState.InProgress) {
                return {
                    completionStateText: this.$t("common.idControlState.in_progress"),
                    completionStateSupportingText: this.$t("common.idControlState.sent_to_recepient"),
                    completedStateStyle: {
                        bgColor: "bg-light",
                        border: "border-light border-l-2",
                        icon: "",
                    },
                };
            }
            return {
                completionStateText: "",
                completionStateSupportingText: "",
                completedStateStyle: {
                    bgColor: "",
                    border: "border-l-2 border-primary-default",
                    icon: "",
                },
            };
        },
        matchIndicatorText(): string {
            switch (this.person.idControlDetails?.matchIndicator) {
            case 8: return this.$t("common.idControlMatchIndicator.manual_id");
            case 7: return this.$t("common.idControlMatchIndicator.weak_match_name");
            case 6: return this.$t("common.idControlMatchIndicator.partial_match_name");
            case 5: return this.$t("common.idControlMatchIndicator.full_match_name");
            case 4: return this.$t("common.idControlMatchIndicator.match_dob");
            case 3: return this.$t("common.idControlMatchIndicator.weak_match_name_and_dob");
            case 2: return this.$t("common.idControlMatchIndicator.partial_match_name_and_dob");
            case 1: return this.$t("common.idControlMatchIndicator.full_match_name_and_dob");
            default:
                return this.$t("common.idControlMatchIndicator.no_match");
            }
        },
        matchText(): string {
            if (this.person.idControlDetails?.status === IDControlState.Completed) {
                const matchIndicator = this.person.idControlDetails?.matchIndicator;
                console.log(matchIndicator, "matchIndicator", this.person.idControlDetails);
                if ([7, 3].indexOf(matchIndicator) > -1) {
                    console.log("weak match");
                    return "Weak Match";
                }

                if ([6, 5, 4, 2].indexOf(matchIndicator) > -1) {
                    return "Partial Match";
                }
                if (matchIndicator === 1) {
                    return "Match";
                }
            }
            return "";
        },
        completedStateStyle(): {bgColor: string, border: string, icon: string} {
            if (this.person.idControlDetails?.status === IDControlState.Completed) {
                const matchIndicator = this.person.idControlDetails?.matchIndicator;
                if (matchIndicator === 0) {
                    return {
                        bgColor: "bg-danger-light",
                        border: "border-danger-default border-l-2",
                        icon: "",
                    };
                }
                if ([1, 2].indexOf(matchIndicator) > -1) {
                    return {
                        bgColor: "bg-success-light",
                        border: "border-success-default border-l-2",
                        icon: "",
                    };
                }

                if ([3, 4, 5, 6, 7].indexOf(matchIndicator) > -1) {
                    return {
                        bgColor: "bg-warning-background",
                        border: "border-warning-default border-l-2",
                        icon: "",
                    };
                }
            }
            return {
                bgColor: "",
                border: "",
                icon: "",
            };
        },
    },
});
</script>
