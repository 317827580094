<template>
    <div>

        <PopupDialog
            :is-visible="dialog.visible"
            :header="$t('project.forms.actor.title')"
            :icon="'clipboard-list'"
            :show-cancel-button="true"
            :progress="dialog"
            @close-modal="onClose"
        >
            <PopupDialogSplit>
                <template #top>
                    <InputRow>
                        <InputGroup :width="6">
                            <InputLabel
                                :text="$t('common.person_info.firstName')"
                                :unique-id="'end-given'"
                            />
                            <InputLocked
                                :model-value="actor.firstName"
                                :unique-id="'end-given'"
                                :disabled="true"
                            />
                        </InputGroup>
                        <InputGroup :width="6">
                            <InputLabel
                                :text="$t('common.person_info.lastName')"
                                :unique-id="'end-family'"
                            />
                            <InputLocked
                                :model-value="actor.lastName"
                                :unique-id="'end-family'"
                                :disabled="true"
                            />
                        </InputGroup>
                        <InputGroup :width="12">
                            <InputLabel
                                :text="$t('common.person_info.email')"
                                :unique-id="'end-email'"
                            />
                            <InputLocked
                                :model-value="actor.email"
                                :unique-id="'end-email'"
                                :disabled="true"
                            />
                        </InputGroup>
                    </InputRow>
                </template>
                <InputRow>
                    <InputGroup :width="6">
                        <InputLabel
                            :text="$t('project.forms.actor.role')"
                            :unique-id="'end-role'"
                        />
                        <InputLocked
                            :model-value="getActorRoleText(actor)"
                            :unique-id="'end-role'"
                            :disabled="true"
                        />
                    </InputGroup>
                    <InputGroup :width="6">
                        <InputLabel
                            :text="$t('project.forms.actor.status')"
                            :unique-id="'end-status'"
                        />
                        <InputLocked
                            :model-value="getActorStatusText(actor)"
                            :unique-id="'end-status'"
                            :disabled="true"
                        />
                    </InputGroup>
                    <InputGroup :width="12" v-if="actor.rejected">
                        <InputLabel
                            :text="$t('project.forms.actor.comment')"
                            :unique-id="'end-comment'"
                        />
                        <span>
                            {{ actor.rejectedMessage }}
                        </span>
                    </InputGroup>
                </InputRow>
            </PopupDialogSplit>
        </PopupDialog>

    </div>
</template>
<script lang="ts">
import { PropType, defineComponent } from "vue";

import { Form, FormActor } from "@/lib/forms/forms";

import PopupDialog from "@/ui/PopupDialog.vue";
import PopupDialogSplit from "@/ui/dialog/PopupDialogSplit.vue";

import InputRow from "@/ui/inputs/InputRow.vue";
import InputGroup from "@/ui/inputs/InputGroup.vue";
import InputLabel from "@/ui/inputs/InputLabel.vue";
import InputLocked from "@/ui/inputs/InputLocked.vue";
import { getActorRoleText, getActorStatusText } from "@/lib/forms/form_helper";

export default defineComponent({
    name: "FormActorInfoDialog",
    components: {
        PopupDialog,
        PopupDialogSplit,
        InputRow,
        InputGroup,
        InputLabel,
        InputLocked,
    },
    emits: ["close-modal"],
    props: {
        form: { type: Object as PropType<Form>, required: true },
        actor: { type: Object as PropType<FormActor>, required: true },
    },
    data() {
        return {
            dialog: {
                visible: true,
                valid: true,
                isWorking: false,
                statusText: "",
                isError: false,
                errorText: "",
            },
        };
    },
    methods: {

        onClose() {
            this.$emit("close-modal");
        },

        getActorStatusText(actor: FormActor) {
            return getActorStatusText(this.form, actor);
        },

        getActorRoleText(actor: FormActor) {
            return getActorRoleText(actor);
        },

    },
});
</script>
