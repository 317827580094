<template>
    <PopupDialog
        :is-visible="dialog.visible"
        :header="$t('common.sendIdControl')"
        :icon="'address-card'"
        :progress="dialog"
        :show-cancel-button="true"
        :show-action-button="true"
        :action-text="$t('common.sendIdControl')"
        :is-action-disabled="!isValid"
        @close-modal="onClose"
        @action="onSubmitClicked"
    >
        <div class="w-[60vw] h-[50vh]">
            <iframe
                id="formdialogframe"
                title="Form"
                scrolling="false"
                :src="formUrl"
            />
        </div>
    </PopupDialog>

</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import PopupDialog from "@/ui/PopupDialog.vue";
import { CreateFormOptions, createPersonForm, FormStartMode } from "@/lib/forms/create_form";
import { deleteForm } from "@/lib/forms/delete_form";
import { DateObject, IDControlDetails, IDControlState, Person, PersonWithRole } from "@/lib/persons/person";
import { updateIdControlDetails } from "@/lib/persons/update_person";
import { Verified } from "@/lib/verified_plugin";
import i18n, { i18localeToVerified } from "@/i18n";
import { FormScope, FormTemplate } from "@/lib/forms/forms";

export default defineComponent({
    name: "IDControlDialog",
    components: {
        PopupDialog,
    },
    props: {
        person: {
            type: Object as PropType<PersonWithRole | Person>,
            default: null,
        },
    },
    data() {
        return {
            formUrl: "",
            redirectRoute: null as any,
            dialog: { visible: true, isWorking: false, statusText: this.$t("common.loading"), isError: false, errorText: "" },
            isValid: false,
            formId: "",
        };
    },
    methods: {
        dateToString(date: DateObject) {
            if (!date) return "";
            return `${date.year}-${date.month < 10 ? "0" + date.month : date.month}-${date.day < 10 ? "0" + date.day : date.day}`;
        },
        async onClose() {
            await deleteForm(this.formId);
            this.$emit("close-modal");
        },

        onSubmitClicked() {
            const data = {
                action: "submit",
            };
            const myIframe = document.getElementById("formdialogframe") as HTMLIFrameElement;
            myIframe.contentWindow?.postMessage(JSON.stringify(data), "*");
        },
        async startForm() {
            // Select the id-control template from settings
            const possibleTemplates: FormTemplate[] = [];
            const formTemplates = this.$config.settings.formTemplates || [];
            for (const template of formTemplates) {
                let wasAdded = false;
                for (const scope of template.scopes) {
                    if (scope === FormScope.IDControl) {
                        possibleTemplates.push(template);
                        wasAdded = true;
                    }
                }
                if (template.key === "id-control") {
                    if (!wasAdded) possibleTemplates.push(template);
                }
            }

            let slug = Verified.instance.companyId;
            let key = "id-control";

            if (possibleTemplates.length > 0) {
                slug = possibleTemplates[0].slug;
                key = possibleTemplates[0].key;
                console.log("using template:", possibleTemplates[0]);
            }
            // todo: support for more than one id-control template

            const createOptions: CreateFormOptions = {
                startMode: FormStartMode.Create,
                contextId: this.person.id,
                templateSlug: slug,
                templateKey: key,
                scopes: [],
                model: {},
            };

            createOptions.model = {
                idControlDetails: {
                    firstName: this.person.data?.person_info?.givenName,
                    lastName: this.person.data?.person_info?.familyName,
                    dateOfBirth: this.person.data?.person_info?.dateOfBirth
                        ? this.dateToString(this.person.data?.person_info?.dateOfBirth)
                        : "",
                    email: this.person.data?.person_info?.email,
                },
            };

            console.log("createOptions", createOptions);

            const response = await createPersonForm(createOptions);
            console.log("response", response);

            if (response.responseInfo.isError) {
                this.dialog.isError = true;
                this.dialog.errorText = response.responseInfo.parsedError?.message || "Unknown error";
            } else {
                this.formUrl = response.formLink;
                this.formId = response.form?.id ?? "";
                this.formUrl = this.formUrl.replace("&token=", "&frameMode=dialog&token=");
                const currentLanguage = i18localeToVerified(i18n.global.locale.value);
                if (this.formUrl.includes("lang=")) {
                    const parts = this.formUrl.split("lang=");
                    let newLink = parts[0] + "lang=";
                    newLink += currentLanguage;
                    newLink += parts[1].substring(5);
                    this.formUrl = newLink;
                } else {
                    this.formUrl = this.formUrl.replace("&token=", `&lang=${currentLanguage}&token=`);
                }
            }
            this.dialog.isWorking = false;
        },
        async onMessageReceived(event: MessageEvent) {
            try {
                const message = JSON.parse(event.data);
                console.log("Message received:", message);
                if (message.action === "validated") {
                    if (message.data.formValid) {
                        this.isValid = true;
                    } else {
                        this.isValid = false;
                    }
                }
                if (message.action === "submitted") {
                    try {
                        await updateIdControlDetails(this.person.id, {
                            status: IDControlState.InProgress,
                            formId: this.formId,
                        } as IDControlDetails);
                    } catch (ex: any) {
                        this.dialog.isError = true;
                        this.dialog.errorText = ex.message;
                    }

                    this.$emit("refresh");
                    this.$emit("close-modal");
                }
            } catch (ex) {
                console.error("Failed to parse message", ex);
            }
        },
    },
    computed: {
        headerText() : string {
            return this.$t("common.sendIdControl");
        },
    },
    emits: ["close-modal", "refresh"],
    async mounted() {
        this.dialog.isWorking = true;
        window.addEventListener("message", this.onMessageReceived);
        await this.startForm();
    },
    unmounted() {
        window.removeEventListener("message", this.onMessageReceived);
    },
});
</script>

<style scoped>
#formdialogframe {
    width: 100%;
    min-height: 265px;
    border: none;
}
</style>
