<template>
    <TransitionRoot as="template" :show="true">
        <Dialog
            class="relative z-10"
            @close="closeModal"
            static
        >
            <!-- Backdrop -->
            <TransitionChild
                as="template"
                enter="ease-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in duration-200"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
                <div
                    class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                    @click="closeModal"
                />
            </TransitionChild>

            <!-- Modal Content -->
            <div class="fixed inset-0 z-20 flex items-center justify-center">
                <TransitionChild
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enter-to="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100 translate-y-0 sm:scale-100"
                    leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                    <DialogPanel class="amlv3-pdfpopup transition-all">
                        <!-- Content -->
                        <div>
                            <PdfViewer
                                v-if="!isLoading && base64"
                                :file="base64"
                                :key="`pdf-${currentAttachmentId || 'main'}`"
                                @close="closeModal"
                            />
                            <div
                                v-if="currentAttachments.length && isLoading === false"
                                class="absolute left-2 top-1/3 h-full z-30"
                            >
                                <PdfAttachmentSidebar
                                    :attachments="currentAttachments"
                                    :current-attachment-id="currentAttachmentId"
                                    @select-attachment="loadAttachment"
                                    @download="downloadAttachment"
                                />
                            </div>
                        </div>
                        <div v-if="currentAttachments.length && isLoading === false" class="aml-pdfview-sidebar">
                            <div
                                class="aml-pdfview-sidebar-button"
                                @click.stop="downloadAllFiles"
                            >
                                <FontIcon :icon="'object-group'" :size="26" />
                            </div>
                        </div>
                        <div
                            v-if="isLoading"
                            class="amlv3-pdfpopup-loading-center"
                        >
                            <div class="amlv3-pdfpopup-loading">
                                <SpinnerVerified :size="50" />
                                <div class="amlv3-pdfpopup-loading-text">
                                    {{ 'Loading preview...' }}
                                </div>
                            </div>
                        </div>
                        <div v-if="isError" class="amlv3-pdfpopup-error-center">
                            <div class="amlv3-pdfpopup-error">
                                <div class="amlv3-pdfpopup-error-icon">
                                    ⚠️
                                </div>
                                <div class="amlv3-pdfpopup-error-text">
                                    {{ errorMessage }}
                                </div>
                                <button
                                    class="amlv3-pdfpopup-error-button"
                                    :disabled="retryDisabled"
                                    @click="retry"
                                >
                                    {{ retryDisabled ? 'Retrying...' : 'Retry' }}
                                </button>
                            </div>
                        </div>
                    </DialogPanel>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script lang="ts">
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { PropType, defineComponent } from "vue";
import { getFile, PdfViewFile, PdfViewParams, mergePDFs, downloadBlob } from "./pdf_view_helper";
import PdfViewer from "./PdfViewer.vue";
import SpinnerVerified from "@/ui/SpinnerVerified.vue";
import PdfAttachmentSidebar from "./PdfAttachmentSidebar.vue";
import FontIcon from "@/ui/FontIcon.vue";
export default defineComponent({
    name: "PdfViewPopup",
    components: {
        Dialog,
        DialogPanel,
        TransitionChild,
        TransitionRoot,
        PdfViewer,
        SpinnerVerified,
        PdfAttachmentSidebar,
        FontIcon,
    },
    emits: ["close-modal"],
    props: {
        params: { type: Object as PropType<PdfViewParams>, required: true },
        showOtherAttachments: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isLoading: true,
            base64: null as Blob | null,
            assessmentBlob: null as Blob | null,
            isError: false,
            errorMessage: "",
            retryDisabled: false,
            currentAttachmentId: "",
            currentAttachments: [] as any[],
        };
    },
    methods: {
        closeModal() {
            this.isLoading = false;
            this.isError = false;
            this.$emit("close-modal");
        },
        async getFile(file: PdfViewFile) {
            this.isLoading = true;
            console.log("GET FILE", file);
            this.isError = false;
            this.errorMessage = "";

            try {
                const response = await getFile(file);
                if (response instanceof Blob) {
                    this.base64 = response;
                    this.currentAttachments = [];
                } else if (response && "file" in response) {
                    this.base64 = response.file;
                    this.assessmentBlob = response.file;
                    const attachments = response.attachments?.map(att => ({
                        id: att.id,
                        name: att.name,
                        attachments: [],
                        file: att.file.file,
                    })) || [];
                    if (attachments.length) {
                        this.currentAttachments = [
                            {
                                id: "main_file",
                                name: "",
                                file: response.file,
                            },
                            ...attachments,
                        ];
                    }
                } else {
                    throw new Error("Invalid file format received");
                }

                if (!this.base64) {
                    throw new Error("Failed to load the file");
                }
            } catch (error) {
                console.error("Error fetching file", error);
                this.isError = true;
                this.errorMessage = "Error fetching file";
            } finally {
                this.isLoading = false;
            }
        },
        retry() {
            if (this.retryDisabled) return;
            this.retryDisabled = true;
            setTimeout(() => {
                this.retryDisabled = false;
                if (this.params.initialId) {
                    for (const file of this.params.files) {
                        if (file.id === this.params.initialId) {
                            return this.getFile(file);
                        }
                        for (const attachment of file.attachments) {
                            if (attachment.id === this.params.initialId) {
                                return this.getFile(attachment);
                            }
                        }
                    }
                } else {
                    this.getFile(this.params.files[0]);
                }
            }, 15000);
        },

        async loadAttachment(attachment: PdfViewFile) {
            if (!attachment) return;

            // Set loading state first
            this.isLoading = true;
            this.isError = false;
            this.errorMessage = "";

            // Clear current base64 to force re-render
            this.base64 = null;

            try {
                this.currentAttachmentId = attachment.id;

                if (attachment.file instanceof Blob) {
                    await new Promise(resolve => setTimeout(resolve, 0)); // Force next tick
                    this.base64 = attachment.file;
                    return;
                }

                const response = await getFile(attachment);

                if (response instanceof Blob) {
                    this.base64 = response;
                } else if (response && "file" in response) {
                    this.base64 = response.file;
                } else {
                    throw new Error("Invalid attachment format");
                }

                if (!this.base64) {
                    throw new Error("Failed to load attachment");
                }
            } catch (error) {
                console.error("Error loading attachment:", error);
                this.isError = true;
                this.errorMessage = "Failed to load attachment";
                this.base64 = null;
            } finally {
                this.isLoading = false;
            }
        },

        async downloadAttachment(attachment: PdfViewFile) {
            try {
                let blob: Blob;
                if (attachment.file instanceof Blob) {
                    blob = attachment.file;
                } else {
                    const response = await getFile(attachment);
                    if (response instanceof Blob) {
                        blob = response;
                    } else if (response && "file" in response) {
                        blob = response.file;
                    } else {
                        throw new Error("Invalid attachment format received");
                    }
                }

                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                a.download = attachment.name || "download.pdf";
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
            } catch (error) {
                console.error("Error downloading attachment:", error);
                this.isError = true;
                this.$emit("close-modal");
                this.errorMessage = "Failed to download attachment";
            }
        },
        async downloadAllFiles() {
            try {
                const filePromises: Promise<Blob>[] = [];
                if (this.assessmentBlob) {
                    filePromises.push(Promise.resolve(this.assessmentBlob));
                }

                this.currentAttachments.forEach(attachment => {
                    if (attachment.file) {
                        filePromises.push(Promise.resolve(attachment.file));
                    }
                });
                const mergedPdf = await mergePDFs(filePromises);
                downloadBlob(mergedPdf, "Final_Report.pdf");
            } catch (error) {
                console.error("Error merging files:", error);
                this.isError = true;
                this.$emit("close-modal");
                this.errorMessage = typeof error === "string" ? error : "Failed to merge and download files";
            } finally {
                this.isLoading = false;
            }
        },
        async downloadSelectedAttachent(attachment: PdfViewFile) {
            this.currentAttachmentId = attachment.id;

            if (attachment.file instanceof Blob) {
                const blob = attachment.file;
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                a.download = attachment.name || "attachement.pdf";
                a.click();
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
            } else {
                this.isError = true;
                this.$emit("close-modal");
                this.errorMessage = "No file to download";
                console.log("Invalid file format");
            }
        },
    },
    watch: {
        base64: {
            handler(newValue: Blob | null) {
                if (newValue) {
                    this.isLoading = false;
                    this.isError = false;
                    this.errorMessage = "";
                }
            },
            immediate: true,
        },
    },
    mounted() {
        console.log("viewParams", this.params);

        if (this.params.initialId) {
            for (const file of this.params.files) {
                if (file.id === this.params.initialId) {
                    this.getFile(file);
                }
                for (const attachment of file.attachments) {
                    if (attachment.id === this.params.initialId) {
                        this.getFile(attachment);
                    }
                }
            }
        } else {
            this.getFile(this.params.files[0]);
        }
    },
});
</script>
<style scoped>

.amlv3-pdfpopup {
    height: 100vh;
    overflow: auto;
}

.amlv3-pdfpopup-loading-center {
    width: 100%;
}

.aml-pdfview-sidebar {
    position: fixed;
    top: 100px;
    right: 40px;
    border-radius: 8px;
    -webkit-box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.75);
}

.amlv3-pdfpopup-loading {
    width: 400px;
    margin: 0 auto;
    margin-top: 200px;
    padding: 20px 0px;
    border-radius: 12px;
    background-color: white;
    -webkit-box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.75);
}

.amlv3-pdfpopup-loading-text {
    margin-top: 20px;
    text-align: center;
}
.amlv3-pdfpopup-error-center {
    width: 100%;
}

.amlv3-pdfpopup-error {
    width: 400px;
    margin: 0 auto;
    margin-top: 200px;
    padding: 20px;
    border-radius: 12px;
    background-color: white;
    box-shadow: 0px 0px 9px 0px rgba(255, 0, 0, 0.5);
    text-align: center;
}

.amlv3-pdfpopup-error-icon {
    font-size: 2rem;
    color: red;
    margin-bottom: 10px;
}

.amlv3-pdfpopup-error-text {
    font-size: 1.2rem;
    margin-bottom: 20px;
}

.amlv3-pdfpopup-error-button {
    padding: 10px 20px;
    font-size: 1rem;
    color: white;
    background-color: red;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.aml-pdfview-sidebar-button {
    width: 60px;
    height: 60px;
    padding-top: 10px;
    text-align: center;
    color: #218BCB;
    border-radius: 8px;
    border: solid 1px lightgray;
    background-color: white;
    cursor: pointer;
}
.aml-pdfview-sidebar-button:hover {
    background-color: whitesmoke;
}

.amlv3-pdfpopup-error-button:hover {
    background-color: darkred;
}

.amlv3-pdfpopup-error-button:disabled {
    background-color: gray;
    cursor: not-allowed;
}

</style>
