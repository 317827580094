<template>
    <section aria-describedby="role assignment">
        <div class="bg-white  border border-muted rounded-lg p-5 w-3/4">
            <div class="flex items-center gap-2 mb-5">
                <FontIcon
                    :icon="'crown'"
                    :size="32"
                    class=" text-primary-default bg-light-blue px-2 py-1 rounded-lg"
                />
                <label class="font-roboto-slab text-3xl mb-0">{{ $t('settings.roleAssignment.title') }}</label>
            </div>

            <div class="">
                <div class="flex flex-col max-h-[600px]">
                    <div class="flex-grow overflow-auto">
                        <table class="relative w-full border-separate border-spacing-y-1">
                            <thead class="sticky top-0 z-30">
                                <tr class="bg-background text-primary-default">
                                    <th class="pl-4 pr-10 py-3 border border-r-0 border-light ">
                                        <div class="flex items-center gap-2">
                                            <FontIcon
                                                :icon="'crown'"
                                                :size="14"
                                            />
                                            <span class="flex-grow">{{ $t('settings.roleAssignment.roleTable.headers.defaultRoles') }}</span>
                                            <FontIcon
                                                :icon="'sort'"
                                                :size="14"
                                                class=" self-end "
                                            />
                                        </div>

                                    </th>
                                    <th class=" pl-4 pr-10 py-3 border border-light">
                                        <div class="flex items-center gap-2">
                                            <FontIcon
                                                :icon="'sliders'"
                                                :size="14"
                                            />
                                            {{ $t('settings.roleAssignment.roleTable.headers.setRoleRules') }}
                                        </div>

                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="(options, role) in values"
                                    :key="role"
                                    class=""
                                >
                                    <td class="pl-4 pr-10 py-4 text-left border border-r-0 border-light">
                                        {{ $t(`core.roles.${role}`) }}
                                    </td>
                                    <td class="pl-4 pr-10 py-4 text-left border border-light">
                                        <InputCheckBox
                                            :items="items"
                                            :unique-id="`options_${role}`"
                                            v-model="values[role]"
                                            items-placement="horizontal"
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="bg-background mt-5 rounded-lg p-6 shadow-inner flex">
                    <div class="flex-grow flex items-center">
                        <div v-if="saveSuccess" class="flex justify-start gap-3 bg-success-background border border-muted rounded-lg px-5 py-3 w-max">
                            <FontIcon icon="circle-check" :size="16" />
                            <span class="">{{ saveSuccess }}</span>
                        </div>

                        <div v-if="saveError" class="flex justify-start gap-3 bg-success-background border border-muted rounded-lg px-5 py-3 w-max">
                            <FontIcon icon="circle-exclamation" :size="16" />
                            <span class="">{{ saveError }}</span>
                        </div>
                    </div>

                    <div aria-describedby="role assignment actions" class="flex space-x-2">
                        <!-- <ActionButton
                            theme="transparent"
                            :loading="false"
                            :disabled="false"
                            @btn-clicked="loadRoleRules"
                        >
                            <span class=" text-primary-default font-medium">{{ $t('settings.roleAssignment.actions.cancel') }}</span>
                        </ActionButton> -->
                        <ActionButton
                            @btn-clicked="save"
                            :disabled="!changed"
                            :loading="saving"
                            class=" font-medium"
                        >
                            {{ $t('settings.roleAssignment.actions.saveRoleRules') }}
                        </ActionButton>
                    </div>

                </div>
            </div>
        </div>
    </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import FontIcon from "@/ui/FontIcon.vue";
import InputCheckBox from "@/ui/inputs/InputCheckBox.vue";
import { CheckboxItem } from "@/ui/inputs/input_helper";
import ActionButton from "@/components/ActionButton.vue";
import { updateSettings } from "@/lib/settings/update_settings";
import { roleConfig } from "@/lib/settings/settings";
import { AsyncProgress } from "@/types/ui_types";
import { querySettings } from "@/lib/settings/get_settings";

export default defineComponent({
    name: "RoleAssignment",
    components: {
        FontIcon,
        InputCheckBox,
        ActionButton,
    },
    data() {
        return {
            values: {} as Record<string, string[]>,
            saving: false,
            saveSuccess: "",
            saveError: "",
            progress: {} as AsyncProgress,
            initialValues: {} as Record<string, string[]>,
        };
    },
    computed: {
        changed(): boolean {
            return JSON.stringify(this.initialValues) !== JSON.stringify(this.values);
        },
        items(): CheckboxItem[] {
            return [{
                key: "keyrole",
                text: this.$t("settings.roleAssignment.rules.keyRole"),
            },
            {
                key: "monitor",
                text: this.$t("settings.roleAssignment.rules.screeningMonitor"),
            }] as CheckboxItem[];
        },
    },
    methods: {
        async save() {
            this.saveSuccess = "";
            this.saveError = "";
            const keyRoleRules = {} as Record<string, roleConfig>;
            for (const value in this.values) {
                keyRoleRules[value] = {
                    keyRole: this.values[value].includes("keyrole"),
                    monitor: this.values[value].includes("monitor"),
                };
            }
            try {
                this.saving = true;
                await updateSettings({ roleConfigs: keyRoleRules });
                const settings = await querySettings();
                this.$config.update(settings);
                this.saveSuccess = this.$t("settings.roleAssignment.messages.saveSuccess");

                setTimeout(() => {
                    this.saveSuccess = "";
                }, 3000);
            } catch (err: any) {
                this.saveError = err?.error?.message || this.$t("settings.roleAssignment.messages.saveError");
                setTimeout(() => {
                    this.saveError = "";
                }, 3000);
            } finally {
                this.saving = false;
                this.loadRoleRules();
            }
        },
        loadRoleRules() {
            const roleConfigs = this.$config.settings.roleConfigs;
            if (roleConfigs) {
                Object.keys(roleConfigs).forEach(role => {
                    const config = roleConfigs[role];

                    this.values[role] = [];
                    if (config.keyRole) {
                        this.values[role].push("keyrole");
                    }
                    if (config.monitor) {
                        this.values[role].push("monitor");
                    }
                });
            }
            this.initialValues = { ...this.values };
        },
    },

    created() {
        this.loadRoleRules();
    },
});
</script>
