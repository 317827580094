import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RiskNestedList = _resolveComponent("RiskNestedList")!

  return (_openBlock(), _createElementBlock("div", null, [
    _withDirectives(_createElementVNode("input", {
      placeholder: _ctx.$t('common.actions.search'),
      type: "text",
      name: "search",
      id: "searchInput",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchQuery) = $event)),
      onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.debouncedSearch && _ctx.debouncedSearch(...args))),
      class: "block w-full h-full rounded-[100px] border border-light-gray border-solid py-1.5 pl-10 placeholder:font-normal placeholder:text-light-gray sm:text-sm sm:leading-4 mb-4"
    }, null, 40, _hoisted_1), [
      [_vModelText, _ctx.searchQuery]
    ]),
    _createVNode(_component_RiskNestedList, {
      items: _ctx.nestedData.items,
      options: _ctx.nestedData.options,
      "selected-code": _ctx.value,
      "selectable-level": _ctx.selectableLevel,
      onItemSelected: _ctx.onItemSelected
    }, null, 8, ["items", "options", "selected-code", "selectable-level", "onItemSelected"])
  ]))
}