
import { ApiResponse, parseLocalError, parseRemoteError } from "../error_parser";
import { Verified } from "../verified_plugin";

export const addEditorToForm = async (formId: string): Promise <{ wasUpdated: boolean, responseInfo: ApiResponse}> => {
    try {
        const response = await fetch(`/api/aml/v1/forms/${formId}/editor`, {
            method: "PUT",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
        });
        const body = await response.json();
        if (response.status !== 200) {
            return {
                wasUpdated: false,
                responseInfo: { isError: true, parsedError: parseRemoteError(body) },
            };
        } else {
            return {
                wasUpdated: true,
                responseInfo: { isError: false, parsedError: null },
            };
        }
    } catch (error) {
        return {
            wasUpdated: false,
            responseInfo: { isError: true, parsedError: parseLocalError(error) },
        };
    }
};
