<template>
    <PopupDialog
        :is-visible="dialog.visible"
        :header="name"
        :icon="'clipboard-list'"
        :progress="dialog"
        :show-cancel-button="true"
        :cancel-button-text="$t('project.tabs.persons.screeningHitsDialog.closeBtn')"
        :show-action-button="true"
        :is-action-disabled="!hasFalsePositiveChanges"
        :action-text="$t('project.tabs.persons.screeningHitsDialog.updateFalsePositivesBtn')"
        :is-warning="false"
        @close-modal="close"
        @action="saveFalsePositiveChanges"
    >
        <div class="overflow-y-scroll w-[700px] h-[70vh] break-all pr-1">
            <div class="flex flex-col gap-3" v-if="loading">
                <div class="aml-loader rounded-sm h-[75px]"></div>
                <div class="aml-loader rounded-sm h-[700px] mt-1"></div>
            </div>

            <div v-if="!loading">

                <div class="border rounded border-light border-solid text-sm">
                    <div class="m-2">
                        <div class="text-sm w-full font-bold mb-2">
                            {{ $t('project.tabs.persons.screeningHitsDialog.summaryTitle') }}
                        </div>
                        <div class="flex gap-1 justify-start items-center text-[12px]">
                            <!-- <div>{{ $t('screening.result') }}</div> -->
                            <div v-if="person.screeningStatus?.pep == 'hitfound'" class="border rounded-full p-1 px-3 bg-[#F2CA61]">
                                {{ $t('project.tabs.persons.screeningHitsDialog.pepLong') }}
                            </div>
                            <div v-if="person.screeningStatus?.sanction == 'hitfound'" class="border rounded-full p-1 px-3 bg-[#F2CA61]">
                                {{ $t('project.tabs.persons.screeningHitsDialog.sanctionsLong') }}
                            </div>
                            <div v-if="person.screeningStatus?.rca == 'hitfound'" class="border rounded-full p-1 px-3 bg-[#F2CA61]">
                                {{ $t('project.tabs.persons.screeningHitsDialog.rcaLong') }}
                            </div>
                            <div v-if="person.screeningStatus?.sip == 'hitfound'" class="border rounded-full p-1 px-3 bg-[#F2CA61]">
                                {{ $t('project.tabs.persons.screeningHitsDialog.sipLong') }}
                            </div>
                            <div v-if="person.screeningStatus?.pep != 'hitfound' && person.screeningStatus?.sanction != 'hitfound' && person.screeningStatus?.rca != 'hitfound' && person.screeningStatus?.sip != 'hitfound'" class="border rounded-full p-1 px-3 bg-green-300">
                                {{ $t('project.tabs.persons.screeningHitsDialog.noFlags') }}
                            </div>
                        </div>

                    </div>
                </div>
                <div class="border rounded border-light border-solid text-sm mt-2">
                    <div class="m-2">
                        <div class="text-sm w-full font-bold mb-2">
                            {{ $t('project.tabs.persons.screeningHitsDialog.searchParams') }}
                        </div>

                        <div class="ml-3">
                            <div>{{ $t('common.person_info.firstName') }}</div>
                            <div class="font-bold mb-1">
                                {{ person.data.person_info.givenName }}
                            </div>
                            <div>{{ $t('common.person_info.lastName') }}</div>
                            <div class="font-bold mb-1">
                                {{ person.data.person_info.familyName }}
                            </div>
                            <div>{{ $t('common.person_info.dob') }}</div>
                            <div class="font-bold">
                                {{ formatDate(person.data.person_info?.dateOfBirth) }}
                            </div>
                        </div>

                    </div>
                </div>
                <div class="border rounded border-light border-solid text-sm mt-2">
                    <div class="m-2">
                        <div class="border-t border-gray-300">
                            <div class="flex justify-between">
                                <div class="text-sm font-bold mb-2">
                                    {{ $t('project.tabs.persons.screeningHitsDialog.hitsTitle') }}
                                </div>
                                <div v-if="hits.length > 1">
                                    <span                                        
                                        @click="selectFalsePositives"
                                        class="text-xs text-blue-500 hover:underline hover:cursor-pointer mt-[1px] mr-5"
                                    >
                                        {{ $t('common.actions.selectAll') }}
                                    </span>
                                    <span 
                                        @click="deselectFalsePositives" 
                                        class="text-xs text-blue-500 hover:underline hover:cursor-pointer mt-[1px] mr-3">
                                        {{ $t('common.actions.deselectAll') }}
                                    </span>
                                </div>
                            </div>

                            <div v-if="hits.length" class="flex flex-col gap-2">
                                <ScreeningHit
                                    v-for="(hit, index) in hits"
                                    :key="index"
                                    :hit="hit"
                                    @update-false-positive="markFalsePositive(index, $event)"
                                />
                            </div>
                            <div v-if="!hits.length" class="flex flex-col gap-2">
                                {{ $t('project.tabs.persons.screeningHitsDialog.noHits') }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </PopupDialog>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";

import CustomerTopAction from "./CustomerTopAction.vue";
import PopupDialog from "@/ui/PopupDialog.vue";
import PopupDialogPrompt from "@/ui/dialog/PopupDialogPrompt.vue";
import SimpleListItem from "@/ui/SimpleListItem.vue";
import ScreeningHit from "@/components/customer/ScreeningHit.vue";
import { getPersonScreeningHits, updatePersonScreeningFalsePositives } from "@/lib/screening/screen_person";
import { Person } from "@/lib/persons/person";
import { formatDateObject } from "@/lib/date_object_formatter";

export default defineComponent({
    name: "PersonScreeningHitsDialog",
    components: {
        CustomerTopAction,
        PopupDialog,
        PopupDialogPrompt,
        SimpleListItem,
        ScreeningHit,
    },
    emits: ["on-close", "on-update-false-positives"],
    props: {
        person: { type: Object as PropType<Person>, required: true },
    },
    computed: {
        name(): string {
            return this.person?.data?.person_info?.givenName + " " + this.person?.data?.person_info?.familyName;
        },
        hasFalsePositiveChanges(): boolean {
            return this.hits.some((hit, index) => hit.falsePositive !== this.hitsCopy[index].falsePositive);
        }
    },
    data() {
        return {
            loading: true,
            dialog: {
                visible: true,
                isWorking: false,
                statusText: "",
                isError: false,
                errorText: "wefw",
            },
            hits: [] as any[],
            hitsCopy: [] as any[],
        };
    },
    methods: {
        showDialog() {
            this.dialog.visible = true;
        },

        close() {
            this.dialog.visible = false;
            this.$emit("on-close");
        },

        selectFalsePositives() {
            for (const [i] of this.hitsCopy.entries()) {
                this.hits[i].falsePositive = true;
            }
        },

        deselectFalsePositives() {
            for (const [i] of this.hitsCopy.entries()) {
                this.hits[i].falsePositive = false;
            }
        },

        formatDate(date: any) {
            return formatDateObject(date);
        },

        async saveFalsePositiveChanges() {
            this.dialog.isError = false;
            this.dialog.errorText = "";
            this.dialog.isWorking = true;
            const payload: {[key: string]: boolean} = {};
            for (const hit of this.hits) {
                payload[hit.id] = hit.falsePositive;
            }

            try {
                await updatePersonScreeningFalsePositives(this.person.id, payload);
                this.$emit("on-update-false-positives");
                await this.reload();
            } catch (err) {
                this.dialog.isError = true;
                this.dialog.errorText = this.$t("project.tabs.persons.screeningHitsDialog.updateError");
            } finally {
                this.dialog.isWorking = false;
            }
        },

        async markFalsePositive(index: number, value: boolean) {
            this.hits[index].falsePositive = value;
        },
        async reload() {
            try {
                const result = await getPersonScreeningHits(this.person.id);
                this.hits = result.hits;
                this.hitsCopy = JSON.parse(JSON.stringify(result.hits));
            } catch (err) {
                this.dialog.isError = true;
                this.dialog.errorText = this.$t("project.tabs.persons.screeningHitsDialog.fetchError");
            }
        },
        async load() {
            this.loading = true;
            await this.reload();
            this.loading = false;
        },
    },
    async mounted() {
        await this.load();
    },
});
</script>
