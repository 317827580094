import { AssessmentSummary } from "../assessments/assessments";
import { ScreeningStatus } from "../persons/person";

export enum ProjectType {
    Person = "person",
    Company = "company",
}

export const enum ProjectStatus {
    Unknown = "unknown",
    PendingInternal = "pending_internal",
    PendingExternal = "pending_external",
    Completed = "completed",
}

export type DataBlock<T> = {
    _block: string;
    _formatVersion: number;
    _source: string;
    _providers: string[];
    _createdAt: number;
    _updatedAt: number;
} & T; // T is the actual underlying data for the block

export type Warnings = {
    id: string;
    message: string;
    reference: string;
    type: string;
    timestamp: number;
}

export interface ProjectDependency {
    id: string;
    shareForms: boolean;
}

export interface Project {
    id: string;
    namespace: string;
    assignee: string;
    manager?: string;

    name: string;
    identity?: string;
    country?: string;

    externalId?: string;

    type: ProjectType;

    workflow: string;
    status: ProjectStatus;
    flowStatus: string;

    dependency?: ProjectDependency;

    // todo: type definitions once the blocks are more defined in the api?
    data?: {
        company_info?: any;
        company_finance?: any;
        company_roles?: any;
        company_beneficials?: any;
    };
    blocks: DataBlock<any>[];

    tags: string[];
    notes: string[];
    attachments: {
        name: string;
        uri: string;
    }[];
    warnings: Warnings[];
    assessments: string[];
    assessment: AssessmentSummary;
    screeningStatus: ScreeningStatus;
    acl: string[];
    confidential: boolean;
}
