<template>
    <div class="amlv3-asslist-items">
        <div
            v-for="(assessment, i) in assessments"
            :key="'pa' + i"
            class="amlv3-asslist-item"
        >
            <div
                class="amlv3-asslist-item-icon"
                :style="{backgroundColor: getRiskInitial(assessment).color}"
            >
                {{ getRiskInitial(assessment).str }}
            </div>

            <div class="amlv3-asslist-item-text">

                <div class="amlv3-asslist-item-name">
                    {{ getAssessmentName(assessment) }}
                </div>

                <MenuInfoLine
                    :info1="getRiskText(assessment)"
                    :info2="assessment.sealedBy?.name"
                />

            </div>

            <div class="amlv3-asslist-item-actions">
                <div
                    v-if="assessment.sealed"
                    class="amlv3-asslist-item-action"
                    @click="previewReport(assessment.id)"
                >
                    <FontIcon :icon="'file-pdf'" :size="18" />
                </div>
            </div>

        </div>
    </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";

import { Assessment } from "@/lib/assessments/assessments";

import FontIcon from "@/ui/FontIcon.vue";
import { getPossibleRiskThresholds } from "@/views/risk/risk_helper";
import { RiskThreshold } from "@/lib/settings/settings";
import MenuInfoLine from "../customer/menu/MenuInfoLine.vue";
import { Project } from "@/lib/projects/projects";
import { getAssessmentFileViewParams } from "../pdf_viewer/types/assessment";

export default defineComponent({
    name: "MenuAssessmentList",
    components: {
        FontIcon,
        MenuInfoLine,
    },
    emits: ["file-preview"],
    props: {
        assessments: { type: Array as PropType<Assessment[]>, required: true },
        project: { type: Object as PropType<Project>, required: false, default: ({}) },
        maxCount: { type: Number as PropType<number>, default: 0 },
    },
    data() {
        return {
            thresholds: new Array<RiskThreshold>(),
        };
    },
    methods: {

        getAssessmentName(assessment: Assessment): string {
            if (assessment.sealedAt) {
                return this.$d(new Date(assessment.sealedAt), "longDateTime");
            }
            return "not sealed yet";
        },

        getRiskText(assessment: Assessment): string {
            for (const threshold of this.thresholds) {
                if (threshold.key === assessment.risk) {
                    return this.$ct(threshold.text);
                }
            }
            return "Unknown Risk";
        },

        getRiskInitial(assessment: Assessment): any {
            let str = "";
            let color = "";
            const assessmentThreshold = this.thresholds.find(threshold => threshold.key === assessment.risk);
            if (assessmentThreshold) {
                str = this.$ct(assessmentThreshold?.text);
                color = assessmentThreshold?.color as string;
                return {
                    str: str ? str.substring(0, 1).toUpperCase() : "X",
                    color,
                };
            } else {
                return {
                    str: "X",
                    color: "",
                };
            }
        },

        async previewReport(assessmentId: string) {
            const previewParams = getAssessmentFileViewParams(this.project.id, assessmentId);
            this.$emit("file-preview", previewParams);
        },

    },
    mounted() {
        this.thresholds = getPossibleRiskThresholds(this.$config.settings);
    },
});
</script>
<style scoped>
.amlv3-asslist-items {
    padding: 0px 20px;
    padding-bottom: 5px;
}

.amlv3-asslist-item {
    display: flex;
    border-bottom: solid 1px lightgray;
}

.amlv3-asslist-item-icon {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    margin-top: 10px;
    padding-top: 3px;
    text-align: center;
    font-family: "Roboto Slab";
    font-size: 12px;
    font-weight: 600;
    border-radius: 50%;
    background-color: lightgray;
}

.amlv3-asslist-item-text {
    flex-grow: 1;
    padding: 4px 10px;
}

.amlv3-asslist-item-name {
    font-size: 15px;
    font-weight: 500;
}

.amlv3-asslist-item-actions {
    flex-shrink: 0;
    padding-top: 11px;
    display: flex;
}

.amlv3-asslist-item-action {
    color: dimgray;
    cursor: pointer;
}
.amlv3-asslist-item-action:hover {
    opacity: 0.7;
}
</style>
