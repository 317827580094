<template>
    <div class="mt-5">
        <RefreshCompanyDataPopup
            v-if="refreshCompanyPopupOpen"
            @closed="refreshCompanyPopupOpen = false"
            @request-project-refresh="requestProjectRefresh"
            :config="refreshCompanyPopupConfig"
            :default-selection="selectedblocks"
        />
        <div class="flex flex-col lg:flex-row gap-3 lg:gap-10 p-5 bg-white rounded-md">
            <div class="flex flex-col lg:w-40 xl:w-80 gap-2 ">
                <div class="flex items-center gap-4">
                    <Icon class="w-8 h-8" icon="aml:company" />
                    <span class="font-medium text-xl">{{ $t("project.tabs.company.blocks.info.title") }}</span>
                </div>
                <div class="flex-1 mt-2 text-[#999999]">
                    <div class="mb-8">
                        <span class="inline leading-3">
                            {{ $t("project.tabs.company.blocks.info.paragraph") }}
                            <div class="inline-block bg-background mr-1">
                                <Icon class="w-4 h-4 inline-block -mb-[2px] mr-1" icon="aml:src-lookup" />
                                <div class="inline-block leading-[22px]">
                                    {{ $t("project.tabs.company.blocks.general.sourceThirdPartyProviders") }}
                                </div>
                            </div>

                            <div class="inline-block bg-background">
                                <Icon class="w-4 h-4 inline-block -mb-[2px] mr-1" icon="aml:src-kyc" />
                                <div class="inline-block leading-[22px]">
                                    {{ $t("project.tabs.company.blocks.general.sourceSelfDeclaration") }}
                                </div>
                            </div>

                            <span>{{ " " + $t("project.tabs.company.blocks.general.orBy") + " " }}</span>

                            <div class="inline-block bg-background">
                                <Icon class="w-4 h-4 inline-block -mb-[2px] mr-1" icon="aml:src-manual" />
                                <div class="inline-block leading-[22px]">
                                    {{ $t("project.tabs.company.blocks.general.sourceManualInput") }}
                                </div>
                            </div>
                        </span>
                    </div>
                    <span v-if="companyInfo">{{ $t("project.tabs.company.blocks.info.paragraph2") }}</span>
                </div>

                <!-- Refresh section -->
                <div
                    @click="refreshBlock('company_info') "
                    class="flex place-self-start gap-3 items-center text-primary-light hover:text-primary-dark cursor-pointer"
                >
                    <Icon class="w-4 h-4" icon="aml:refresh" />
                    <span>{{ $t('project.tabs.company.refreshData.refreshSection') }}</span>
                </div>
            </div>

            <!-- Missing company info block -->
            <DataBlockMissing v-if="!companyInfo" @retry="refreshCompanyPopupOpen = true">
                {{ $t("project.tabs.company.blocks.general.error") }}
            </DataBlockMissing>

            <!-- Company Info Block Values -->
            <div v-if="companyInfo" class="flex-1 grid grid-cols-3 gap-4">
                <!-- Name -->
                <DataBlockField
                    :title="$t('project.tabs.company.blocks.info.name')"
                    :icons="iconsForSources(sourcesForField('company_info.name'))"
                    class="col-span-3 md:col-span-1"
                >
                    <span :class="{ 'text-[#999]': !companyInfo.name }">{{ companyInfo.name || none }}</span>
                </DataBlockField>

                <!-- Org nr -->
                <DataBlockField
                    :title="$t('project.tabs.company.blocks.info.orgnr')"
                    :icons="iconsForSources(sourcesForField('company_info.registrationNumber'))"
                    class="col-span-3 md:col-span-1"
                >
                    <span :class="{ 'text-[#999]': !companyInfo.registrationNumber }">{{ companyInfo.registrationNumber
                        || none }}</span>
                </DataBlockField>

                <!-- Country -->
                <DataBlockField
                    :title="$t('project.tabs.company.blocks.info.country')"
                    :icons="iconsForSources(sourcesForField('company_info.registrationCountry'))"
                    class="col-span-3 md:col-span-1"
                >
                    <span :class="{ 'text-[#999]': !companyInfo.registrationCountry }">{{
                        companyInfo.registrationCountry ? $t("countries." + companyInfo.registrationCountry.toUpperCase()) : none
                    }}</span>
                </DataBlockField>

                <!-- Registration Date -->
                <DataBlockField
                    :title="$t('project.tabs.company.blocks.info.registrationDate')"
                    :icons="iconsForSources(sourcesForField('company_info.registrationDate'))"
                    class="col-span-3 sm:col-span-1"
                >
                    <div :class="{ 'text-[#999]': !companyInfo.registrationDate }">
                        {{ companyInfo.registrationDate ? formatDateObject(companyInfo.registrationDate) : none }}
                    </div>
                </DataBlockField>

                <!-- Entity Type -->
                <DataBlockField
                    :title="$t('project.tabs.company.blocks.info.entityType')"
                    :icons="iconsForSources(sourcesForField('company_info.entityType'))"
                    class="col-span-3 sm:col-span-1"
                >
                    <div :class="{ 'text-[#999]': !companyInfo.entityType }">
                        {{ companyInfo.entityType ? getEntityTypeName(companyInfo.entityType) : none }}
                    </div>
                </DataBlockField>

                <!-- Legal form -->
                <DataBlockField
                    :title="$t('project.tabs.company.blocks.info.legalFormCode')"
                    :icons="iconsForSources(sourcesForField('company_info.legalForm'))"
                    class="col-span-3 sm:col-span-1"
                >
                    <div :class="{ 'text-[#999]': !companyInfo.legalForm }">
                        {{ companyInfo.legalForm || none }}
                    </div>
                </DataBlockField>

                <!-- Business Description -->
                <DataBlockField
                    :title="$t('project.tabs.company.blocks.info.businessDescription')"
                    :icons="iconsForSources(sourcesForField('company_info.businessDescription'))"
                    class="col-span-3 sm:col-span-2"
                >
                    <span :class="{ 'text-[#999]': !companyInfo.businessDescription }">{{
                        companyInfo.businessDescription || none }}</span>
                </DataBlockField>

                <!-- Employees -->
                <DataBlockField
                    :title="$t('project.tabs.company.blocks.info.employees')"
                    :icons="iconsForSources(sourcesForField('company_info.employees'))"
                    class="col-span-3 sm:col-span-1"
                >
                    <span :class="{ 'text-[#999]': !companyInfo.employees }">{{ companyInfo.employees || none }}</span>
                </DataBlockField>

                <!-- Addresses -->
                <DataBlockField
                    :title="$t('project.tabs.company.blocks.info.addresses')"
                    class="col-span-3 3xl:col-span-2"
                >
                    <div v-if="companyInfo.addresses?.length" class="mt-2">
                        <table class="table-auto w-full">
                            <thead>
                                <tr class="bg-transparent text-xs text-gray-600 border-b">
                                    <th class="pb-2">
                                        {{ $t("project.tabs.company.blocks.info.addressType") }}
                                    </th>
                                    <th class="pb-2">
                                        {{ $t("project.tabs.company.blocks.info.street") }}
                                    </th>
                                    <th class="pb-2">
                                        {{ $t("project.tabs.company.blocks.info.town") }}
                                    </th>
                                    <th class="pb-2">
                                        {{ $t("project.tabs.company.blocks.info.zip") }}
                                    </th>
                                    <th class="pb-2">
                                        {{ $t("project.tabs.company.blocks.info.country") }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="(address, index) in companyInfo.addresses"
                                    class="bg-transparent border-t"
                                    :key="index"
                                >
                                    <td class="py-2">
                                        {{ address.type }}
                                    </td>
                                    <td class="py-2">
                                        {{ address.street }}
                                    </td>
                                    <td class="py-2">
                                        {{ address.town }}
                                    </td>
                                    <td class="py-2">
                                        {{ address.zip }}
                                    </td>
                                    <td class="py-2">
                                        {{ address.country?.toUpperCase() }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <span v-if="!companyInfo.addresses?.length" class="text-gray-400">{{ none }}</span>
                </DataBlockField>

                <!-- NACE Codes -->
                <DataBlockField
                    :title="$t('project.tabs.company.blocks.info.naceCodes')"
                    class="col-span-3 sm:col-span-2"
                >
                    <div v-if="companyInfo.naceCodes?.length" class="mt-2">
                        <table class="table-auto w-full">
                            <thead>
                                <tr class="bg-transparent text-xs text-gray-600 border-b">
                                    <th class="pb-2">
                                        {{ $t("project.tabs.company.blocks.info.naceCodesCode") }}
                                    </th>
                                    <th class="pb-2">
                                        {{
                                            $t("project.tabs.company.blocks.info.naceCodesDescription") }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="naceCode in companyInfo.naceCodes"
                                    class="bg-transparent border-t"
                                    :key="naceCode"
                                >
                                    <td class="py-2">
                                        {{ naceCode.code }}
                                    </td>
                                    <td class="py-2">
                                        {{ naceCode.description }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <span v-if="!companyInfo.naceCodes?.length" class="text-gray-400">{{ none }}</span>
                </DataBlockField>

                <!-- Info -->
                <DataBlockField
                    :title="$t('project.tabs.company.blocks.general.multipleFieldsTitle')"
                    :icons="iconsForSources([
                        ...new Set(
                            [
                                'company_info.isVatRegistered',
                                'company_info.isBakrupt',
                                'company_info.underLiquidation',
                                'company_info.underCompulsoryLiquidation',
                            ].flatMap(sourcesForField)
                        ),
                    ])
                    "
                    class="col-span-3 2xl:col-span-1"
                >
                    <div class="flex flex-col text-sm">
                        <div class="flex flex-1 border-t py-2">
                            <span class="flex-1 text-sm">{{
                                $t("project.tabs.company.blocks.info.isVatRegistered") }}</span>
                            <span :class="companyInfo.isVatRegistered == undefined ? 'text-[#999]' : 'font-bold'">{{
                                textForBooleanField(companyInfo.isVatRegistered)
                            }}</span>
                        </div>
                        <div class="flex flex-1 border-t py-2">
                            <span class="flex-1 text-sm">{{ $t("project.tabs.company.blocks.info.isBankrupt")
                            }}</span>
                            <span :class="companyInfo.isBankrupt == undefined ? 'text-[#999]' : 'font-bold'">{{
                                textForBooleanField(companyInfo.isBankrupt)
                            }}</span>
                        </div>
                        <div class="flex flex-1 border-t py-2">
                            <span class="flex-1 text-sm">{{
                                $t("project.tabs.company.blocks.info.underLiquidation") }}</span>
                            <span :class="companyInfo.underLiquidation == undefined ? 'text-[#999]' : 'font-bold'">{{
                                textForBooleanField(companyInfo.underLiquidation)
                            }}</span>
                        </div>
                        <div class="flex flex-1 border-t pt-2">
                            <span class="flex-1 text-sm">{{
                                $t("project.tabs.company.blocks.info.underCompulsoryLiquidation")
                            }}</span>
                            <span
                                :class="companyInfo.underCompulsoryLiquidation == undefined ? 'text-[#999]' : 'font-bold'"
                            >{{
                                textForBooleanField(companyInfo.underCompulsoryLiquidation)
                            }}</span>
                        </div>
                    </div>
                </DataBlockField>
            </div>
        </div>
        <!-- Role Info -->
        <div class="flex flex-col lg:flex-row gap-3 lg:gap-10 p-5 bg-white rounded-md mt-5">
            <div class="relative flex flex-col lg:w-40 xl:w-80 gap-2">
                <div class="flex items-center gap-4">
                    <Icon class="w-8 h-8" icon="roles" />
                    <span class="font-medium text-xl">{{ $t("project.tabs.company.blocks.roles.title") }}</span>
                </div>
                <div class="flex-1 mt-2 text-[#999999]">
                    <div class="mb-8">
                        <span class="inline leading-3">
                            {{ $t("project.tabs.company.blocks.roles.paragraph") }}
                            <div class="inline-block mr-1">
                                <div class="inline-block leading-[22px]">
                                    {{ $t("project.tabs.company.blocks.roles.paragraph2") }}
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <div
                    @click="refreshBlock('company_roles')"
                    class="absolute bottom-0 left-0 flex place-self-start gap-3 items-center text-primary-light hover:text-primary-dark cursor-pointer"
                >
                    <Icon class="w-4 h-4" icon="aml:refresh" />
                    <span>{{ $t('project.tabs.company.refreshData.refreshSection') }}</span>
                </div>
            </div>
            <DataBlockMissing v-if="!showRoleBlock()" @retry="refreshCompanyPopupOpen = true">
                {{ $t("project.tabs.company.blocks.general.error") }}
            </DataBlockMissing>

            <div v-else class="w-full flex-1 grid  gap-4">
                <table class="min-w-full divide-y divide-gray-300 bg-background w-full rounded-md">
                    <thead>
                        <tr class="bg-transparent text-[#999999]">
                            <th class="p-3 text-[12px] w-2/5 align-middle text-xs font-bold">
                                {{ $t("project.tabs.company.blocks.roles.name").toLocaleUpperCase() }}
                            </th>
                            <th class="p-3 text-[12px]">
                                {{ $t("project.tabs.company.blocks.roles.type").toLocaleUpperCase() }}
                            </th>
                            <th class="p-3 text-[12px]">
                                {{ $t("project.tabs.company.blocks.roles.role").toLocaleUpperCase() }}
                            </th>
                            <th class="p-3 text-[12px] w-1/5">
                                {{ $t("project.tabs.company.blocks.roles.enteredate").toLocaleUpperCase() }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="companySpecificRoles(companyRoles).length === 0">
                            <td colspan="4" class="p-5 text-center text-gray-500 bg-background">
                                {{ $t("project.tabs.company.blocks.roles.noDataMessage") }}
                            </td>

                        </tr>
                        <tr
                            v-for="role in companySpecificRoles(companyRoles)"
                            class="bg-transparent border-t text-[#444444]"
                            :key="role.name"
                        >
                            <td class="p-3 text-[15px]">
                                <div class="flex">
                                    <Icon
                                        v-if="role.type === 'company'"
                                        class="w-8 h-8"
                                        icon="small-company"
                                    />
                                    <Icon
                                        v-if="role.type === 'person'"
                                        class="w-8 h-8"
                                        icon="small-person"
                                    />
                                    <span>
                                        {{ role.name }}
                                    </span>
                                </div>
                            </td>
                            <td class="p-3 text-[15px] first-letter:capitalize">
                                {{ $t(`customers.entityTypes.${role.type?.toLowerCase() }`) }}
                            </td>
                            <td class="p-3 text-[15px] first-letter:capitalize">
                                {{ $t(`core.roles.${role.roleCode}`) }}
                            </td>
                            <td class="p-3 text-[15px]">
                                {{ role.enteredDate ? role.enteredDate.year : '' }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <!-- Beneficial Block -->
        <div class="flex flex-col lg:flex-row gap-3 lg:gap-10 p-5 bg-white rounded-md mt-5">
            <div class="relative flex flex-col lg:w-40 xl:w-80 gap-2">
                <div class="flex items-center gap-4">
                    <Icon class="w-8 h-8" icon="benefitial-owners" />
                    <span class="font-medium text-xl">{{ $t("project.tabs.company.blocks.benefitialOwners.title")
                    }}</span>
                </div>
                <div class="flex-1 mt-2 text-[#999999]">
                    <div class="mb-8">
                        <span class="inline leading-3">
                            {{ $t("project.tabs.company.blocks.benefitialOwners.paragraph") }}
                        </span>
                    </div>
                </div>
                <div
                    @click="refreshBlock('company_beneficials')"
                    class="absolute bottom-0 left-0 flex place-self-start gap-3 items-center text-primary-light hover:text-primary-dark cursor-pointer pt-3 min-w-full"
                >
                    <Icon class="w-4 h-4" icon="aml:refresh" />
                    <span>{{ $t('project.tabs.company.refreshData.refreshSection') }}</span>
                </div>
            </div>
            <DataBlockMissing v-if="!showRoleBlock()" @retry="refreshCompanyPopupOpen = true">
                {{ $t("project.tabs.company.blocks.general.error") }}
            </DataBlockMissing>
            <div v-else class="w-full flex-1 grid  gap-4">
                <div class="bg-background rounded-md p-4 grid grid-cols-5 gap-4">
                    <div class="col-span-5">
                        <table class="divide-y divide-gray-300 w-full">
                            <thead>
                                <tr>
                                    <th class="p-1 text-xs font-bold bg-background">
                                        {{
                                            $t(
                                                "project.tabs.company.blocks.benefitialOwners.identifiedShareOwners"
                                            ).toLocaleUpperCase()
                                        }}
                                    </th>
                                    <th class="p-1 text-xs font-bold bg-background flex justify-end">
                                        {{
                                            $t("project.tabs.company.blocks.benefitialOwners.percentage").toLocaleUpperCase()
                                        }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-if="shareholders().length === 0">
                                    <td colspan="4" class="p-4 text-center text-gray-500 bg-background">
                                        {{ $t("project.tabs.company.blocks.benefitialOwners.noDataMessage") }}
                                    </td>

                                </tr>
                                <tr
                                    v-for="owner in shareholders()"
                                    :key="owner.name"
                                    class="text-gray-600 border-t bg-background"
                                >
                                    <td class="p-1 text-[15px] first-letter:capitalize">
                                        {{ owner.name }}
                                    </td>
                                    <td class="pt-1 text-center text-[15px] flex justify-end">
                                        {{ owner.percentage }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="bg-background rounded-md p-4 grid grid-cols-5 gap-4">
                    <div class="col-span-5">
                        <table class="divide-y divide-gray-300 w-full bg-black">
                            <thead>
                                <tr>
                                    <th class="text-xs font-bold bg-background">
                                        {{
                                            $t(
                                                "project.tabs.company.blocks.benefitialOwners.title"
                                            ).toLocaleUpperCase()
                                        }}
                                    </th>
                                    <th class="p-1 text-xs font-bold bg-background flex justify-end">
                                        <Icon class="w-4 h-4 inline-block" icon="aml:src-lookup" />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-if="beneficialOwners().length === 0">
                                    <td colspan="4" class="p-4 text-center text-gray-500 bg-background">
                                        {{ $t("project.tabs.company.blocks.benefitialOwners.noDataMessage") }}
                                    </td>

                                </tr>
                                <tr
                                    v-for="owner in beneficialOwners()"
                                    :key="owner.name"
                                    class="p-1  text-gray-600 border-t bg-background"
                                >
                                    <td class="text-[15px] first-letter:capitalize">
                                        {{ owner.name }}
                                    </td>
                                    <td class="pt-1 text-center text-[15px] flex justify-end">
                                        {{ owner.percentage }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        </div>
        <!--Finance Block-->
        <div class="flex flex-col lg:flex-row gap-3 lg:gap-10 p-5 bg-white rounded-md mt-5">
            <div class="relative flex flex-col lg:w-40 xl:w-80 gap-2">
                <div class="flex items-center gap-4">
                    <Icon class="w-8 h-8" icon="benefitial-owners" />
                    <span class="font-medium text-xl">{{ $t("project.tabs.company.blocks.economy.title")
                    }}</span>
                </div>
                <div class="flex-1 mt-2 text-[#999999]">
                    <div class="mb-8">

                        <label>{{ $t("project.tabs.company.blocks.economy.overviewHeader") }}</label>
                        <ul class="list-disc list-inside pl-2">
                            <li>
                                <label>{{ $t('project.tabs.company.blocks.economy.overviewItem1') }}</label>
                                <div class="">
                                    <ul class="list-disc list-inside pl-5">
                                        <li>{{ $t('project.tabs.company.blocks.economy.snapshotItem1') }}</li>
                                        <li>{{ $t('project.tabs.company.blocks.economy.snapshotItem2') }}</li>
                                        <li>{{ $t('project.tabs.company.blocks.economy.snapshotItem3') }}</li>
                                    </ul>
                                </div>

                            </li>

                            <li>{{ $t('project.tabs.company.blocks.economy.overviewItem2') }}</li>
                            <li>{{ $t('project.tabs.company.blocks.economy.overviewItem3') }}</li>
                        </ul>
                    </div>
                </div>
                <div
                    @click="refreshBlock('company_finance')"
                    class="absolute bottom-0 left-0 flex place-self-start gap-3 items-center text-primary-light hover:text-primary-dark cursor-pointer pt-3 min-w-full"
                >
                    <Icon class="w-4 h-4" icon="aml:refresh" />
                    <span>{{ $t('project.tabs.company.refreshData.refreshSection') }}</span>
                </div>
            </div>
            <DataBlockMissing v-if="!companyFinancedata" @retry="refreshCompanyPopupOpen = true">
                {{ $t("project.tabs.company.blocks.benefitialOwners.noDataMessage") }}
            </DataBlockMissing>
            <div class="w-full flex-1" v-else>
                <div class="w-full grid grid-cols-6  gap-4">
                    <DataBlockField
                        :title="$t('project.tabs.company.blocks.economy.labelAccountant')"
                        class="col-span-6 md:col-span-2"
                    >
                        <span :class="{ 'text-[#999]': !companyFinancedata.accountant }">{{ companyFinancedata.accountant || none }}</span>
                    </DataBlockField>
                    <DataBlockField
                        :title="$t('project.tabs.company.blocks.economy.labelAuditor')"
                        class="col-span-6 md:col-span-2"
                    >
                        <span :class="{ 'text-[#999]': !companyFinancedata.auditor }">{{ companyFinancedata.auditor || none }}</span>
                    </DataBlockField>
                    <DataBlockField
                        :title="$t('project.tabs.company.blocks.economy.labelListingType.header')"
                        class="col-span-6 md:col-span-2"
                    >
                        <span :class="{ 'text-[#999]': !companyFinancedata.listingType }">{{ companyFinancedata.listingType ?
                            companyFinancedata.listingType === "Listed" ?
                                $t('project.tabs.company.blocks.economy.labelListingType.listed') :
                                $t('project.tabs.company.blocks.economy.labelListingType.unlisted') : none }}</span>
                    </DataBlockField>
                    <DataBlockField
                        :title="$t('project.tabs.company.blocks.economy.labelData')"
                        class="col-span-6"
                    >
                        <div v-if="companyFinancedata && companyFinancedata.keyFigures && companyFinancedata.keyFigures.length > 0">
                            <table class="min-w-full text-left">
                                <thead>
                                    <tr class="bg-transparent ">
                                        <th class="py-2 w-1/4"></th>
                                        <th
                                            v-for="(yearData, index) in finance3yrsKeyFigures"
                                            :key="index"
                                            class="py-2 text-lg font-semibold text-right"
                                        >
                                            {{ yearData.closingPeriod.year }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="bg-transparent ">
                                    <tr
                                        class="bg-transparent "
                                        v-for="(label, index) in financeChartLabels"
                                        :key="index"
                                        :class="[label.selected ? 'text-primary-default': '']"
                                    >
                                        <td
                                            @click="financeLineChartData(label.key)"
                                            class="cursor-pointer w-1/4 py-2"
                                            :class="[label.selected ? 'font-medium': '']"
                                        >
                                            {{ label.label }}
                                        </td>
                                        <td
                                            v-for="(yearData, i) in finance3yrsKeyFigures"
                                            :key="i"
                                            class="py-2 text-right"
                                        >
                                            {{ yearData[label.key] ? $n(yearData[label.key], 'decimal') : none }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class=" flex flex-1 items-center bg-white rounded-md py-3 px-24">
                                <LineChart :chart-data="lineChartData" />
                            </div>

                        </div>
                        <div v-else>
                            {{ $t("project.tabs.company.blocks.benefitialOwners.noDataMessage") }}
                        </div>
                    </DataBlockField>
                    <DataBlockField
                        :title="$t('project.tabs.company.blocks.economy.labelBalanceSheet')"
                        class="col-span-6 w-full"
                    >
                        <div v-if="companyFinancedata && companyFinancedata.keyFigures && companyFinancedata.keyFigures.length > 0">
                            <table class="min-w-full text-left">
                                <thead>
                                    <tr class="bg-transparent ">
                                        <th class="py-2 w-1/4"></th>
                                        <th
                                            v-for="(yearData, index) in finance3yrsKeyFigures"
                                            :key="index"
                                            class="py-2 text-lg font-semibold text-right"
                                        >
                                            {{ yearData.closingPeriod.year }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        class="bg-transparent "
                                        v-for="(label, index) in balanceSheet"
                                        :key="index"
                                    >
                                        <td class="w-1/4 py-2">
                                            {{ label.label }}
                                        </td>
                                        <td
                                            v-for="(yearData, i) in finance3yrsKeyFigures"
                                            :key="i"
                                            class="py-2 text-right"
                                        >
                                            {{ yearData[label.key] ? $n(yearData[label.key], 'decimal') : none }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div v-else>
                            {{ $t("project.tabs.company.blocks.benefitialOwners.noDataMessage") }}
                        </div>
                    </DataBlockField>
                    <DataBlockField
                        :title="$t('project.tabs.company.blocks.economy.labelCashFlow')"
                        class="col-span-6 w-full"
                    >
                        <div v-if="companyFinancedata && companyFinancedata.keyFigures && companyFinancedata.keyFigures.length > 0">
                            <table class="min-w-full text-left">
                                <thead>
                                    <tr class="bg-transparent ">
                                        <th class="py-2 w-1/4"></th>
                                        <th
                                            v-for="(yearData, index) in finance3yrsKeyFigures"
                                            :key="index"
                                            class="py-2 text-lg font-semibold text-right"
                                        >
                                            {{ yearData.closingPeriod.year }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        class="bg-transparent"
                                        v-for="(label, index) in cashFlow"
                                        :key="index"
                                    >
                                        <td class="w-1/4 py-2">
                                            {{ label.label }}
                                        </td>
                                        <td
                                            v-for="(yearData, i) in finance3yrsKeyFigures"
                                            :key="i"
                                            class="py-2 text-right"
                                        >
                                            {{ yearData[label.key] ? $n(yearData[label.key], 'decimal') : none }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div v-else>
                            {{ $t("project.tabs.company.blocks.benefitialOwners.noDataMessage") }}
                        </div>
                    </DataBlockField>
                    <label
                        v-if="companyFinancedata && companyFinancedata.keyFigures && companyFinancedata.keyFigures.length > 0"
                        class="text-xs font-primary-default"
                    >{{ $t('project.tabs.company.blocks.economy.currencyLabel') }}
                        {{ companyFinancedata.currency }}</label>
                </div>
            </div>

        </div>
    </div>
</template>

<script lang="ts">
import DataBlockField from "@/components/datablocks/DataBlockField.vue";
import DataBlockMissing from "@/components/datablocks/DataBlockMissing.vue";
import RefreshCompanyDataPopup from "@/components/datablocks/RefreshCompanyDataPopup.vue";
import Icon from "@/components/Icon.vue";
import { enabledBlocks } from "@/lib/projects/attach_company_data";
import { Project } from "@/lib/projects/projects";
import { defineComponent, PropType } from "vue";
import LineChart from "@/components/LineChart.vue";
import { formatDateObject } from "@/lib/date_object_formatter";
import { getEntityTypeName } from "@/types/entity_types";

export default defineComponent({
    components: {
        DataBlockField,
        DataBlockMissing,
        Icon,
        RefreshCompanyDataPopup,
        LineChart,
    },
    emits: ["requestProjectRefresh"],
    props: {
        project: {
            type: Object as PropType<Project>,
            required: true,
        },
    },
    watch: {
        project() {
            this.financeLineChartData("revenue");
        },
    },
    computed: {
        companyInfo(): any | null {
            return this.project?.data?.company_info ?? null;
        },
        companyFinancedata(): any | null {
            return this.project?.data?.company_finance ?? null;
        },
        finance3yrsKeyFigures() : any | null {
            const figCount = this.project?.data?.company_finance?.keyFigures.length ?? 0;
            console.log("count", figCount >= 3 ? 3 : figCount);
            return figCount > 0 ? this.project?.data?.company_finance?.keyFigures.slice(figCount >= 3 ? -3 : -figCount) : null;
        },

        none(): string {
            return this.$t("project.tabs.company.blocks.general.valueUndefined");
        },
        refreshCompanyPopupConfig(): any {
            const blocks: { name: string; lastUpdated?: number; warning?: string }[] = [];
            for (const blockName of enabledBlocks) {
                const matchingBlock = this.project.blocks?.sort((a, b) => b._updatedAt - a._updatedAt).find((x) => x._block == blockName);
                if (matchingBlock) {
                    blocks.push({
                        name: blockName,
                        lastUpdated: matchingBlock._updatedAt,
                        // todo: find potential warnings on project related to this block
                    });
                } else {
                    blocks.push({
                        name: blockName,
                        // warning: "Test warning",
                    });
                }
            }

            return {
                projectId: this.project.id,
                orgnr: (this.project.data?.company_info?.registrationNumber as string) || "",
                country: (this.project.data?.company_info?.country as string) || "",
                blocks,
            };
        },
        companyRoles(): any {
            return this.project?.data?.company_roles?.roles;
        },
        companyBeneficialOwners(): any {
            return this.project?.data?.company_beneficials?.beneficials;
        },
        financeChartLabels(): { label: string; key: string; selected: boolean }[] {
            return [
                { label: this.$t("project.tabs.company.blocks.economy.dataItems.revenue"), key: "revenue", selected: true },
                { label: this.$t("project.tabs.company.blocks.economy.dataItems.grossProfit"), key: "grossProfit", selected: false },
                { label: this.$t("project.tabs.company.blocks.economy.dataItems.preTaxProfit"), key: "preTaxProfit", selected: false },
                { label: this.$t("project.tabs.company.blocks.economy.dataItems.netProfit"), key: "netProfit", selected: false }];
        },
        balanceSheet(): { label: string; key: string }[] {
            return [
                { label: this.$t("project.tabs.company.blocks.economy.dataItems.totalAssets"), key: "totalAssets" },
                { label: this.$t("project.tabs.company.blocks.economy.dataItems.totalLiabilities"), key: "totalLiabilities" },
                { label: this.$t("project.tabs.company.blocks.economy.dataItems.totalEquity"), key: "totalEquity" },
            ];
        },
        cashFlow(): { label: string; key: string }[] {
            return [
                { label: this.$t("project.tabs.company.blocks.economy.dataItems.cashEquivalents"), key: "cashEquivalents" },
                { label: this.$t("project.tabs.company.blocks.economy.dataItems.financialIncome"), key: "financialIncome" },
                { label: this.$t("project.tabs.company.blocks.economy.dataItems.financialExpenses"), key: "financialExpenses" },
            ];
        },
    },
    data() {
        return {
            refreshCompanyPopupOpen: false,
            selectedblocks: [] as string[],
            lineChartData: {},

            options: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false,
                    },
                },
            },
        };
    },
    methods: {
        formatDateObject,
        getEntityTypeName,

        requestProjectRefresh() {
            this.$emit("requestProjectRefresh");
        },
        // Find which data sources a selected value is derived from. We do this by simply comparing the selected value to each blocks value
        sourcesForField(path: string): string[] {
            const sources: string[] = [];

            // ["company_info", "name"]
            const nodes = path.split(".");

            // find the *selected* value of the specified property
            const val = nodes.reduce((prev, next) => (prev ? prev[next] : undefined), this.project?.data as any);

            // we only care about blocks of same type as the block name in the path
            const blocks = (this.project?.blocks || []).filter((x) => x._block == nodes[0]);

            // remove block name since the blocks themselves don't include that as a property
            nodes.shift();

            for (const block of blocks) {
                // don't add the source more than once
                if (sources.includes(block._source)) {
                    continue;
                }

                // find the value of the property in this specific block
                const blockVal = nodes.reduce((prev, next) => (prev ? prev[next] : undefined), block as any);

                // values are the same so the selected value is considered to be derived from this block
                if (blockVal && blockVal == val) {
                    sources.push(block._source);
                }
            }

            return sources;
        },

        iconsForSources(sources: string[]) {
            const icons: string[] = [];
            for (const source of sources) {
                switch (source.toLowerCase()) {
                case "lookup":
                    icons.push("aml:src-lookup");
                    break;
                case "manual":
                    icons.push("aml:src-manual");
                    break;
                case "forms":
                    icons.push("aml:src-kyc");
                    break;
                }
            }
            return icons;
        },

        calculatePercentageOwnership(owners: any): string {
            if (owners.ownership) {
                const percentage = (owners.ownership * 100).toFixed(2);
                return percentage + "%";
            } else {
                const percentageFrom = Number(owners.range.from * 100).toFixed(2);
                const percentageTo = Number(owners.range.to * 100).toFixed(2);
                return `${percentageFrom}% - ${percentageTo}%`;
            }
        },

        calculateShareholders(owners: any[]) {
            const benefialOwners = [];
            const shareholders = [];
            for (const owner of owners || []) {
                if (owner.ownership.range) {
                    benefialOwners.push({
                        ...owner,
                        percentage: `${Number(owner.ownership.range.from * 100).toFixed(2)}% - ${Number(
                            owner.ownership.range.to * 100,
                        ).toFixed(2)}%`,
                    });
                } else {
                    const percentage = Number(owner.ownership.ownership * 100).toFixed(2);
                    console.log(owner.ownership, "percentage");
                    shareholders.push({
                        ...owner,
                        percentage: percentage + "%",
                    });
                }
            }
            return {
                benefialOwners,
                shareholders,
            };
        },

        shareholders(): any {
            return this.removeDuplicate(this.calculateShareholders(this.companyBeneficialOwners).shareholders);
        },
        beneficialOwners(): any {
            return this.removeDuplicate(this.calculateShareholders(this.companyBeneficialOwners).benefialOwners);
        },

        companySpecificRoles(role: any[]): any {
            if (!role || role.length === 0) {
                return [];
            }
            return role.filter((x) => x.type !== "company" || x.roleCode !== "owner");
        },

        onClickDataField() {
            alert("Editing not yet implemented");
        },

        textForBooleanField(value: boolean | undefined): string {
            if (value === true) {
                return this.$t("common.yes");
            } else if (value === false) {
                return this.$t("common.false");
            } else {
                return this.$t("common.n/a");
            }
        },

        showRoleBlock() {
            return !!this.project.data?.company_roles;
        },

        showBeneficialBlock() {
            return !!this.project.data?.company_beneficials;
        },
        refreshBlock(blockName: string) {
            const blockMap: { [key: string]: string[] } = {
                company_info: ["company_info"],
                company_beneficials: ["company_beneficials"],
                company_roles: ["company_roles"],
                company_finance: ["company_finance"],
            };
            this.refreshCompanyPopupOpen = true;
            this.selectedblocks = blockMap[blockName] || [];
        },
        removeDuplicate(input: any[]) {
            return input.reduce((acc, curr) => {
                if (!acc.some((item: { name: any; }) => item.name.toLowerCase() === curr.name.toLowerCase())) {
                    acc.push(curr);
                }
                return acc;
            }, []);
        },

        financeLineChartData(propertyName: string) {
            const data = this.project?.data?.company_finance?.keyFigures?.slice(-3);

            if (!data) {
                this.lineChartData = {};
                return;
            }

            const labels = data.map((x : any) => x.closingPeriod.year);
            const revenues = data.map((x : any) => x[propertyName]);

            this.lineChartData = {
                labels: labels,
                datasets: [
                    {
                        label: this.$t(`project.tabs.company.blocks.economy.dataItems.${propertyName}`),
                        backgroundColor: "#218BCB",
                        data: revenues,
                    },
                ],
            };

            for (const item of this.financeChartLabels) {
                if (item.key === propertyName) {
                    item.selected = true;
                } else {
                    item.selected = false;
                }
            }
        },

    },
    created() {
        this.financeLineChartData("revenue");
    },

});
</script>
