import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputLabel = _resolveComponent("InputLabel")!
  const _component_InputLocked = _resolveComponent("InputLocked")!
  const _component_InputGroup = _resolveComponent("InputGroup")!
  const _component_InputRow = _resolveComponent("InputRow")!
  const _component_PopupDialogSplit = _resolveComponent("PopupDialogSplit")!
  const _component_PopupDialog = _resolveComponent("PopupDialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_PopupDialog, {
      "is-visible": _ctx.dialog.visible,
      header: _ctx.$t('project.forms.actor.title'),
      icon: 'clipboard-list',
      "show-cancel-button": true,
      progress: _ctx.dialog,
      onCloseModal: _ctx.onClose
    }, {
      default: _withCtx(() => [
        _createVNode(_component_PopupDialogSplit, null, {
          top: _withCtx(() => [
            _createVNode(_component_InputRow, null, {
              default: _withCtx(() => [
                _createVNode(_component_InputGroup, { width: 6 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_InputLabel, {
                      text: _ctx.$t('common.person_info.firstName'),
                      "unique-id": 'end-given'
                    }, null, 8, ["text"]),
                    _createVNode(_component_InputLocked, {
                      "model-value": _ctx.actor.firstName,
                      "unique-id": 'end-given',
                      disabled: true
                    }, null, 8, ["model-value"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_InputGroup, { width: 6 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_InputLabel, {
                      text: _ctx.$t('common.person_info.lastName'),
                      "unique-id": 'end-family'
                    }, null, 8, ["text"]),
                    _createVNode(_component_InputLocked, {
                      "model-value": _ctx.actor.lastName,
                      "unique-id": 'end-family',
                      disabled: true
                    }, null, 8, ["model-value"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_InputGroup, { width: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_InputLabel, {
                      text: _ctx.$t('common.person_info.email'),
                      "unique-id": 'end-email'
                    }, null, 8, ["text"]),
                    _createVNode(_component_InputLocked, {
                      "model-value": _ctx.actor.email,
                      "unique-id": 'end-email',
                      disabled: true
                    }, null, 8, ["model-value"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          default: _withCtx(() => [
            _createVNode(_component_InputRow, null, {
              default: _withCtx(() => [
                _createVNode(_component_InputGroup, { width: 6 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_InputLabel, {
                      text: _ctx.$t('project.forms.actor.role'),
                      "unique-id": 'end-role'
                    }, null, 8, ["text"]),
                    _createVNode(_component_InputLocked, {
                      "model-value": _ctx.getActorRoleText(_ctx.actor),
                      "unique-id": 'end-role',
                      disabled: true
                    }, null, 8, ["model-value"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_InputGroup, { width: 6 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_InputLabel, {
                      text: _ctx.$t('project.forms.actor.status'),
                      "unique-id": 'end-status'
                    }, null, 8, ["text"]),
                    _createVNode(_component_InputLocked, {
                      "model-value": _ctx.getActorStatusText(_ctx.actor),
                      "unique-id": 'end-status',
                      disabled: true
                    }, null, 8, ["model-value"])
                  ]),
                  _: 1
                }),
                (_ctx.actor.rejected)
                  ? (_openBlock(), _createBlock(_component_InputGroup, {
                      key: 0,
                      width: 12
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_InputLabel, {
                          text: _ctx.$t('project.forms.actor.comment'),
                          "unique-id": 'end-comment'
                        }, null, 8, ["text"]),
                        _createElementVNode("span", null, _toDisplayString(_ctx.actor.rejectedMessage), 1)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["is-visible", "header", "progress", "onCloseModal"])
  ]))
}