import { Verified } from "../verified_plugin";
import { RegularSettings, Setting } from "./settings";

export const updateSettings = async (settings: RegularSettings): Promise<Setting> => {
    try {
        const response = await fetch("/api/aml/v1/settings", {
            method: "PUT",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
            body: JSON.stringify(settings),
        });

        const result = await response.json();

        if (!response.ok) {
            throw new Error(result);
        }

        return result as Setting;
    } catch (err) {
        console.error("Failed to update setting", err);
        throw err;
    }
};
