import { RiskScores } from "@/lib/risk/risk_matrix";
import { RiskAssets } from "../risk_assets";
import { RiskNestedItem, RiskNestedListData } from "../risk_nested_list";
import { LocalizedString } from "@/lib/common/common";

export const getCompanyTypes = (assets: RiskAssets):RiskNestedListData => {
    const companyType = assets.companyType;
    const items: RiskNestedItem[] = [];

    for (const entityType of companyType.entityTypes) {
        const entityTypeItem: RiskNestedItem = {
            level: 0,
            scoreKey: entityType.key,
            code: entityType.key,
            text: getLocalizedString(entityType),
            showCode: false,
            codeWidth: 0,
            expanded: false,
            items: [],
        };
        items.push(entityTypeItem);
    }
    return {
        items,
        options: {
            limit: 5,
        },
    };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getLocalizedString = (entityType: any): LocalizedString => {
    const text: LocalizedString = {};

    if (entityType.en_EN) text.en_EN = entityType.en_EN;
    if (entityType.nb_NO) text.nb_NO = entityType.nb_NO;
    if (entityType.sv_SE) text.sv_SE = entityType.sv_SE;
    if (entityType.fi_FI) text.fi_FI = entityType.fi_FI;

    return text;
};

export const getDefaultCompanyTypeScores = (assets: RiskAssets): Record<string, number> => {
    const scores: RiskScores = {};

    const asset = assets.companyType;

    for (const entityType of asset.entityTypes) {
        scores[entityType.key] = 5;
    }
    return scores;
};
