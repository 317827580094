<template>
    <div class="mt-1">

        <div
            v-for="(event, i) in eventsDisplayData"
            :key="'mle' + i"
            class="amlv3-levents-item even:bg-white odd:bg-background py-1 px-2"
        >
            <div class="amlv3-levents-item-icon">
                <FontIcon :icon="event.icon" :size="12" />
            </div>
            <div class="amlv3-levents-item-text">
                <div class="amlv3-levents-item-title">
                    {{ event.title }}
                </div>
                <MenuInfoLine
                    :info1="event.dateString"
                    :info2="event.userName"
                />
            </div>
        </div>

    </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";

import { Project } from "@/lib/projects/projects";

import { queryEvents, Event } from "@/lib/events/events";
import FontIcon from "@/ui/FontIcon.vue";
import { EventDisplayData, getEventDisplayData } from "@/lib/events/event_helper";
import MenuInfoLine from "./menu/MenuInfoLine.vue";

export default defineComponent({
    name: "ProjectEventList",
    components: {
        FontIcon,
        MenuInfoLine,
    },
    emits: ["menu-tab"],
    props: {
        project: { type: Object as PropType<Project>, required: true },
        events: { type: Array as PropType<Event[]>, required: true },
    },
    data() {
        return {

        };
    },
    computed: {
        eventsDisplayData(): EventDisplayData[] {
            return this.events.map((event: Event) => {
                return getEventDisplayData(event, this.$config.settings);
            });
        },
    },
});
</script>
<style scoped>
.amlv3-levents-item {
    display: flex;
}

.amlv3-levents-item-icon {
    flex-shrink: 0;
    width: 26px;
    height: 26px;
    margin-top: 6px;
    padding-top: 5px;
    text-align: center;
    color: #218BCB;
    border-radius: 50%;
    background-color: rgb(224, 242, 254);
}

.amlv3-levents-item-text {
    padding-left: 10px;
}

.amlv3-levents-item-title {
    font-size: 14px;
}
</style>
