import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-50ec2566"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id", "value", "checked", "onChange"]
const _hoisted_2 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex gap-3 fbav3-input-checkbox font-roboto", _ctx.itemsPlacement === 'horizontal' ? 'flex-row gap-8' : 'flex-col' ])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "flex items-center mb-1 text-sm",
        key: item.key
      }, [
        _createElementVNode("input", {
          type: "checkbox",
          id: `${_ctx.uniqueId}-${item.key}`,
          value: item.key,
          checked: _ctx.isPicked(item.key),
          onChange: ($event: any) => (_ctx.onchange(item)),
          class: "w-6 h-6 checked:border-indigo-600 rounded-sm checked:bg-indigo-600 indeterminate:border-indigo-600 indeterminate:bg-indigo-600 disabled:border-gray-300 disabled:bg-gray-100 disabled:checked:bg-gray-100"
        }, null, 40, _hoisted_1),
        _createElementVNode("label", {
          for: `${_ctx.uniqueId}-${item.key}`,
          class: "ml-3 mb-0 text-sm"
        }, _toDisplayString(item.text), 9, _hoisted_2)
      ]))
    }), 128))
  ], 2))
}