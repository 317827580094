import { ApiResponse, parseLocalError, parseRemoteError } from "../error_parser";
import { Verified } from "../verified_plugin";

// export enum AssessmentAttachmentSources {
//     FORMS = "forms",
//     PROJECT = "project",
//     FORM_ATTACHMENT = "formAttachment"

// }

export interface AssessmentAttachment {
    attachmentSource: string,
    attachmentSourceId: string,
    attachmentId: string,
    contentType?: string,
    name: string,
    url:string
}

export const getAllAssesmentAttachment = async(projectId: string, assessmentId: string): Promise<{attachments: AssessmentAttachment[], responseInfo: ApiResponse}> => {
    try {
        const response = await fetch(`/api/aml/v1/projects/${projectId}/assessments/${assessmentId}/attachments`, {
            method: "GET",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
        });
        console.log(response, "Response")
        const body = await response.json();
        if (!response.ok) {
            return {
                attachments: [],
                responseInfo: { isError: true, parsedError: null },
            };
        }
        return {
            attachments: body.attachments as Array<AssessmentAttachment>,
            responseInfo: { isError: false, parsedError: parseRemoteError(body) },
        };
    } catch (error) {
        return {
            attachments: [],
            responseInfo: { isError: true, parsedError: parseLocalError(error) },
        };
    }
};
