import { Verified } from "../verified_plugin";
import { Person, PersonCompaniesData, PersonWithRole } from "./person";

export const queryPersons = async (
    skip: number,
    limit: number,
    search: string,
    screeningMonitor?: string[],
    hitType?: string[],
): Promise<{ total: number; persons: Person[] }> => {
    try {
        const params = new URLSearchParams({
            skip: skip.toString(),
            limit: limit.toString(),
            search,
        });

        if (hitType && hitType.length > 0) {
            hitType.forEach(type => params.append("hitType[]", type));
        }

        if (screeningMonitor?.length === 1) {
            params.append("monitor", screeningMonitor[0]);
        }

        const response = await fetch(`/api/aml/v1/persons?${params.toString()}`, {
            method: "GET",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
        });

        const body = await response.json();

        if (!response.ok) {
            throw new Error(body);
        }

        return body as { total: number; persons: Person[] };
    } catch (err) {
        console.error("Failed to query persons", err);
        throw err;
    }
};

export const queryPersonWithRoleForCompany = async (projectId: string): Promise<PersonWithRole[] | null> => {
    try {
        const response = await fetch(`/api/aml/v1/projects/${projectId}/persons`, {
            method: "GET",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
        });

        const body = await response.json();

        if (!response.ok) {
            throw new Error(body);
        }
        return body as PersonWithRole[];
    } catch (err) {
        console.error("Failed to query persons", err);
        return null;
    }
};

export const queryPersonBelongsToCompanies = async (personId: string): Promise<PersonCompaniesData[] | null> => {
    try {
        const response = await fetch(`/api/aml/v1/persons/${personId}/projects`, {
            method: "GET",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
        });

        const body = await response.json();

        if (!response.ok) {
            throw new Error(body);
        }
        return body as PersonCompaniesData[];
    } catch (err) {
        console.error("Failed to query persons", err);
        return null;
    }
};
