<template>
    <Layout>
        <Nav />
        <Main>
            <iframe
                id="formviewframe"
                title="Form"
                scrolling="auto"
                :src="formUrl"
            />

            <ContactListDialog
                v-if="contactDialog.visible"
                :contacts="contacts"
                @close-modal="contactDialog.visible = false"
                @selected="selectContact"
            />

        </Main>
    </Layout>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import Layout from "../components/Layout.vue";
import Main from "../components/Main.vue";
import ContactListDialog from "@/components/contact_list/ContactListDialog.vue";
import { Contact } from "@/components/contact_list/contact_list";
import Nav from "@/components/Nav.vue";
import i18n, { i18localeToVerified } from "@/i18n";

export default defineComponent({
    name: "FormView",
    components: {
        Layout,
        Main,
        Nav,
        ContactListDialog,
    },
    data() {
        return {
            formUrl: "",
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            redirectRoute: null as any,

            contactDialog: { visible: false },
            contacts: new Array<Contact>(),
        };
    },
    methods: {
        onMessageReceived(event: MessageEvent) {
            try {
                const message = JSON.parse(event.data);
                console.log("Message received:", message);

                if (message.action === "submitted") this.onSubmitted();
                if (message.action === "formLoaded") this.onFormLoaded(message.data);
                if (message.action === "showContacts") this.onShowContacts();
            } catch (ex) {
                console.error("Failed to parse message", ex);
            }
        },

        // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
        onFormLoaded(data: any) {
            /* this.header = data.title; */
            this.overrideContacts();
        },

        onShowContacts() {
            console.log("SHOW CCC");
            this.contactDialog.visible = true;
        },

        overrideContacts() {
            const data = {
                action: "overrideContacts",
            };
            this.onPostMessage(data);
        },

        selectContact(contact: Contact) {
            const data = {
                action: "selectContact",
                person: {
                    firstName: contact.givenName,
                    lastName: contact.familyName,
                    email: contact.email,
                },
            };
            this.onPostMessage(data);
        },

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onPostMessage(data: any) {
            const myIframe = document.getElementById("formviewframe");
            if (myIframe) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (myIframe as any).contentWindow.postMessage(JSON.stringify(data), "*");
                console.log("posted", data);
            }
        },

        onSubmitted() {
            /* console.log('Form submitted, redirecting to', this.redirectRoute);
            this.$router.push(this.redirectRoute); */
            window.history.go(-1);
        },
    },
    async mounted() {
        window.addEventListener("message", this.onMessageReceived);

        if (sessionStorage.getItem("amlFormView")) {
            const data = sessionStorage.getItem("amlFormView") || "";
            try {
                const parsedData = JSON.parse(data);
                console.log("parsedData", parsedData);

                this.formUrl = parsedData.formUrl;
                this.formUrl = this.formUrl.replace("&token=", "&frameMode=page&token=");

                const currentLanguage = i18localeToVerified(i18n.global.locale.value);
                if (this.formUrl.includes("lang=")) {
                    const parts = this.formUrl.split("lang=");
                    let newLink = parts[0] + "lang=";
                    newLink += currentLanguage;
                    newLink += parts[1].substring(5);
                    this.formUrl = newLink;
                } else {
                    this.formUrl = this.formUrl.replace("&token=", `&lang=${currentLanguage}&token=`);
                }

                this.redirectRoute = parsedData.redirectRoute;

                if (parsedData.contacts) {
                    this.contacts = parsedData.contacts as Contact[];
                }
            } catch (error) {
                console.error(error);
            }
        }
    },
    unmounted() {
        window.removeEventListener("message", this.onMessageReceived);
    },
});
</script>
<style scoped>
#formviewframe {
    width: 100%;
    height: 100%;
}
</style>
