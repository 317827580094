<template>
    <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 py-6">
        <!--Header-->
        <div class="flex justify-between mb-5">
            <label class="text-3xl">{{ $t("common.person_info.ssn") }}</label>
            <Icon
                class="h-10 w-10 hover:bg-background p-3 cursor-pointer rounded-full"
                icon="cross"
                @click="$emit('closed')"
                aria-hidden="true"
            />
        </div>
        <!--Body-->
        <div class="flex flex-col gap-y-5">
            <ul v-if="ssnList && ssnList.length > 0" class="p-0 space-y-3">
                <li
                    v-for="(ssn, index) in ssnList"
                    :key="index"
                    class="flex gap-x-2 items-center min-h-[6rem] border border-light border-solid p-4 rounded-lg hover:bg-primary-hover-blue"
                >
                    <div>
                        <input
                            name="ssns"
                            type="radio"
                            :value="ssn.value"
                            v-model="currentValue"
                            class="h-6 w-6 border-light"
                            @input="onChange(ssn.value)"
                        />
                    </div>
                    <div>
                        <div>
                            <label>{{ ssn.value }}</label>
                            <div
                                v-for="src in ssn.sources"
                                class="text-sm text-light-gray flex gap-2"
                                :key="src.source"
                            >
                                <Icon class="w-4 h-4" :icon="`aml:src-${src.source.toLowerCase()}`" />
                                <Icon icon="dot-single" aria-hidden="true" />
                                <span>{{ $d(new Date(src.updatedAt), "long") }}</span>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
            <!--Start Add new name-->
            <div v-if="addSSN" class="space-y-3 bg-background rounded-lg p-4">
                <div class="w-full relative">
                    <label class="block">{{ addOrUpdateText }}</label>
                    <div class="absolute right-1 top-0">
                        <Icon
                            icon="cross"
                            class=" w-3 h-3 cursor-pointer"
                            @click="addSSN = false"
                            aria-hidden="true"
                        />
                    </div>
                </div>
                <Icon
                    icon="horizontal-line"
                    class=""
                    aria-hidden="true"
                />
                <div class="space-y-2">
                    <InputLabel
                        unique-id="person-ssn"
                        :text="$t('common.person_info.ssn')"
                        class="block"
                    />
                    <InputText
                        v-model="newSSN"
                        unique-id="addSSN"
                        :max-length="12"
                    />
                </div>
                <CustomButton
                    :text="addOrUpdateText"
                    class="border border-primary-default font-medium text-base box-border ml-auto px-2"
                    text-color="primary-default"
                    round-style="3xl"
                    bg-color="white"
                    @click="addnewSSN"
                />
            </div>
            <!--End Add new name-->
            <div v-if="!addSSN">
                <a class="text-primary-default" @click="addSSN = true">{{ addOrUpdateText }}</a>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import CustomButton from "@/components/CustomButton.vue";
import Icon from "@/components/Icon.vue";
import { valueWithsources } from "@/lib/common/common";
import { Person } from "@/lib/persons/person";
import { updatePerson, updatePersonInfo } from "@/lib/persons/update_person";
import { TextInputType } from "@/ui/inputs/input_helper";
import InputLabel from "@/ui/inputs/InputLabel.vue";
import InputText from "@/ui/inputs/InputText.vue";
import { defineComponent, PropType } from "vue";

export default defineComponent({
    name: "EditEmail",
    components: {
        Icon,
        CustomButton,
        InputText,
        InputLabel,
    },
    props: {
        person: {
            type: Object as PropType<Person>,
            required: true,
        },
    },
    data() {
        return {
            addSSN: false,
            newSSN: "",
            TextInputType,
        };
    },
    emits: ["closed", "refresh"],
    computed: {
        ssnList(): any[] | null {
            const personInfoDataBlocks = this.person.blocks
                .filter((b: any) => b._block === "person_info")
                ?.filter((b: any) => b.nationality?.nationalIdentificationNumber && b.nationality?.nationalIdentificationNumber !== "");
            if (personInfoDataBlocks && personInfoDataBlocks.length > 0) {
                const ssns = personInfoDataBlocks.reduce((acc, block) => {
                    const existingSSN = acc.find((p: any) => p.value === block.nationality?.nationalIdentificationNumber);
                    if (existingSSN) {
                        existingSSN.sources.push({ source: block._source, updatedAt: block._updatedAt });
                    } else {
                        acc.push({
                            value: block.nationality?.nationalIdentificationNumber ?? {},
                            sources: [{ source: block._source, updatedAt: block._updatedAt }],
                        });
                    }
                    return acc;
                }, [] as valueWithsources[]);

                ssns.sort((a: any, b: any) => {
                    // Prioritize the name matching person.data.person_info.givenName
                    if (a.value === this.person.data.person_info.nationality?.nationalIdentificationNumber) {
                        return -1; // a comes before b
                    } else if (b.value === this.person.data.person_info.nationality?.nationalIdentificationNumber) {
                        return 1; // b comes before a
                    }

                    // Get the latest 'updatedAt' value for each name
                    const latestUpdateA = Math.max(...a.sources.map((source: any) => new Date(source.updatedAt)));
                    const latestUpdateB = Math.max(...b.sources.map((source: any) => new Date(source.updatedAt)));

                    // Compare the latest update dates
                    return latestUpdateB - latestUpdateA;
                });
                console.log("ssns", ssns);
                return ssns;
            }

            return null;
        },
        addOrUpdateText(): string {
            return (
                (this.ssnList && this.ssnList?.length > 0 ? this.$t("common.actions.update") : this.$t("common.actions.add")) +
                " " +
                this.$t("common.person_info.ssn")
            );
        },
        currentValue(): string {
            return this.person.data.person_info.nationality?.nationalIdentificationNumber ?? "";
        },
    },
    methods: {
        async addnewSSN() {
            await updatePerson(this.person.id, {
                nationality: {
                    country: this.person.data.person_info.nationality?.country ?? "",
                    nationalIdentificationNumber: this.newSSN,
                },
            });
            this.$emit("refresh");
            this.addSSN = false;
            this.newSSN = "";
        },
        async onChange(ssn: string) {
            await updatePersonInfo(this.person.id, {
                nationality: {
                    country: this.person.data.person_info.nationality?.country ?? "",
                    nationalIdentificationNumber: ssn,
                },
            });
            this.$emit("refresh");
        },
    },
});
</script>
