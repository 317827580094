import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PopupDialogPrompt = _resolveComponent("PopupDialogPrompt")!
  const _component_PopupDialog = _resolveComponent("PopupDialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_PopupDialog, {
      "is-visible": _ctx.dialog.visible,
      header: _ctx.$t('project.forms.actions.openForm'),
      icon: 'clipboard-list',
      "show-action-button": true,
      "show-cancel-button": true,
      "is-action-disabled": !_ctx.dialog.valid,
      "action-text": _ctx.$t('project.forms.actions.openForm'),
      "action-icon": 'clipboard-list',
      progress: _ctx.dialog,
      onCloseModal: _ctx.onClose,
      onAction: _ctx.getFormLink
    }, {
      default: _withCtx(() => [
        _createVNode(_component_PopupDialogPrompt, {
          "prompt-text": _ctx.$t('project.forms.actions.editDraft'),
          "info-text": _ctx.$t('project.forms.hints.editDraft')
        }, null, 8, ["prompt-text", "info-text"])
      ]),
      _: 1
    }, 8, ["is-visible", "header", "is-action-disabled", "action-text", "progress", "onCloseModal", "onAction"])
  ]))
}