<template>
    <div>

        <PopupDialog
            :is-visible="dialog.visible"
            :header="$t('project.forms.reminder.send')"
            :icon="'bell'"
            :show-action-button="true"
            :show-cancel-button="true"
            :is-action-disabled="!dialog.valid"
            :action-text="$t('project.forms.reminder.send')"
            :action-icon="'bell'"
            :progress="dialog"
            @close-modal="onClose"
            @action="onSendReminder"
        >
            <PopupDialogSplit
                is-form
                :prompt-text="$t('project.forms.reminder.promptText')"
                :prompt-hint="$t('project.forms.reminder.hintText')"
            >

                <InputGroup :width="6">
                    <InputLabel
                        :text="$t('common.person_info.firstName')"
                        :unique-id="'end-given'"
                    />
                    <InputLocked
                        v-model="model.givenName"
                        :unique-id="'end-given'"
                        :disabled="true"
                        @input="validateDialog"
                    />
                </InputGroup>

                <InputGroup :width="6">
                    <InputLabel
                        :text="$t('common.person_info.lastName')"
                        :unique-id="'end-family'"
                    />
                    <InputLocked
                        v-model="model.familyName"
                        :unique-id="'end-family'"
                        :disabled="true"
                        @input="validateDialog"
                    />
                </InputGroup>

                <InputGroup :width="12">
                    <InputLabel
                        :text="$t('common.person_info.email')"
                        :unique-id="'end-email'"
                    />
                    <InputLocked
                        v-model="model.email"
                        :unique-id="'end-email'"
                        :disabled="true"
                        @input="validateDialog"
                    />
                </InputGroup>

            </PopupDialogSplit>
        </PopupDialog>

    </div>
</template>
<script lang="ts">
import { PropType, defineComponent } from "vue";

import { Form, FormActor } from "@/lib/forms/forms";

import PopupDialog from "@/ui/PopupDialog.vue";
import PopupDialogSplit from "@/ui/dialog/PopupDialogSplit.vue";
import { sendReminder } from "@/lib/forms/send_reminder";

import InputGroup from "@/ui/inputs/InputGroup.vue";
import InputLabel from "@/ui/inputs/InputLabel.vue";
import InputLocked from "@/ui/inputs/InputLocked.vue";

export default defineComponent({
    name: "FormReminderDialog",
    components: {
        PopupDialog,
        PopupDialogSplit,
        InputGroup,
        InputLabel,
        InputLocked,
    },
    emits: ["close-modal", "sent"],
    props: {
        form: { type: Object as PropType<Form>, required: true },
        actor: { type: Object as PropType<FormActor>, required: true },
    },
    data() {
        return {
            dialog: {
                visible: true,
                valid: true,
                isWorking: false,
                statusText: "",
                isError: false,
                errorText: "",
            },
            model: {
                givenName: "",
                familyName: "",
                email: "",
            },
        };
    },
    methods: {

        onClose() {
            this.$emit("close-modal");
        },

        validateDialog() {
            this.dialog.valid = true;
        },

        async onSendReminder() {
            this.dialog.isError = false;
            this.dialog.isWorking = true;
            this.dialog.statusText = "Sending reminder";

            const response = await sendReminder(this.form.id, this.actor.id);

            if (response.responseInfo.isError) {
                this.dialog.errorText = response.responseInfo.parsedError?.message || "Unknown error";
                this.dialog.isError = true;
            } else {
                console.log("response.wasSent", response.wasSent);
                // todo: refresh project
                this.$emit("sent", response.wasSent);
                this.onClose();
            }

            this.dialog.isWorking = false;
        },

    },
    mounted() {
        this.model.givenName = this.actor.firstName;
        this.model.familyName = this.actor.lastName;
        this.model.email = this.actor.email;
    },
});
</script>
