import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "relative flex" }
const _hoisted_2 = { class: "relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ComboboxInput = _resolveComponent("ComboboxInput")!
  const _component_FontIcon = _resolveComponent("FontIcon")!
  const _component_ComboboxButton = _resolveComponent("ComboboxButton")!
  const _component_ComboboxOption = _resolveComponent("ComboboxOption")!
  const _component_ComboboxOptions = _resolveComponent("ComboboxOptions")!
  const _component_Combobox = _resolveComponent("Combobox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Combobox, {
      as: "div",
      modelValue: _ctx.selectedItem,
      "onUpdate:modelValue": [
        _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedItem) = $event)),
        _cache[3] || (_cache[3] = (value) => _ctx.updateModelValue(value))
      ],
      items: _ctx.filteredItems,
      class: "w-full"
    }, {
      default: _withCtx(({ open }) => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_ComboboxInput, {
            class: "w-full min-h-[42px] px-2 text-base border border-solid border-muted rounded-md",
            onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.query = $event.target.value)),
            onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.query = '')),
            ref: "combobox",
            "display-value": (item) => item?.text,
            placeholder: _ctx.placeholder
          }, null, 8, ["display-value", "placeholder"]),
          _createVNode(_component_ComboboxButton, {
            as: "div",
            class: "absolute inset-y-0 right-0 flex items-center pr-2"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_FontIcon, {
                class: "",
                icon: open ? 'angle-up' : 'angle-down'
              }, null, 8, ["icon"])
            ]),
            _: 2
          }, 1024),
          (_ctx.filteredItems.length > 0)
            ? (_openBlock(), _createBlock(_component_ComboboxOptions, {
                key: 0,
                class: "fixed pl-0 z-10 mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg sm:text-sm",
                style: {"width":"inherit"}
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredItems, (item) => {
                    return (_openBlock(), _createBlock(_component_ComboboxOption, {
                      key: item.value,
                      value: item,
                      as: "div",
                      class: "list-none list-item"
                    }, {
                      default: _withCtx(({ active, selected }) => [
                        _createElementVNode("div", {
                          class: _normalizeClass(['relative cursor-default select-none py-2 pl-2 pr-3', active ? ' bg-light outline-none' : ''])
                        }, [
                          _createElementVNode("span", {
                            class: _normalizeClass(['block truncate', selected && 'font-semibold'])
                          }, _toDisplayString(item.text), 3)
                        ], 2)
                      ]),
                      _: 2
                    }, 1032, ["value"]))
                  }), 128))
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["modelValue", "items"])
  ]))
}