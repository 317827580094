import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "py-5 bg-white" }
const _hoisted_2 = { class: "mb-3" }
const _hoisted_3 = {
  key: 0,
  class: "w-full mb-3"
}
const _hoisted_4 = {
  key: 0,
  class: "text-center bg-success-light z-10 rounded-lg p-2"
}
const _hoisted_5 = { class: "" }
const _hoisted_6 = {
  key: 1,
  class: "w-full"
}
const _hoisted_7 = {
  key: 1,
  class: "w-full text-center text-danger-default z-10 rounded-lg p-2"
}
const _hoisted_8 = { class: "" }
const _hoisted_9 = { class: "space-y-2" }
const _hoisted_10 = { class: "flex items-center p-4" }
const _hoisted_11 = { class: "ml-3 flex-shrink" }
const _hoisted_12 = { class: "text-xs text-light-gray group-hover:text-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomButton = _resolveComponent("CustomButton")!
  const _component_FontIcon = _resolveComponent("FontIcon")!
  const _component_FileUploadInput = _resolveComponent("FileUploadInput")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_OverflowText = _resolveComponent("OverflowText")!
  const _component_DropdownMenu = _resolveComponent("DropdownMenu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.addFile)
        ? (_openBlock(), _createBlock(_component_CustomButton, {
            key: 0,
            text: _ctx.$t('common.addAttachment'),
            "bg-color": "transparent",
            "text-color": "primary-default",
            "border-color": "primary-default",
            icon: "file-circle-plus",
            "round-style": "2xl",
            "icon-position": "left",
            class: "ml-auto",
            "on-click": () => (_ctx.addFile = true)
          }, null, 8, ["text", "on-click"]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.addFile)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_ctx.showSuccess)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_FontIcon, {
                  icon: "circle-check",
                  size: 40,
                  class: "text-success-default mb-2"
                }),
                _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t("common.uploadSuccess")), 1)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createVNode(_component_FileUploadInput, {
                  onFileUploaded: _ctx.uploadAttachment,
                  progress: _ctx.progress
                }, null, 8, ["onFileUploaded", "progress"]),
                _createVNode(_component_CustomButton, {
                  text: _ctx.$t('common.actions.cancel'),
                  "bg-color": "primary-default",
                  "text-color": "white",
                  "border-color": "primary-default",
                  "round-style": "2xl",
                  "icon-position": "left",
                  class: "ml-auto mb-3 w-full",
                  "on-click": () => {_ctx.addFile = false; _ctx.errorMessage = '';}
                }, null, 8, ["text", "on-click"])
              ]))
        ]))
      : _createCommentVNode("", true),
    (_ctx.errorMessage)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.errorMessage), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("ul", _hoisted_9, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.attachments, (a, i) => {
        return (_openBlock(), _createElementBlock("li", {
          key: 'attachment_' + i,
          class: "group bg-background rounded-lg hover:bg-primary-hover-blue"
        }))
      }), 128)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.attachments, (a, i) => {
        return (_openBlock(), _createElementBlock("li", {
          key: 'attachment_' + i,
          class: "group bg-background rounded-lg hover:bg-primary-hover-blue"
        }, [
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_Icon, {
              class: "w-8 h-8 flex-none",
              icon: "attachment"
            }),
            _createElementVNode("div", _hoisted_11, [
              _createVNode(_component_OverflowText, {
                text: a.name,
                "max-length": 20
              }, null, 8, ["text"]),
              _createElementVNode("div", _hoisted_12, _toDisplayString(a.createdBy) + " - " + _toDisplayString(a.createdAt ? _ctx.$d(a.createdAt, "long") : ""), 1)
            ]),
            _createVNode(_component_DropdownMenu, {
              options: _ctx.menuOptions,
              "items-align": "left",
              class: "ml-auto",
              onMenuItemSelected: (option) => _ctx.menuItemSelected(option, a.id ?? '')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Icon, {
                  class: "w-4 h-4",
                  icon: "hamburger-menu"
                })
              ]),
              _: 2
            }, 1032, ["options", "onMenuItemSelected"])
          ])
        ]))
      }), 128))
    ])
  ]))
}