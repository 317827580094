import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "assessment-attachments" }
const _hoisted_2 = {
  key: 0,
  class: "text-red-500 mt-2 text-sm"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputCheckbox2 = _resolveComponent("InputCheckbox2")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_InputCheckbox2, {
      modelValue: _ctx.checkboxState,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.checkboxState) = $event)),
      items: _ctx.checkboxItems,
      "unique-id": 'attachment-checkbox'
    }, null, 8, ["modelValue", "items"]),
    (_ctx.selectedState.length === 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.$ct('Please select at least one attachment')), 1))
      : _createCommentVNode("", true)
  ]))
}