<template>
    <PopupDialog
        :is-visible="dialog.visible"
        :header="$t('assessment.approval.newTitle')"
        :icon="'thumbs-up'"
        :show-cancel-button="true"
        :show-action-button="true"
        :action-text="$t('assessment.approval.startAction')"
        :action-icon="'share'"
        :is-action-disabled="!dialog.valid"
        :progress="dialog"
        @action="addNewApproval"
        @close-modal="onClose"
    >
        <PopupDialogSplit
            style="min-width: 500px; max-width: 560px;"
            :prompt-text="$t('assessment.approval.promptText')"
            :prompt-hint="$t('assessment.approval.promptHint')"
        >
            <div v-if="!dialog.isWorking && !(needsLock && !canLock)">
                <div
                    v-for="(approver, i) in approvers"
                    :key="'asda' + i"
                    class="amlv3-appselectd-approver"
                >

                    <div class="amlv3-appselectd-approver-form">
                        <InputGroup :width="6">
                            <ComboBox
                                v-model="approver.value"
                                :items="dropdownItems"
                                @update:model-value="onSelectApprover(approver)"
                                :placeholder="$t('common.searchEmployee')"
                            />
                        </InputGroup>
                        <InputGroup :width="6">
                            <InputText
                                v-model="approver.email"
                                :unique-id="'approver-email'"
                                @input="validateDialog"
                                :readonly="true"
                            />
                        </InputGroup>
                    </div>

                    <div class="amlv3-appselectd-approver-actions">
                        <div class="amlv3-appselectd-approver-action" @click="onDeleteApprover(i)">
                            <FontIcon :icon="'trash-can'" />
                        </div>
                    </div>

                </div>

                <ButtonRow class="mt-4" :alignment="ButtonAlignment.Right">
                    <ButtonSingle
                        is-link
                        :text="$t('assessment.approval.approvers.addAction')"
                        @clicked="onAddApprover"
                    />
                </ButtonRow>

            </div>
            <div v-if="!canLock">
                <PopupDialogInfoIconAndText
                    :text="$t('assessment.approval.completeHint')"
                    :is-warning="true"
                />
            </div>
            <div v-if="needsLock && canLock" class="mt-6">
                <PopupDialogInfoIconAndText
                    :text="$t('assessment.approval.lockHint')"
                />
            </div>
        </PopupDialogSplit>
    </PopupDialog>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";

import { Project } from "@/lib/projects/projects";
import PopupDialog from "@/ui/PopupDialog.vue";
import PopupDialogSplit from "@/ui/dialog/PopupDialogSplit.vue";
import { FlowAction } from "@/types/flow_types";
import { Assessment } from "@/lib/assessments/assessments";
import { DropdownItem } from "@/ui/inputs/input_helper";
import InputDropdown from "@/ui/inputs/InputDropdown.vue";
import InputGroup from "@/ui/inputs/InputGroup.vue";
import InputText from "@/ui/inputs/InputText.vue";
import FontIcon from "@/ui/FontIcon.vue";
import { Employee } from "@/lib/common/identity";
import { ButtonAlignment } from "@/types/ui_types";
import ButtonRow from "@/ui/ButtonRow.vue";
import ButtonSingle from "@/ui/ButtonSingle.vue";
import { ApproverRequestModel, startApproval, StartApprovalRequest } from "@/lib/assessments/add_approval";
import { getProjectProcess } from "@/lib/projects/get_project";
import PopupDialogInfoIconAndText from "@/ui/dialog/PopupDialogInfoIconAndText.vue";
import { lockAssessment } from "@/lib/assessments/seal_assessment";
import ComboBox from "@/ui/inputs/ComboBox.vue";

interface ApproverModel {
    value: string;
    email: string;
}

export default defineComponent({
    name: "ApprovalSelectDialog",
    components: {
        PopupDialog,
        PopupDialogSplit,
        PopupDialogInfoIconAndText,
        FontIcon,
        InputGroup,
        InputDropdown,
        InputText,
        ButtonRow,
        ButtonSingle,
        ComboBox,
    },
    emits: ["request-project-refresh", "close-modal"],
    props: {
        action: { type: Object as PropType<FlowAction>, required: true },
        project: { type: Object as PropType<Project>, required: true },
        assessment: { type: Object as PropType<Assessment>, required: true },
    },
    data() {
        return {
            ButtonAlignment,

            dialog: { visible: true, valid: false, isWorking: false, statusText: "", isError: false, errorText: "" },

            employees: new Array<Employee>(),
            dropdownItems: new Array<DropdownItem>(),
            approvers: new Array<ApproverModel>(),
            approvalKey: "default",

            canLock: false,
            needsLock: true,
        };
    },
    methods: {

        onClose() {
            this.$emit("close-modal");
        },

        onAddApprover() {
            this.approvers.push({
                value: "",
                email: "",
            });
        },

        onSelectApprover(approver: ApproverModel) {
            const employee = this.employees.find((employee) => employee.uid === approver.value);
            if (employee && employee.email) {
                approver.email = employee.email;
            }
            this.validateDialog();
        },

        onDeleteApprover(index: number) {
            this.approvers.splice(index, 1);
            this.validateDialog();
        },

        validateDialog() {
            let valid = true;

            if (this.approvers.length === 0) valid = false;

            this.approvers.forEach((approver) => {
                if (!approver.value || !approver.email) valid = false;
            });

            this.dialog.valid = valid;
        },

        async addNewApproval() {
            if (this.approvers.length === 0) {
                this.dialog.isError = true;
                this.dialog.isWorking = false;
                this.dialog.errorText = this.$t("assessment.approval.noApprovers");
                return;
            }
            this.dialog.isError = false;
            this.dialog.isWorking = true;
            if (this.needsLock) {
                this.dialog.statusText = this.$t("assessment.lock.lockProgress");

                const lockResponse = await lockAssessment(this.project.id, this.assessment.id, true);
                if (lockResponse.responseInfo.isError) {
                    this.dialog.errorText = lockResponse.responseInfo.parsedError?.message || "Unknown error";
                    this.dialog.isError = true;
                    this.dialog.isWorking = false;
                    return;
                }
            }

            this.dialog.statusText = this.$t("assessment.approval.working");

            const approval: StartApprovalRequest = {
                key: this.approvalKey,
                approvers: [],
            };

            this.approvers.forEach((approverModel) => {
                const employee = this.employees.find((employee) => employee.uid === approverModel.value);
                if (employee) {
                    const approver: ApproverRequestModel = {
                        uid: employee.uid,
                        givenName: employee.givenName,
                        familyName: employee.familyName,
                        email: employee.email,
                    };
                    approval.approvers.push(approver);
                }
            });

            console.log("approval", approval);

            const response = await startApproval(this.project.id, this.assessment.id, approval);
            if (response.responseInfo.isError) {
                this.dialog.isError = true;
                this.dialog.errorText = response.responseInfo.parsedError?.message || "Unknown Error";
            } else {
                const projectResponse = await getProjectProcess(this.project.id);
                this.$emit("request-project-refresh", projectResponse);
                this.onClose();
            }
            this.dialog.isWorking = false;
        },

        async fetchEmployees() {
            this.dialog.isError = false;
            this.dialog.isWorking = true;
            this.dialog.statusText = this.$t("common.loadingPerson");

            this.employees = await this.$assets.getEmployees();

            // If enabled in settings, filter out any users that are not in the approvers list
            const approvalCnf = this.$config.settings.approvalConfigs?.find(x => x.key === this.action.params?.approvalKey);
            if (approvalCnf && approvalCnf.useApproversList) {
                this.employees = this.employees.filter(x => approvalCnf.approvers?.includes(x.uid));
            }

            this.employees.forEach((employee) => {
                this.dropdownItems.push({
                    value: employee.uid,
                    text: employee.givenName + " " + employee.familyName,
                });
            });

            this.onAddApprover();
            this.dialog.isWorking = false;
        },

    },
    created() {
        console.log("action", this.action);
        if (this.action.params && this.action.params.approvalKey) {
            this.approvalKey = this.action.params.approvalKey as string;
        } else {
            // todo: implement select dialog
            this.approvalKey = "default";
        }
        console.log("approvalKey", this.approvalKey);

        if (this.assessment.locked) {
            this.canLock = true;
            this.needsLock = false;
        } else {
            if (this.assessment.matrixOutput.completed) {
                this.canLock = true;
            }
        }

        this.fetchEmployees();
    },
});
</script>
<style scoped>
.amlv3-appselectd-approver {
    display: flex;
}

.amlv3-appselectd-approver-form {
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
}

.amlv3-appselectd-approver-actions {
    flex-shrink: 0;
    display: flex;
}

.amlv3-appselectd-approver-action {
    width: 30px;
    padding-top: 5px;
    text-align: center;
    cursor: pointer;
}
.amlv3-appselectd-approver-action:hover {
    opacity: 0.7;
}
</style>
