<template>
    <Layout>
        <Nav />
        <Main>
            <PersonProfileHeader :person="person" :company-count="customers?.length ?? none" />
            <div class="flex h-dvh pt-9 px-12">
                <div class="w-2/3 space-y-4 pr-6">
                    <!--Profile info start-->
                    <div class="overflow-hidden rounded-lg bg-white shadow">
                        <div class="px-4 py-5 sm:p-6">
                            <div class="flex items-center gap-2 mb-4">
                                <Icon class="w-6 h-6 fill-primary-default stroke-primary-default" icon="profile" />
                                <span class="text-xl">{{ $t("common.profile") }}</span>
                            </div>
                            <div class="grid grid-cols-12 gap-3">
                                <PersonDataBlockField
                                    :title="$t('common.person_info.firstName')"
                                    class="col-span-4 h-24 hover:bg-primary-hover-blue border border-light"
                                    :sources="sourcesAndCountForField('person_info.givenName')"
                                    @open-sidebar-dialog="() => toggleSidebarDialog('firstName')"
                                >
                                    <div class="text-body">
                                        {{ person.data?.person_info?.givenName || none }}
                                    </div>
                                </PersonDataBlockField>
                                <PersonDataBlockField
                                    :title="$t('common.person_info.lastName')"
                                    class="col-span-4 h-24 hover:bg-primary-hover-blue border border-light"
                                    :sources="sourcesAndCountForField('person_info.familyName')"
                                    @open-sidebar-dialog="() => toggleSidebarDialog('lastName')"
                                >
                                    <div class="text-body">
                                        {{ person.data?.person_info?.familyName || none }}
                                    </div>
                                </PersonDataBlockField>
                                <PersonDataBlockField
                                    :title="$t('common.person_info.dateOfBirth')"
                                    class="col-span-4 h-24 hover:bg-primary-hover-blue border border-light"
                                    :sources="sourcesAndCountForField('person_info.dateOfBirth')"
                                    @open-sidebar-dialog="() => toggleSidebarDialog('dob')"
                                >
                                    <div class="text-body">
                                        {{
                                            formatDate(person.data?.person_info?.dateOfBirth)
                                        }}
                                    </div>
                                </PersonDataBlockField>
                                <PersonDataBlockField
                                    :title="$t('common.person_info.email')"
                                    class="col-span-6 h-24 hover:bg-primary-hover-blue border border-light"
                                    :sources="sourcesAndCountForField('person_info.email')"
                                    @open-sidebar-dialog="() => toggleSidebarDialog('email')"
                                >
                                    <div class="text-body">
                                        {{ person.data?.person_info?.email || none }}
                                    </div>
                                </PersonDataBlockField>
                                <PersonDataBlockField
                                    :title="$t('common.person_info.address')"
                                    class="col-span-6 h-24 hover:bg-primary-hover-blue border border-light"
                                    @open-sidebar-dialog="() => toggleSidebarDialog('address')"
                                    :sources="sourcesAndCountForField('person_info.addresses')"
                                >
                                    <div class="text-body">
                                        {{
                                            person.data?.person_info?.addresses
                                                ? addressObjectToString(person.data?.person_info?.addresses[0])
                                                : none
                                        }}
                                    </div>
                                </PersonDataBlockField>
                                <PersonDataBlockField
                                    :title="$t('common.person_info.phone')"
                                    class="col-span-4 hover:bg-primary-hover-blue border border-light"
                                    @open-sidebar-dialog="() => toggleSidebarDialog('phone')"
                                    :sources="sourcesAndCountForField('person_info.phone')"
                                >
                                    <div class="text-body">
                                        {{ person.data?.person_info?.phone || none }}
                                    </div>
                                </PersonDataBlockField>
                                <PersonDataBlockField
                                    :title="$t('common.person_info.ssn')"
                                    class="col-span-4 hover:bg-primary-hover-blue border border-light"
                                    :sources="sourcesAndCountForField('person_info.nationality.nationalIdentificationNumber')"
                                    @open-sidebar-dialog="() => toggleSidebarDialog('ssn')"
                                >
                                    <HiddenText
                                        v-if="person.data?.person_info?.nationality?.nationalIdentificationNumber"
                                        :text="person.data?.person_info?.nationality?.nationalIdentificationNumber"
                                        class="text-body"
                                    />
                                    <div v-else class="text-body">
                                        {{ none }}
                                    </div>
                                </PersonDataBlockField>
                                <PersonDataBlockField
                                    :title="$t('common.person_info.country')"
                                    class="col-span-4 hover:bg-primary-hover-blue border border-light"
                                    @open-sidebar-dialog="() => toggleSidebarDialog('country')"
                                    :sources="sourcesAndCountForField('person_info.nationality.country')"
                                >
                                    <div v-if="person.data?.person_info?.nationality?.country" class="text-body">
                                        {{
                                            $ct(Object.entries(countryDetails.countries).find(([key, value]) => key === person.data?.person_info?.nationality?.country.toUpperCase())?.[1])
                                        }}
                                    </div>
                                    <div v-else>
                                        {{ none }}
                                    </div>
                                </PersonDataBlockField>
                            </div>
                        </div>
                    </div>
                    <!--Profile info end-->

                    <!--Customers list start-->
                    <div class="overflow-hidden rounded-lg bg-white shadow">
                        <div class="px-4 py-5 sm:p-6">
                            <div class="flex items-center gap-2 mb-4">
                                <Icon class="w-6 h-6" icon="company" />
                                <span class="text-xl">{{ $t("core.menu.customers") }}</span>
                            </div>
                            <div v-if="peginatedCustomers && peginatedCustomers.length > 0" class="border border-light rounded-lg overflow-hidden">
                                <div class="flex flex-col">
                                    <ul>
                                        <li
                                            class="group flex items-center gap-2 p-4 hover:bg-primary-hover-blue"
                                            :class="[index < peginatedCustomers?.length - 1 ? 'border-b border-light' : '']"
                                            v-for="(customer, index) in peginatedCustomers"
                                            :key="customer.projectId"
                                        >
                                            <div>
                                                <div>
                                                    {{ customer.projectName }}
                                                </div>
                                                <div class="text-light-gray group-hover:text-body text-sm">
                                                    <span> Role : </span>
                                                    <span v-for="(role, i) in customer.roles" :key="i">{{
                                                        $t(role.name ? "core.roles." + role.name : "core.roles." + role.key) +
                                                            (index < customer.roles.length - 1 ? ", " : "")
                                                    }}</span>
                                                </div>
                                            </div>
                                            <div class="flex items-center gap-2 ml-auto">
                                                <div class="flex items-center gap-1 px-2 py-1 rounded-3xl bg-primary-default text-white">
                                                    <span class="text-xs">Risk: {{ customer.risk }}</span>
                                                </div>

                                                <Icon
                                                    class="w-5 h-5 fill-primary-default cursor-pointer"
                                                    icon="rightArrow"
                                                    @click="$router.push({ path: `/aml/customers/${customer.projectId}/overview` })"
                                                />
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div v-else>
                                {{ none }}
                            </div>
                            <Pagination
                                v-if="customers && customers.length > 5"
                                :items-count="customers?.length"
                                :items-per-page="5"
                                pagination-style="long"
                                @change-page="changeCompanyPage"
                            />
                        </div>
                    </div>
                    <!--Customers list end-->

                    <div class="overflow-hidden rounded-lg bg-white shadow gap-5 px-4 py-5 sm:p-6">
                        <div class="flex items-center gap-2 mb-4">
                            <Icon class="w-6 h-6 fill-primary-default stroke-primary-default" icon="screening" />
                            <span class="text-xl">{{ $t("common.idcontrol&screening") }}</span>
                        </div>
                        <div class="flex gap-5 min-h-36">
                            <!-- ID control start-->
                            <IdControl
                                :person="person"
                                :show-menu="true"
                                @refresh-persons="getPersonDetails"
                            />
                            <!-- ID control end-->

                            <!-- screening start-->
                            <Screening
                                :person="person"
                                :show-menu="true"
                                @refresh-persons="getPersonDetails"
                            />
                            <!-- screening end-->
                        </div>
                    </div>
                </div>
                <div class="w-1/3 border-l border-light rounded-md px-6 mb-6 bg-white h-fit">
                    <Tab
                        :tabs="tabList"
                        @change-tab="changeTab"
                        :selected-tab-key="selectedTabKey"
                    />
                    <div>
                        <LastEvents
                            v-if="selectedTabKey == 'events'"
                            :person-id="person.id"
                            :events="events"
                        />
                        <Attachments
                            v-if="selectedTabKey == 'attachments'"
                            :attachments="person.attachments.sort((a, b) => b?.createdAt - a?.createdAt)"
                            :person-id="person.id"
                            @refresh="getPersonDetails"
                        />
                        <Notes
                            v-if="selectedTabKey == 'notes'"
                            @refresh="getPersonDetails"
                            :person-id="person.id"
                            :notes="person.notes"
                        />
                        <Tags
                            v-if="selectedTabKey == 'tags'"
                            :tags="person.tags"
                            :person-id="person.id"
                            @refresh="getPersonDetails"
                        />
                    </div>
                </div>
            </div>
        </Main>
    </Layout>
    <SidebarDialog :sidebar-open="openSidebarDialog" @closed="toggleSidebarDialog">
        <EditAddress
            @closed="toggleSidebarDialog"
            v-if="editPropertyName === 'address'"
            :person="person"
            @refresh="getPersonDetails"
        />
        <EditName
            @refresh="getPersonDetails"
            :person="person"
            @closed="toggleSidebarDialog"
            :property-name="editPropertyName === 'firstName' ? 'givenName' : 'familyName'"
            :title="editPropertyName === 'firstName' ? $t('common.person_info.firstName') : $t('common.person_info.lastName')"
            v-if="editPropertyName === 'firstName' || editPropertyName === 'lastName'"
        />
        <EditDOB
            @closed="toggleSidebarDialog"
            v-if="editPropertyName === 'dob'"
            :person="person"
            @refresh="getPersonDetails"
        />
        <EditEmail
            @refresh="getPersonDetails"
            @closed="toggleSidebarDialog"
            v-if="editPropertyName === 'email'"
            :person="person"
        />
        <EditPhone
            @refresh="getPersonDetails"
            @closed="toggleSidebarDialog"
            v-if="editPropertyName === 'phone'"
            :person="person"
        />
        <EditSSN
            @refresh="getPersonDetails"
            @closed="toggleSidebarDialog"
            v-if="editPropertyName === 'ssn'"
            :person="person"
        />
        <EditNationality
            @closed="toggleSidebarDialog"
            v-if="editPropertyName === 'country'"
            :person="person"
            @refresh="getPersonDetails"
        />
    </SidebarDialog>
</template>

<script lang="ts">
import { Layout, Main, Nav } from "@/components";
import IdControl from "@/components/customer/IdControl.vue";
import LastEvents from "@/components/customer/LastEvents.vue";
import Screening from "@/components/customer/Screening.vue";
import PersonDataBlockField from "@/components/datablocks/PersonDataBlockField.vue";
import HiddenText from "@/components/HiddenText.vue";
import Icon from "@/components/Icon.vue";
import Pagination from "@/components/Pagination.vue";
import Attachments from "@/components/person_profile/Attachments.vue";
import EditAddress from "@/components/person_profile/EditAddress.vue";
import EditDOB from "@/components/person_profile/EditDOB.vue";
import EditEmail from "@/components/person_profile/EditEmail.vue";
import EditName from "@/components/person_profile/EditName.vue";
import EditNationality from "@/components/person_profile/EditNationality.vue";
import EditPhone from "@/components/person_profile/EditPhone.vue";
import EditSSN from "@/components/person_profile/EditSSN.vue";
import PersonProfileHeader from "@/components/person_profile/Header.vue";
import Notes from "@/components/person_profile/Notes.vue";
import Tags from "@/components/person_profile/Tags.vue";
import SidebarDialog from "@/components/SidebarDialog.vue";
import Tab from "@/components/Tab.vue";
import lists from "@/i18n/en/index";
import { TabItem } from "@/lib/common/tabItem";
import { Event, EventScope, queryEvents } from "@/lib/events/events";
import { getPerson } from "@/lib/persons/get_person";
import { Address, HitStatus, Person, PersonCompaniesData } from "@/lib/persons/person";
import { queryPersonBelongsToCompanies } from "@/lib/persons/query_persons";
import { defineComponent } from "vue";
import countryDetails from "../../../public/assets/geography/countries.json";
import { formatDateObject } from "@/lib/date_object_formatter";
import { getAddressDisplayText } from "@/components/person/person_display_helper";

export default defineComponent({
    name: "PersonProfile",
    components: {
        Nav,
        Layout,
        Main,
        PersonProfileHeader,
        Tab,
        Icon,
        PersonDataBlockField,
        SidebarDialog,
        Attachments,
        Notes,
        Tags,
        Pagination,
        EditAddress,
        EditName,
        EditEmail,
        EditNationality,
        LastEvents,
        IdControl,
        Screening,
        HiddenText,
        EditDOB,
        EditPhone,
        EditSSN,
    },
    data() {
        return {
            selectedTabKey: "events",
            editPropertyName: "",
            customers: [] as PersonCompaniesData[] | null,
            openSidebarDialog: false,
            companyCurrentPage: 1,
            companyItemsPerPage: 5,
            person: {} as Person,
            HitStatus,
            lists,
            tabs: [] as TabItem[],
            countryDetails,
            events: [] as Event[],
        };
    },
    methods: {
        sourcesAndCountForField(path: string): string[] {
            const sources: string[] = [];
            // ["person_info", "name"]
            const nodes = path.split(".");

            // find the *selected* value of the specified property
            const val = nodes.reduce((prev, next) => (prev ? prev[next] : undefined), this.person?.data as any);

            if (val) {
                // we only care about blocks of same type as the block name in the path
                const blocks = (this.person?.blocks || []).filter((x) => x._block === nodes[0]);

                // remove block name since the blocks themselves don't include that as a property
                nodes.shift();

                for (const block of blocks) {
                    // find the value of the property in this specific block
                    const blockVal = nodes.reduce((prev, next) => (prev ? prev[next] : undefined), block as any);

                    // values are the same so the selected value is considered to be derived from this block
                    if (blockVal) {
                        sources.push(block._source);
                    }
                }
            }
            return sources;
        },
        formatDate(dateInput: any) {
            return formatDateObject(dateInput);
        },
        toggleSidebarDialog(propertyName: string) {
            this.openSidebarDialog = !this.openSidebarDialog;
            if (this.openSidebarDialog) {
                this.editPropertyName = propertyName;
            } else {
                this.editPropertyName = "";
            }
        },
        changeTab(tabKey: string) {
            this.selectedTabKey = tabKey;
        },
        changeCompanyPage(page: number) {
            this.companyCurrentPage = page;
        },

        addressObjectToString(address: Address) {
            if (address) {
                return getAddressDisplayText(address);
            }
        },

        async getPersonDetails() {
            try {
                this.person = await getPerson(this.$route.params.id as string);
            } catch (err) {
                console.error(err);
            }
        },

        async loadEvents() {
            try {
                const res = await queryEvents({ eventScope: EventScope.Person, resource: this.person.id });
                this.events = res.events;
            } catch (err) {
                console.error(err);
            }
        },
    },
    computed: {
        none(): string {
            return this.$t("project.tabs.company.blocks.general.valueUndefined");
        },
        tabList(): TabItem[] {
            return [
                { key: "events", text: "common.events", itemCount: this.events?.length },
                { key: "attachments", text: "common.attachments", itemCount: this.person.attachments?.length },
                { key: "notes", text: "common.notes", itemCount: this.person.notes?.length },
                { key: "tags", text: "common.tags", itemCount: this.person.tags?.length },
            ] as TabItem[];
        },
        peginatedCustomers(): PersonCompaniesData[] | null {
            return this.customers
                ? Array.from(this.customers).slice(
                    (this.companyCurrentPage - 1) * this.companyItemsPerPage,
                    this.companyCurrentPage * this.companyItemsPerPage,
                )
                : null;
        },
    },

    async created() {
        await this.getPersonDetails();
        this.customers = await queryPersonBelongsToCompanies(this.person.id);
        await this.loadEvents();
    },
});
</script>
<style>
.bg-gray-11 {
    background-color: #e7e7e7;
}

.bg-gray-13 {
    background-color: #d5d5d5;
    border: solid 1px #d5d5d5;
}
</style>
