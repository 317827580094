<template>
    <div class="card-container flex flex-col bg-white rounded px-5">
        <ul class="divide-y-2 divide-gray-300">
            <li
                class="py-5 relative"
                v-for="(step, i) in steps"
                :key="'s' + i"
            >
                <div class="card-container-grid grid gap-2">
                    <div class="flex gap-2">
                        <div
                            class="shrink-0 w-10 h-10 rounded-full bg-[#F4F4F4]"
                            :class="[
                                { 'srv3-step-icon-completed': step.statusType === StatusType.Completed },
                                { 'srv3-step-icon-error': step.statusType === StatusType.Error },
                                { 'srv3-step-icon-warning': step.statusType === StatusType.Warning },
                            ]"
                        >
                            <div
                                class="srv3-step-icon-text"
                                v-if="step.statusType === StatusType.Default || step.statusType === StatusType.InProgress"
                            >
                                {{ i + 1 }}
                            </div>
                            <div
                                class="srv3-step-icon-icon"
                                v-if="step.statusType === StatusType.Completed"
                            >
                                <FontIcon :icon="'check'" :size="20" />
                            </div>
                            <div
                                class="srv3-step-icon-icon"
                                v-if="step.statusType === StatusType.Error || step.statusType === StatusType.Warning"
                            >
                                <FontIcon :icon="'circle-exclamation'" :size="20" />
                            </div>
                        </div>
                        <div class="flex flex-col gap-1 pr-4">
                            <h4 class="text-base font-bold">
                                {{ $ct(step.name) }}
                            </h4>
                            <div class="flex flex-wrap gap-1">
                                <div
                                    class="flex h-[28px] py-[3px] px-[10px] rounded-[14px] bg-[#F4F4F4]"
                                    v-for="(status, j) in step.pills"
                                    :key="'p' + j"
                                    :class="[
                                        { 'srv3-step-pill-progress': status.type === StatusType.InProgress },
                                        { 'srv3-step-pill-completed': status.type === StatusType.Completed },
                                        { 'srv3-step-pill-error': status.type === StatusType.Error },
                                        { 'srv3-step-pill-warning': status.type === StatusType.Warning },
                                    ]"
                                >
                                    <div
                                        class="srv3-step-pill-dot"
                                        v-if="status.type !== StatusType.Default"
                                    />
                                    <div class="srv3-step-pill-text">
                                        {{ $ct(status.text) }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="pl-[48px]">
                        <StepActions
                            :key="'sa' + i"
                            :step="step"
                            @action="$emit('action', $event)"
                        />
                    </div>
                </div>
            </li>
        </ul>
    </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";
import { FlowRenderData, StatusType, StepRenderData } from "@/types/flow_types";
import FontIcon from "@/ui/FontIcon.vue";
import StepActions from "./StepActions.vue";

export default defineComponent({
    name: "StepRenderer",
    components: {
        FontIcon,
        StepActions,
    },
    emits: ["action"],
    props: {
        renderData: { type: Object as PropType<FlowRenderData>, required: true },
    },
    watch: {
        renderData: {
            deep: true,
            handler() {
                this.loadSteps();
            },
        },
    },
    data() {
        return {
            StatusType,
            steps: new Array<StepRenderData>(),
        };
    },
    methods: {
        loadSteps() {
            this.steps = [];
            this.$nextTick(() => {
                this.renderData.steps.forEach((step) => {
                    this.steps.push(step);
                });
            });
        },
    },
    mounted() {
        this.loadSteps();
    },
});
</script>
<style scoped>
.card-container {
    container-type: inline-size;
    container-name: card-container;
}
.card-container-grid {
    grid-template-columns: 1fr auto;
}
@container card-container (max-width: 600px) {
    .card-container-grid {
        grid-template-columns: unset;
    }
    :deep(.srv3-step-actions) {
        position: unset;
    }
    :deep(.srv3-step-main-action) {
        padding-top: 0;
    }
    :deep(.srv3-step-actions-menu-icon) {
        position: absolute;
        top: 16px;
        right: -16px;
    }
    :deep(.srv3-step-actions-menu) {
        top: 58px;
        right: 0px;
    }
}
/* Icon */
.srv3-step-icon-text {
    padding-top: 10px;
    text-align: center;
    font-family: "Roboto Slab";
    font-size: 12px;
    font-weight: 600;
}
.srv3-step-icon-icon {
    padding-top: 6px;
    text-align: center;
}
.srv3-step-icon-completed {
    background-color: #E6F5FF;
}
.srv3-step-icon-completed .srv3-step-icon-icon {
    color: #218BCB;
}
.srv3-step-icon-error .srv3-step-icon-icon {
    color: #B62537;
}
.srv3-step-icon-warning .srv3-step-icon-icon {
    color: #F2CA61;
}
/* Statuses */
.srv3-step-pill-dot {
    flex-shrink: 0;
    width: 7px;
    height: 7px;
    margin-top: 7px;
    margin-right: 7px;
    border-radius: 50px;
    background-color: #C4C4C4;
}
.srv3-step-pill-text {
    font-size: 14px;
    color: #444444;
}
.srv3-step-pill-progress {
    border: solid 1px #D5D5D5;
    background-color: #FFFFFF;
}
.srv3-step-pill-completed {
    border: solid 1px #218BCB;
    background-color: #B1D8F0;
}
.srv3-step-pill-error {
    border: solid 1px #FFE2E5;
    background-color: #FFE2E5;
}
.srv3-step-pill-warning {
    border: solid 1px #F2CA61;
    background-color: #FFEDBC;
}
.srv3-step-pill-completed .srv3-step-pill-dot {
    background-color: #218BCB;
}
.srv3-step-pill-error .srv3-step-pill-dot {
    background-color: #B62537;
}
.srv3-step-pill-warning .srv3-step-pill-dot {
    background-color: #F2CA61;
}
</style>
