import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { "aria-describedby": "role assignment" }
const _hoisted_2 = { class: "bg-white border border-muted rounded-lg p-5 w-3/4" }
const _hoisted_3 = { class: "flex items-center gap-2 mb-5 pl-2" }
const _hoisted_4 = { class: "font-roboto-slab text-3xl mb-0" }
const _hoisted_5 = { class: "" }
const _hoisted_6 = { class: "flex flex-col max-h-[600px]" }
const _hoisted_7 = { class: "flex-grow overflow-auto pl-2 pr-3" }
const _hoisted_8 = { class: "relative w-full border-separate border-spacing-y-1" }
const _hoisted_9 = { class: "sticky top-0 z-30" }
const _hoisted_10 = { class: "bg-background text-primary-default" }
const _hoisted_11 = { class: "pl-4 pr-10 py-3 border border-r-0 border-light" }
const _hoisted_12 = { class: "flex items-center gap-2" }
const _hoisted_13 = { class: "flex-grow" }
const _hoisted_14 = { class: "pl-4 pr-10 py-3 border border-light" }
const _hoisted_15 = { class: "flex items-center gap-2" }
const _hoisted_16 = { class: "pl-4 pr-10 py-4 text-left border border-r-0 border-light" }
const _hoisted_17 = { class: "pl-4 pr-10 py-4 text-left border border-light" }
const _hoisted_18 = { class: "bg-background mt-5 rounded-lg p-6 shadow-inner flex" }
const _hoisted_19 = { class: "flex-grow flex items-center" }
const _hoisted_20 = {
  key: 0,
  class: "flex justify-start gap-3 bg-success-background border border-muted rounded-lg px-5 py-3 w-max"
}
const _hoisted_21 = { class: "" }
const _hoisted_22 = {
  key: 1,
  class: "flex justify-start gap-3 bg-success-background border border-muted rounded-lg px-5 py-3 w-max"
}
const _hoisted_23 = { class: "" }
const _hoisted_24 = {
  "aria-describedby": "role assignment actions",
  class: "flex space-x-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontIcon = _resolveComponent("FontIcon")!
  const _component_InputCheckBox = _resolveComponent("InputCheckBox")!
  const _component_ActionButton = _resolveComponent("ActionButton")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_FontIcon, {
          icon: 'crown',
          size: 32,
          class: "text-primary-default bg-light-blue px-2 py-1 rounded-lg"
        }),
        _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t('settings.roleAssignment.title')), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("table", _hoisted_8, [
              _createElementVNode("thead", _hoisted_9, [
                _createElementVNode("tr", _hoisted_10, [
                  _createElementVNode("th", _hoisted_11, [
                    _createElementVNode("div", _hoisted_12, [
                      _createVNode(_component_FontIcon, {
                        icon: 'crown',
                        size: 14
                      }),
                      _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.$t('settings.roleAssignment.roleTable.headers.defaultRoles')), 1),
                      _createVNode(_component_FontIcon, {
                        icon: 'sort',
                        size: 14,
                        class: "self-end"
                      })
                    ])
                  ]),
                  _createElementVNode("th", _hoisted_14, [
                    _createElementVNode("div", _hoisted_15, [
                      _createVNode(_component_FontIcon, {
                        icon: 'sliders',
                        size: 14
                      }),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t('settings.roleAssignment.roleTable.headers.setRoleRules')), 1)
                    ])
                  ])
                ])
              ]),
              _createElementVNode("tbody", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.values, (options, role) => {
                  return (_openBlock(), _createElementBlock("tr", {
                    key: role,
                    class: ""
                  }, [
                    _createElementVNode("td", _hoisted_16, _toDisplayString(_ctx.$t(`core.roles.${role}`)), 1),
                    _createElementVNode("td", _hoisted_17, [
                      _createVNode(_component_InputCheckBox, {
                        items: _ctx.items,
                        "unique-id": `options_${role}`,
                        modelValue: _ctx.values[role],
                        "onUpdate:modelValue": ($event: any) => ((_ctx.values[role]) = $event),
                        "items-placement": "horizontal"
                      }, null, 8, ["items", "unique-id", "modelValue", "onUpdate:modelValue"])
                    ])
                  ]))
                }), 128))
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_18, [
          _createElementVNode("div", _hoisted_19, [
            (_ctx.saveSuccess)
              ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                  _createVNode(_component_FontIcon, {
                    icon: "circle-check",
                    size: 16
                  }),
                  _createElementVNode("span", _hoisted_21, _toDisplayString(_ctx.saveSuccess), 1)
                ]))
              : _createCommentVNode("", true),
            (_ctx.saveError)
              ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                  _createVNode(_component_FontIcon, {
                    icon: "circle-exclamation",
                    size: 16
                  }),
                  _createElementVNode("span", _hoisted_23, _toDisplayString(_ctx.saveError), 1)
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_24, [
            _createVNode(_component_ActionButton, {
              onBtnClicked: _ctx.save,
              disabled: !_ctx.changed,
              loading: _ctx.saving,
              class: "font-medium"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('settings.roleAssignment.actions.saveRoleRules')), 1)
              ]),
              _: 1
            }, 8, ["onBtnClicked", "disabled", "loading"])
          ])
        ])
      ])
    ])
  ]))
}