import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-349c35ce"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex" }
const _hoisted_2 = { class: "py-8 px-6 min-w-[485px] grow" }
const _hoisted_3 = { class: "flex gap-3" }
const _hoisted_4 = { class: "flex flex-wrap items-center gap-x-3 gap-y-2" }
const _hoisted_5 = { class: "font-roboto-slab leading-[1.25] text-[28px] text-body overflow-anywhere" }
const _hoisted_6 = { class: "flex gap-3 ml-auto" }
const _hoisted_7 = ["tooltip"]
const _hoisted_8 = ["tooltip"]
const _hoisted_9 = { class: "mt-3 mb-2 border-b border-gray-300" }
const _hoisted_10 = {
  key: 0,
  class: "pt-4 pr-4"
}
const _hoisted_11 = { class: "bg-white rounded-lg py-4 px-5 min-h-[500px] w-[400px] xl:w-[460px]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_IdentitySelector = _resolveComponent("IdentitySelector")!
  const _component_FontIcon = _resolveComponent("FontIcon")!
  const _component_CustomerAccessPopup = _resolveComponent("CustomerAccessPopup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Icon, {
          class: "w-8 h-8 shrink-0",
          icon: _ctx.displayIcon
        }, null, 8, ["icon"]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.project.name), 1),
          (_ctx.project.type === _ctx.ProjectType.Company)
            ? (_openBlock(), _createBlock(_component_IdentitySelector, {
                key: 0,
                project: _ctx.project,
                onRequestProjectRefresh: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('request-project-refresh', $event)))
              }, null, 8, ["project"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", {
            class: "text-gray-500 cursor-pointer",
            tooltip: _ctx.$t('project.access.iconTooltip'),
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.accessPopupOpen = true))
          }, [
            _createVNode(_component_FontIcon, { icon: "share-nodes" })
          ], 8, _hoisted_7),
          (_ctx.accessPopupOpen)
            ? (_openBlock(), _createBlock(_component_CustomerAccessPopup, {
                key: 0,
                project: _ctx.project,
                onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.accessPopupOpen = false)),
                onRequestProjectRefresh: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('request-project-refresh', $event)))
              }, null, 8, ["project"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: "text-gray-500 cursor-pointer",
            tooltip: _ctx.$t('project.deleteProject'),
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('delete')))
          }, [
            _createVNode(_component_FontIcon, { icon: 'trash-can' })
          ], 8, _hoisted_8)
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _renderSlot(_ctx.$slots, "tabs", {}, undefined, true)
      ]),
      _createElementVNode("div", null, [
        _renderSlot(_ctx.$slots, "content", {}, undefined, true)
      ])
    ]),
    (_ctx.showMenu)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _renderSlot(_ctx.$slots, "menu", {}, undefined, true)
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}