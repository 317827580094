import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3b41aa5e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "fbav3-input-checkbox mb-3" }
const _hoisted_2 = ["id", "name", "checked", "onChange"]
const _hoisted_3 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, i) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "flex items-center text-sm",
        key: _ctx.uniqueId + i
      }, [
        _createElementVNode("input", {
          id: _ctx.uniqueId + item.key,
          type: "checkbox",
          name: _ctx.uniqueId,
          checked: _ctx.modelValue[item.key],
          class: "w-4 h-4 bg-gray-100 border-gray-300 rounded focus:ring-primary-500 text-primary focus:ring-2",
          onChange: ($event: any) => (_ctx.onChange($event, item))
        }, null, 40, _hoisted_2),
        _createElementVNode("label", {
          for: _ctx.uniqueId + item.key,
          class: "ml-2 text-sm mb-0 font-medium text-gray-900"
        }, _toDisplayString(_ctx.$ct(item.text)), 9, _hoisted_3)
      ]))
    }), 128))
  ]))
}