<template>
    <div class="relative flex">
        <Combobox
            as="div"
            v-model="selectedItem"
            @update:model-value="(value) => updateModelValue(value)"
            :items="filteredItems"
            class="w-full"
            v-slot="{ open }"
        >
            <div class="relative">
                <ComboboxInput
                    class="w-full min-h-[42px] px-2 text-base border border-solid border-muted rounded-md"
                    @change="query = $event.target.value"
                    @blur="query = ''"
                    ref="combobox"
                    :display-value="(item: DropdownItem) => item?.text"
                    :placeholder="placeholder"
                />
                <ComboboxButton as="div" class="absolute inset-y-0 right-0 flex items-center pr-2">
                    <FontIcon class="" :icon="open ? 'angle-up' : 'angle-down'" />
                </ComboboxButton>

                <ComboboxOptions
                    v-if="filteredItems.length > 0"
                    class=" fixed  pl-0 z-10 mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg sm:text-sm"
                    style=" width: inherit"
                >
                    <ComboboxOption
                        v-for="item in filteredItems"
                        :key="item.value"
                        :value="item"
                        as="div"
                        class=" list-none list-item"
                        v-slot="{ active, selected }"
                    >
                        <div :class="['relative cursor-default select-none py-2 pl-2 pr-3', active ? ' bg-light outline-none' : '']">
                            <span :class="['block truncate', selected && 'font-semibold']">
                                {{ item.text }}
                            </span>
                        </div>
                    </ComboboxOption>
                </ComboboxOptions>
            </div>
        </Combobox>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { DropdownItem } from "@/ui/inputs/input_helper";
import FontIcon from "@/ui/FontIcon.vue";
import {
    Combobox,
    ComboboxButton,
    ComboboxInput,
    ComboboxOption,
    ComboboxOptions,
} from "@headlessui/vue";

export default defineComponent({
    name: "ComboBox",
    components: {
        Combobox,
        ComboboxButton,
        ComboboxInput,
        ComboboxOption,
        ComboboxOptions,
        FontIcon,
    },
    props: {
        modelValue: {
            type: String,
            required: true,
        },
        items: {
            type: Array as PropType<DropdownItem[]>,
            required: true,
        },
        placeholder: {
            type: String,
            default: "",
        },
    },
    emits: ["update:modelValue"],
    data() {
        return {
            query: "",
            selectedItem: null as DropdownItem | null,
        };
    },
    computed: {
        filteredItems(): DropdownItem[] {
            return this.query === ""
                ? this.items
                : this.items.filter((item: DropdownItem) => {
                    return item.text.toLowerCase().includes(this.query.toLowerCase());
                });
        },
    },
    methods: {
        updateModelValue(value: DropdownItem | null) {
            console.log("updateModelValue", value);
            this.$emit("update:modelValue", value?.value);
            this.query = "";
        },
        onBlur() {
            // Added this method to clear the query when input loses focus.
            console.log("query", this.query);
            console.log("selectedItem", this.selectedItem);
            this.query = this.selectedItem?.text || "";
        },
    },

});
</script>
