import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5c88e338"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "w-[60vw] h-[50vh]" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PopupDialog = _resolveComponent("PopupDialog")!

  return (_openBlock(), _createBlock(_component_PopupDialog, {
    "is-visible": _ctx.dialog.visible,
    header: _ctx.$t('common.sendIdControl'),
    icon: 'address-card',
    progress: _ctx.dialog,
    "show-cancel-button": true,
    "show-action-button": true,
    "action-text": _ctx.$t('common.sendIdControl'),
    "is-action-disabled": !_ctx.isValid,
    onCloseModal: _ctx.onClose,
    onAction: _ctx.onSubmitClicked
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("iframe", {
          id: "formdialogframe",
          title: "Form",
          scrolling: "false",
          src: _ctx.formUrl
        }, null, 8, _hoisted_2)
      ])
    ]),
    _: 1
  }, 8, ["is-visible", "header", "progress", "action-text", "is-action-disabled", "onCloseModal", "onAction"]))
}