<template>
    <div>

        <PopupDialog
            :is-visible="dialog.visible"
            :header="$t('project.forms.actions.openForm')"
            :icon="'clipboard-list'"
            :show-action-button="true"
            :show-cancel-button="true"
            :is-action-disabled="!dialog.valid"
            :action-text="$t('project.forms.actions.openForm')"
            :action-icon="'clipboard-list'"
            :progress="dialog"
            @close-modal="onClose"
            @action="getFormLink"
        >
            <PopupDialogPrompt
                :prompt-text="$t('project.forms.actions.editDraft')"
                :info-text="$t('project.forms.hints.editDraft')"
            />
        </PopupDialog>

    </div>
</template>
<script lang="ts">
import { PropType, defineComponent } from "vue";

import { Form } from "@/lib/forms/forms";

import PopupDialog from "@/ui/PopupDialog.vue";
import { getFormLink } from "@/lib/forms/get_form_link";
import { FlowAction } from "@/types/flow_types";
import { Verified } from "@/lib/verified_plugin";
import { getContactsFromProject } from "../contact_list/contact_list";
import { Project } from "@/lib/projects/projects";
import PopupDialogPrompt from "@/ui/dialog/PopupDialogPrompt.vue";

export default defineComponent({
    name: "FormLinkDialog",
    components: {
        PopupDialog,
        PopupDialogPrompt,
    },
    emits: ["close-modal", "open"],
    props: {
        action: { type: Object as PropType<FlowAction>, required: true },
        project: { type: Object as PropType<Project>, required: true },
        forms: { type: Object as PropType<Form[]>, required: true },
        formId: { type: String as PropType<string>, default: "" },
        actorId: { type: String as PropType<string>, default: "" },
    },
    data() {
        return {
            dialog: {
                visible: true,
                valid: false,
                isWorking: false,
                statusText: "",
                isError: false,
                errorText: "",
            },
            userUid: "",
            actualFormId: "",
            actualActorId: "",
        };
    },
    methods: {

        onClose() {
            this.$emit("close-modal");
        },

        openFormLink(formLink: string) {
            const formViewData = {
                formUrl: formLink,
                redirectRoute: { path: "/aml/customers/" + this.$route.params.id },
                contacts: getContactsFromProject(this.project),
            };
            sessionStorage.setItem("amlFormView", JSON.stringify(formViewData));
            this.$router.push({ path: "/aml/form" });
        },

        async getFormLink() {
            this.dialog.isError = false;
            this.dialog.isWorking = true;
            this.dialog.statusText = this.$t("common.gettingFormLink");

            const response = await getFormLink(this.actualFormId, this.actualActorId);

            if (response.responseInfo.isError) {
                this.dialog.errorText = response.responseInfo.parsedError?.message || "Unknown error";
                this.dialog.isError = true;
            } else {
                console.log("response.formLink", response.formLink);
                this.openFormLink(response.formLink);
            }

            this.dialog.isWorking = false;
        },

    },
    mounted() {
        this.userUid = Verified.instance.uid;

        if (this.formId) {
            this.actualFormId = this.formId;
        } else {
            if (this.action.params && this.action.params.formKey) {
                const formKey = this.action.params.formKey;
                for (const form of this.forms) {
                    if (form.templateKey === formKey && form.isSelected) {
                        this.actualFormId = form.id;
                    }
                }
            }
        }

        if (!this.actualFormId) {
            this.dialog.errorText = "Valid formId not provided!";
            this.dialog.isError = true;
            return;
        }

        if (this.actorId) {
            this.actualActorId = this.actorId;
        } else {
            for (const form of this.forms) {
                if (form.id === this.actualFormId) {
                    for (const actor of form.actors) {
                        if (actor.uid === this.userUid && !actor.completed) {
                            this.actualActorId = actor.id;
                            break;
                        }
                    }
                }
            }
        }

        if (!this.actualActorId) {
            this.dialog.errorText = "Valid actorId not provided!";
            this.dialog.isError = true;
            return;
        }

        this.dialog.valid = true;
    },
});
</script>
