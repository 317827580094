<template>
    <div class="fbav3-input-checkbox mb-3">
        <div
            class="flex items-center  text-sm"
            v-for="(item, i) in items"
            :key="uniqueId + i"
        >
            <input
                :id="uniqueId + item.key"
                type="checkbox"
                :name="uniqueId"
                :checked="modelValue[item.key]"
                class="w-4 h-4 bg-gray-100 border-gray-300 rounded focus:ring-primary-500 text-primary focus:ring-2"
                @change="onChange($event, item)"
            >
            <label :for="uniqueId + item.key" class="ml-2 text-sm mb-0 font-medium text-gray-900">
                {{ $ct(item.text) }}
            </label>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import { CheckboxItem, CheckboxModel } from "./input_helper";

export default defineComponent({
    name: "InputCheckbox2",
    props: {
        modelValue: {
            type: Object as PropType<CheckboxModel>,
            required: true,
        },
        items: {
            type: Array as PropType<CheckboxItem[]>,
            default: () => [],
        },
        uniqueId: {
            type: String as PropType<string>,
            required: true,
        },
    },
    emits: ["update:modelValue"],

    methods: {
        onChange(event: Event, item: CheckboxItem) {
            if (!(event.target instanceof HTMLInputElement)) return;

            const value = { ...this.modelValue };
            value[item.key] = event.target.checked;
            this.$emit("update:modelValue", value);
        },
    },
});
</script>

<style scoped>
.fbav3-input-checkbox input[type='checkbox'] {
    transform: scale(1);
    margin-left: 3px;
}
</style>
