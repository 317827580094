import { createI18n, I18nOptions } from "vue-i18n";
import en from "./en/index";
import fi from "./fi/index";
import no from "./no/index";
import sv from "./sv/index";

type TranslationSchema = typeof en;
export const DEFAULT_LOCALE = "en";
export const SUPPORTED_LOCALES = ["en", "sv", "no", "fi"];

export const extractLanguage = (locale: string) => {
    switch (locale) {
    case "en_EN": return "en";
    case "sv_SE": return "sv";
    case "nb_NO": return "no";
    case "fi_FI": return "fi";
    default: return locale.split("_")[0];
    }
};

export const i18localeToVerified = (locale: string) => {
    // todo: can we use the Verified platform format for locales so we don't have to do this?
    switch (locale) {
    case "en": return "en_EN";
    case "sv": return "sv_SE";
    case "no": return "nb_NO";
    case "fi": return "fi_FI";
    default: return "en_EN";
    }
};

export function getDefaultLocale() {
    const persistedLocale = localStorage.getItem("lang");

    if (persistedLocale) {
        return extractLanguage(persistedLocale);
    }

    return DEFAULT_LOCALE;
}

const options: I18nOptions = {
    legacy: false,
    locale: getDefaultLocale(),
    fallbackLocale: "en",
    globalInjection: true,
    messages: {
        en,
        no,
        sv,
        fi,
    },
    datetimeFormats: {
        en: {
            short: {
                hour: "numeric",
                minute: "numeric",
                hour12: false,
            },
            long: {
                day: "numeric",
                month: "short",
                year: "numeric",
            },
            longDateTime: {
                month: "short",
                day: "numeric",

                year: "numeric",
                hour: "numeric",
                minute: "numeric",
                hour12: false,
            },
            monthShort: {
                month: "short",
            },
            monthLong: {
                month: "long",
            },
        },
        sv: {
            short: {
                hour: "numeric",
                minute: "numeric",
                hour12: false,
            },
            long: {
                month: "short",
                day: "numeric",

                year: "numeric",
            },
            longDateTime: {
                month: "short",
                day: "numeric",

                year: "numeric",
                hour: "numeric",
                minute: "numeric",
                hour12: false,
            },
            monthShort: {
                month: "short",
            },
            monthLong: {
                month: "long",
            },
        },
    },
    numberFormats: {
        en: {
            currency: {
                style: "currency",
                currencyDisplay: "narrowSymbol",
                currency: "USD",
            },
            decimal: {
                style: "decimal",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            },
            percent: {
                style: "percent",
                useGrouping: false,
            },
        },
        sv: {
            currency: {
                style: "currency",
                currencyDisplay: "code",
                currency: "SEK",
            },
            decimal: {
                style: "decimal",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            },
            percent: {
                style: "percent",
                useGrouping: false,
            },
        },
    },
};

export default createI18n<false, typeof options>(options);
