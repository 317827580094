<template>
    <div>

        <div class="amlv3-formlist">

            <!-- <MenuSwitch
                v-model="filterMode"
                :items="filterItems"
                :has-button="!project.assessment.locked"
                :button-icon="'plus'"
                :button-text="$t('project.forms.actions.new')"
                @clicked="newDialog.visible = true"
            /> -->

            <div style="margin-top: 20px;">

                <div
                    class="amlv3-formlist-spinner"
                    v-if="loadingForms"
                >
                    <SpinnerVerified :size="50" />
                </div>

                <div
                    v-for="(form, i) in loadedForms"
                    :key="`flf${i}`"
                    style="margin-bottom: 10px;"
                >
                    <FormListItem
                        :project="project"
                        :form="form"
                        :user-uid="userUid"
                        :initially-open="form.id === initiallyOpenId"
                        @delete="onDeleteClicked(i)"
                        @request-project-refresh="$emit('request-project-refresh', $event)"
                    />
                </div>

            </div>

        </div>

        <!-- Select template and start form -->
        <PopupDialog
            :is-visible="newDialog.visible"
            :header="$t('project.forms.actions.start')"
            :icon="'clipboard-list'"
            :progress="newDialog"
            :show-cancel-button="true"
            :show-action-button="true"
            :action-text="$t('project.forms.actions.start')"
            :is-action-disabled="!selectedTemplate.key"
            @close-modal="newDialog.visible = false"
            @action="startForm"
        >

            <SimpleListItem
                v-for="(template, i) in availableTemplates"
                :key="'flt' + i"
                :text="template.name"
                :sub-text="template.name"
                :is-first="i === 0"
                :is-last="i === availableTemplates.length - 1"
                :is-selected="selectedTemplate && selectedTemplate.key == template.key"
                @clicked="selectedTemplate = template"
            />

        </PopupDialog>

        <!-- Delete confirmation dialog -->
        <PopupDialog
            :is-visible="deleteDialog.visible"
            :header="$t('common.actions.delete')"
            :icon="'trash-can'"
            :progress="deleteDialog"
            :prompt="$t('common.actions.areYouSure')"
            :show-action-button="true"
            :show-cancel-button="true"
            :action-text="$t('common.actions.delete')"
            @close-modal="deleteDialog.visible = false"
            @action="onDeleteForm"
        />

        <FormDialog
            v-if="isFormDialogVisible"
            @close-modal="onFormDialogClosed"
        />

    </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";

import { CreateFormOptions, createProjectForm, FormStartMode } from "@/lib/forms/create_form";
import { deleteForm } from "@/lib/forms/delete_form";
import { Form, FormContextType, FormScope } from "@/lib/forms/forms";
import { Verified } from "@/lib/verified_plugin";

import PopupDialog from "@/ui/PopupDialog.vue";
import SpinnerVerified from "@/ui/SpinnerVerified.vue";

import SimpleListItem from "@/ui/SimpleListItem.vue";
import FormDialog from "./FormDialog.vue";
import FormListItem from "./FormListItem.vue";

import { Project } from "@/lib/projects/projects";
import MenuSwitch from "../customer/menu/MenuSwitch.vue";
import { CheckboxItem } from "@/ui/inputs/input_helper";

enum OpenMode {
    Redirect = "redirect",
    NewTab = "newtab",
    Dialog = "dialog",
    Framed = "framed",
}

interface FormTemplate {
    slug: string;
    key: string;
    name: string;
    mode: OpenMode;
    scopes: FormScope[];
}

export default defineComponent({
    name: "FormList",
    components: {
        PopupDialog,
        SpinnerVerified,
        FormListItem,
        FormDialog,
        SimpleListItem,
        MenuSwitch,
    },
    emits: ["request-project-refresh"],
    props: {
        contextType: { type: String as PropType<FormContextType>, required: true },
        contextId: { type: String as PropType<string>, required: true },
        forms: { type: Array as PropType<Form[]>, required: true },
        project: { type: Object as PropType<Project>, required: true },
        initialKey: { type: String as PropType<string>, default: "" },
    },
    data() {
        return {
            loadingForms: false,
            loadedForms: new Array<Form>(),
            newDialog: { visible: false, isWorking: false, statusText: "", isError: false, errorText: "" },
            deleteDialog: { visible: false, index: -1, isWorking: false, statusText: "", isError: false, errorText: "" },
            isFormDialogVisible: false,
            availableTemplates: new Array<FormTemplate>(),
            selectedTemplate: {
                key: "",
                slug: "",
            } as FormTemplate,
            userUid: "",

            initialKeyInternal: "",
            initiallyOpenId: "",

            filterItems: [
                {
                    key: "selected",
                    textKey: "project.forms.filters.active",
                },
                {
                    key: "open",
                    textKey: "project.forms.filters.open",
                },
                {
                    key: "archived",
                    textKey: "project.forms.filters.archived",
                },
            ] as CheckboxItem[],
            filterMode: "selected",
        };
    },
    watch: {
        forms: {
            deep: true,
            handler() {
                this.loadForms();
            },
        },
    },
    methods: {

        onFormDialogClosed() {
            this.isFormDialogVisible = false;
        },

        onDeleteClicked(index: number) {
            this.deleteDialog.index = index;
            this.deleteDialog.visible = true;
        },

        async onDeleteForm() {
            this.deleteDialog.isWorking = true;
            this.deleteDialog.statusText = this.$t("common.working.deleting");

            try {
                const formId = this.forms[this.deleteDialog.index].id;
                const response = await deleteForm(formId);
                if (response.responseInfo.isError) {
                    this.deleteDialog.isError = true;
                    this.deleteDialog.errorText = response.responseInfo.parsedError?.message || "";
                } else {
                    this.$emit("request-project-refresh");
                    this.deleteDialog.visible = false;
                }
            } catch (error) {
                console.error(error);
            }

            this.deleteDialog.isWorking = false;
        },

        async startForm() {
            if (!this.selectedTemplate) return;
            this.newDialog.isWorking = true;
            this.newDialog.statusText = this.$t("project.forms.working.creating");
            console.log("Start Form:", this.selectedTemplate);

            const createOptions: CreateFormOptions = {
                startMode: FormStartMode.Create,
                contextId: this.contextId,
                templateSlug: this.selectedTemplate.slug,
                templateKey: this.selectedTemplate.key,
                scopes: this.selectedTemplate.scopes,
                model: {},
            };

            console.log("createOptions", createOptions);

            const response = await createProjectForm(createOptions);
            console.log("response", response);

            if (response.responseInfo.isError) {
                this.newDialog.isError = true;
                this.newDialog.errorText = response.responseInfo.parsedError?.message || "Unknown error";
            } else {
                const formViewData = {
                    formUrl: response.formLink,
                    redirectRoute: { path: "/aml/customers/" + this.$route.params.id },
                };
                sessionStorage.setItem("amlFormView", JSON.stringify(formViewData));

                if (this.selectedTemplate.mode === OpenMode.Framed) {
                    this.$router.push({ path: "/aml/form" });
                } else if (this.selectedTemplate.mode === OpenMode.Redirect) {
                    window.location.href = response.formLink;
                } else if (this.selectedTemplate.mode === OpenMode.NewTab) {
                    window.open(response.formLink, "_blank")?.focus();
                } else {
                    this.isFormDialogVisible = true;
                }
                this.newDialog.visible = false;
            }
            this.newDialog.isWorking = false;
        },

        loadForms() {
            this.loadedForms = [];
            this.forms.forEach((form) => {
                this.loadedForms.push(form);
                if ((form.templateKey === this.initialKey || form.templateKey === this.initialKeyInternal) && form.isSelected) {
                    this.initiallyOpenId = form.id;
                }
            });
        },

        highlightItem(itemKey: string) {
            this.initialKeyInternal = itemKey;
            this.loadedForms = [];
            this.$nextTick(() => {
                this.loadForms();
            });
        },

    },
    mounted() {
        this.userUid = Verified.instance.uid;
        this.loadForms();
        this.$config.settings.formTemplates?.forEach((template) => {
            if (template.scopes.includes(FormScope.KYC)) {
                this.availableTemplates.push({
                    slug: template.slug,
                    key: template.key,
                    name: this.$ct(template.name),
                    mode: OpenMode.Framed,
                    scopes: template.scopes,
                });
            }
        });
    },
});
</script>
<style scoped>
.amlv3-formlist {
    min-height: 50px;
}

.amlv3-formlist-spinner {
    padding: 40px 0px;
}

</style>
