import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "mb-2 text-md block" }
const _hoisted_2 = { class: "relative flex items-center" }
const _hoisted_3 = ["placeholder"]
const _hoisted_4 = { class: "absolute left-4" }
const _hoisted_5 = { class: "absolute right-4" }
const _hoisted_6 = {
  key: 0,
  class: "absolute w-full rounded-b-md top-[53px] bg-white border border-gray-300 z-10"
}
const _hoisted_7 = {
  key: 0,
  class: "text-sm font-bold p-5"
}
const _hoisted_8 = { class: "block" }
const _hoisted_9 = {
  key: 1,
  class: "max-h-[200px] overflow-y-scroll",
  ref: "resultsContainer"
}
const _hoisted_10 = ["onClick", "onKeydown"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_ActionButton = _resolveComponent("ActionButton")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("label", _hoisted_1, _toDisplayString(_ctx.$t("newCustomer.searchInstruction")), 1),
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("input", {
        type: "text",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.query) = $event)),
        placeholder: _ctx.$t('common.placeholders.startSearch') + '..',
        class: "pr-14 pl-14 p-4 rounded-md bg-white border border-gray-300 w-full",
        onKeydown: [
          _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.onArrowDown && _ctx.onArrowDown(...args)), ["down"])),
          _cache[2] || (_cache[2] = _withKeys(
//@ts-ignore
(...args) => (_ctx.onArrowUp && _ctx.onArrowUp(...args)), ["up"])),
          _cache[3] || (_cache[3] = _withKeys(
//@ts-ignore
(...args) => (_ctx.onEnter && _ctx.onEnter(...args)), ["enter"])),
          _cache[4] || (_cache[4] = _withKeys(
//@ts-ignore
(...args) => (_ctx.onTab && _ctx.onTab(...args)), ["tab"]))
        ],
        maxlength: 100,
        onInput: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args)))
      }, null, 40, _hoisted_3), [
        [_vModelText, _ctx.query]
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_Icon, {
          class: "w-6 h-6 text-gray-400",
          icon: "ion:business"
        })
      ]),
      _createElementVNode("div", _hoisted_5, [
        (_ctx.loading)
          ? (_openBlock(), _createBlock(_component_Icon, {
              key: 0,
              class: "w-6 h-6 opacity-70",
              icon: "tabler:loader-2"
            }))
          : _createCommentVNode("", true)
      ]),
      (_ctx.result)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            (!_ctx.result.companies.length)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t("newCustomer.noResult")) + " \"" + _toDisplayString(_ctx.query) + "\"", 1),
                  _createVNode(_component_ActionButton, {
                    onBtnClicked: _ctx.setManual,
                    class: "mt-2 mr-auto block"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("newCustomer.manualFillHint")), 1)
                    ]),
                    _: 1
                  }, 8, ["onBtnClicked"])
                ]))
              : _createCommentVNode("", true),
            (_ctx.result.companies.length)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.result.companies, (company, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: company.name,
                      onClick: ($event: any) => (_ctx.select(company)),
                      onKeydown: _withKeys(($event: any) => (_ctx.select(company)), ["enter"]),
                      class: _normalizeClass({
                            'p-2  cursor-pointer hover:bg-primary-default hover:text-white text-sm': true,
                            'bg-primary-default text-white': index === _ctx.selectedIndex,
                        })
                    }, _toDisplayString(company.name), 43, _hoisted_10))
                  }), 128))
                ], 512))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}