<template>
    <PopupDialog
        :is-visible="true"
        :header="$t('project.access.header')"
        :icon="project.confidential ? 'lock' : 'lock-open'"
        :progress="dialog"
        :show-cancel-button="true"
        :show-action-button="true"
        :action-icon="confidential ? 'lock' : 'lock-open'"
        :is-action-disabled="confidential == project.confidential"
        :action-text="$t('project.access.actionText')"
        :is-warning="false"
        @close-modal="$emit('close')"
        @action="update"
    >
        <div class="w-[500px] rounded-md" style="border: 1px solid #e1e1e1">
            <div class="bg-gray-100 mb-4 rounded-t-md text-sm bg-gray-[#f4f4f4] p-4">
                {{ $t("project.access.information") }}
            </div>

            <div v-if="hasProjectsRemoveDefaultAclEntriesPolicy">
                <div class="flex gap-3 m-4">
                    <SwitchInput v-model="confidential" />
                    <label>{{ $t("project.access.confidentialSwitchLabel") }}</label>
                </div>

                <div
                    v-if="confidential != project.confidential"
                    class="m-4 text-sm flex gap-2 bg-yellow-50 p-3 rounded-md"
                    style="border: 1px solid #b88739"
                >
                    <svg
                        class="shrink-0 size-4 mt-0.5 text-yellow-800"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    >
                        <path d="m21.73 18-8-14a2 2 0 0 0-3.48 0l-8 14A2 2 0 0 0 4 21h16a2 2 0 0 0 1.73-3Z"></path>
                        <path d="M12 9v4"></path>
                        <path d="M12 17h.01"></path>
                    </svg>

                    <div v-if="!confidential">
                        <p class="text-yellow-800">
                            {{ $t("project.access.rolesWillBeAddedWarning") }}
                            <span class="font-bold">{{ $t("project.access.rolePrivileged") }}, {{ $t("project.access.roleAdmin") }}</span>
                        </p>
                    </div>
                    <div v-if="confidential">
                        <p class="text-yellow-800">
                            {{ $t("project.access.rolesWillBeRemovedWarning") }}
                            <span class="font-bold">{{ $t("project.access.rolePrivileged") }}, {{ $t("project.access.roleAdmin") }}</span>
                            {{ $t("project.access.rolesWillBeAddedOrRemovedUnless") }}
                        </p>
                    </div>
                </div>
            </div>

            <div v-if="!hasProjectsRemoveDefaultAclEntriesPolicy">
                <div
                    class="m-4 text-sm flex gap-2 bg-red-50 p-3 rounded-md"
                    style="border: 1px solid #b83939"
                >
                    <svg
                        class="shrink-0 size-4 mt-0.5 text-red-800"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    >
                        <path d="m21.73 18-8-14a2 2 0 0 0-3.48 0l-8 14A2 2 0 0 0 4 21h16a2 2 0 0 0 1.73-3Z"></path>
                        <path d="M12 9v4"></path>
                        <path d="M12 17h.01"></path>
                    </svg>

                    <p class="text-red-800">
                        {{ $t("project.access.noAccess") }}
                    </p>

                </div>
            </div>

        </div>
    </popupdialog>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import { Project } from "@/lib/projects/projects";
import { UpdateProjectOptions, updateProjectAcl, defaultAclEntries } from "@/lib/projects/update_project";
import CustomerTopAction from "./CustomerTopAction.vue";
import PopupDialog from "@/ui/PopupDialog.vue";
import { getProjectProcess } from "@/lib/projects/get_project";
import SimpleListItem from "@/ui/SimpleListItem.vue";
import InputText from "@/ui/inputs/InputText.vue";
import SwitchInput from "@/components/SwitchInput.vue";
import { Policy } from "@/lib/platform";

export default defineComponent({
    name: "CustomerAccessPopup",
    components: {
        CustomerTopAction,
        PopupDialog,
        SimpleListItem,
        InputText,
        SwitchInput,
    },
    emits: ["request-project-refresh", "close"],
    props: {
        project: { type: Object as PropType<Project>, required: true },
    },
    data() {
        return {
            dialog: { isVisible: true, isWorking: false, statusText: "", isError: false, errorText: "" },
            confidential: false,
            acl: [] as string[],
            hasProjectsRemoveDefaultAclEntriesPolicy: false,
        };
    },
    computed: {
    },
    watch: {
    },
    methods: {
        async update() {
            if (this.confidential === this.project.confidential) {
                return;
            }

            this.dialog.isError = false;
            this.dialog.isWorking = true;
            this.dialog.statusText = this.$t("project.access.working");
            const response = await updateProjectAcl(this.project.id, defaultAclEntries, this.confidential ? "remove" : "add");
            if (response.responseInfo.isError) {
                this.dialog.errorText = response.responseInfo.parsedError?.message || "Unknown error";
                this.dialog.isError = true;
            } else {
                const projectResponse = await getProjectProcess(this.project.id);
                this.$emit("request-project-refresh", projectResponse);
                this.dialog.isVisible = false;
                this.$emit("close");
            }
            this.dialog.isWorking = false;
        },
    },
    async mounted() {
        this.confidential = this.project.confidential;
        this.acl = this.project.acl;
        const policies = await this.$assets.getPolicies();
        if (policies.includes(Policy.ProjectsRemoveDefaultAclEntries)) {
            this.hasProjectsRemoveDefaultAclEntriesPolicy = true;
        }
    },
});
</script>
