import { ApiResponse, parseLocalError, parseRemoteError } from "../error_parser";
import { Verified } from "../verified_plugin";
import { AssessmentAttachment } from "./get_assessment_attachments";

export const lockAssessment = async (projectId: string, assessmentId: string, locked: boolean): Promise<{ wasLocked: boolean, responseInfo: ApiResponse }> => {
    try {
        const response = await fetch(`/api/aml/v1/projects/${projectId}/assessments/${assessmentId}/lock`, {
            method: "POST",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
            body: JSON.stringify({
                locked,
            }),
        });

        const body = await response.json();

        if (!response.ok) {
            return {
                wasLocked: false,
                responseInfo: { isError: true, parsedError: parseRemoteError(body) },
            };
        }

        return {
            wasLocked: true,
            responseInfo: { isError: false, parsedError: null },
        };
    } catch (error) {
        return {
            wasLocked: false,
            responseInfo: { isError: true, parsedError: parseLocalError(error) },
        };
    }
};

export const sealAssessment = async (projectId: string, assessmentId: string, attachments: AssessmentAttachment[]): Promise<{ wasSealed: boolean, responseInfo: ApiResponse }> => {
    try {
        const response = await fetch(`/api/aml/v1/projects/${projectId}/assessments/${assessmentId}/seal`, {
            method: "POST",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
            body: JSON.stringify({ attachments }),
        });

        const body = await response.json();

        if (!response.ok) {
            return {
                wasSealed: false,
                responseInfo: { isError: true, parsedError: parseRemoteError(body) },
            };
        }

        return {
            wasSealed: true,
            responseInfo: { isError: false, parsedError: null },
        };
    } catch (error) {
        return {
            wasSealed: false,
            responseInfo: { isError: true, parsedError: parseLocalError(error) },
        };
    }
};

export const getAssesmentReport = async(projectId: string, assessmentId: string): Promise<{url: string, attachments: AssessmentAttachment[], responseInfo: ApiResponse }> => {
    try {
        const response = await fetch(`/api/aml/v1/projects/${projectId}/assessments/${assessmentId}/report`, {
            method: "GET",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
        });
        const body = await response.json();
        if (!response.ok) {
            return {
                url: "",
                attachments: [],
                responseInfo: { isError: true, parsedError: null },
            };
        }
        return {
            url: body.url,
            attachments: body.attachments,
            responseInfo: { isError: false, parsedError: parseRemoteError(body) },
        };
    } catch (error) {
        return {
            url: "",
            attachments: [],
            responseInfo: { isError: true, parsedError: parseLocalError(error) },
        };
    }
};
interface Attachment {
    url: string,
    attachmentSourcceId: string,
    name: string,
    contentType: string,
}
export const getAssessmentAttachments = async(projectId: string, assessmentId: string): Promise<{attachment: Attachment[], responseInfo: ApiResponse }> => {
    try {
        const response = await fetch(`/api/aml/v1/projects/${projectId}/assessments/${assessmentId}/urls`, {
            method: "GET",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
        });
        const body = await response.json();
        if (!response.ok) {
            return {
                attachment: [],
                responseInfo: { isError: true, parsedError: null },
            };
        }
        return {
            attachment: body.attachements,
            responseInfo: { isError: false, parsedError: parseRemoteError(body) },
        };
    } catch (error) {
        return {
            attachment: [],
            responseInfo: { isError: true, parsedError: parseLocalError(error) },
        };
    }
};

export const downloadAssessmentReport = async (
    presignedUrl: string,
): Promise<{ file: Blob | null, responseInfo: ApiResponse }> => {
    try {
        const fileResponse = await fetch(presignedUrl, {
            method: "GET",
        });

        if (!fileResponse.ok) {
            return {
                file: null,
                responseInfo: {
                    isError: true,
                    parsedError: parseLocalError(`HTTP error: ${fileResponse.status} ${fileResponse.statusText}`),
                },
            };
        }

        const blob = await fileResponse.blob();

        console.log("fileResponse", fileResponse);

        return {
            file: blob,
            responseInfo: { isError: false, parsedError: null },
        };
    } catch (error) {
        return {
            file: null,
            responseInfo: { isError: true, parsedError: parseLocalError(error) },
        };
    }
};
