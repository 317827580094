import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputLabel = _resolveComponent("InputLabel")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_InputGroup = _resolveComponent("InputGroup")!
  const _component_InputRow = _resolveComponent("InputRow")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_InputRow, null, {
      default: _withCtx(() => [
        _createVNode(_component_InputGroup, { width: 12 }, {
          default: _withCtx(() => [
            _createVNode(_component_InputLabel, {
              text: _ctx.$t('newCustomer.inputs.company.label'),
              required: true,
              "unique-id": "company-name"
            }, null, 8, ["text"]),
            _createVNode(_component_InputText, {
              modelValue: _ctx.company.name,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.company.name) = $event)),
              "unique-id": "company-name",
              placeholder: _ctx.$t('newCustomer.inputs.company.placeholder'),
              required: ""
            }, null, 8, ["modelValue", "placeholder"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_InputRow, null, {
      default: _withCtx(() => [
        _createVNode(_component_InputGroup, { width: 6 }, {
          default: _withCtx(() => [
            _createVNode(_component_InputLabel, {
              text: _ctx.$t('newCustomer.inputs.orgNumber.label'),
              required: true,
              "unique-id": "company-org-nr"
            }, null, 8, ["text"]),
            _createVNode(_component_InputText, {
              modelValue: _ctx.company.registrationNumber,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.company.registrationNumber) = $event)),
              "unique-id": "company-org-nr",
              placeholder: _ctx.$t('newCustomer.inputs.orgNumber.placeholder'),
              required: ""
            }, null, 8, ["modelValue", "placeholder"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}