import { LocalizedString } from "@/lib/common/common";
import { RiskIndicatorDefinition, RiskValueMode, RiskValueSource, RiskValueType } from "@/lib/risk/risk_matrix";
import { RiskAssets } from "./risk_assets";
import { getRandomString } from "./risk_helper";
import { getDefaultScores } from "./special_types/special_types";

export interface AvailableIndicator {
    key: string;
    name: LocalizedString;
    hint: LocalizedString;
}

export const getCommonIndicators = (): AvailableIndicator[] => {
    const list: AvailableIndicator[] = [
        {
            key: "reg-country",
            name: { en_EN: "Registration Country", sv_SE: "Registreringsland", nb_NO: " Registreringsland" },
            hint: { en_EN: "The country where the company is registered." },
        },
        {
            key: "nace",
            name: { en_EN: "NACE codes", sv_SE: "NACE koder", nb_NO: "NACE koder", fi_FI: "NACE koodit" },
            hint: { en_EN: "Business codes (NACE) for the company." },
        },
        {
            key: "high-risk-country",
            name: { en_EN: "High Risk Countries (EU list)", sv_SE: "Högriskländer (EU-lista)", nb_NO: " Høyrisikoland (EU-liste)", fi_FI: " Korkean riskin maat (EU-luettelo)" },
            hint: { en_EN: "Matches a country against EUs list of high risk countries." },
        },
        {
            key: "pep",
            name: { en_EN: "PEP Hits", sv_SE: "PEP träffar", nb_NO: "PEP treff", fi_FI: "PEP osumat" },
            hint: { en_EN: "Checks for PEP hits on key roles." },
        },
        {
            key: "listed",
            name: { en_EN: "Listed", sv_SE: "Börsnoterad", fi_FI: "Pörssinoteerattu", nb_NO: "Børsnotert" },
            hint: { en_EN: "Check if the company is listed on any stock exchange." },
        },

        {
            key: "company-type",
            name: { en_EN: "Company Type", sv_SE: "Bolagsform", nb_NO: " Foretaksform", fi_FI: "Yhtiömuoto" },
            hint: { en_EN: "Check if the company is of a specific type." },
        },
        {
            key: "registration-date",
            name: { en_EN: "Registration Date", sv_SE: "Registreringsdatum", nb_NO: "Registreringsdato", fi_FI: "Rekisteröintipäivä" },
            hint: { en_EN: "Check the date the company was registered." },
        },

        {
            key: "turnover",
            name: { en_EN: "Turnover", sv_SE: "Omsättning", nb_NO: "Omsetning", fi_FI: "Liikevaihto" },
            hint: { en_EN: "Latest reported turnover" },
        },

    ];
    return list;
};

export const getTypeIndicators = (): AvailableIndicator[] => {
    const list: AvailableIndicator[] = [
        {
            key: "type-string",
            name: { en_EN: "Free Text", sv_SE: "Fritext", fi_FI: "Vapaa teksi", nb_NO: "Fritekst" },
            hint: { en_EN: "Input can be anything, matches against text values." },
        },
        {
            key: "type-number",
            name: { en_EN: "Number", sv_SE: "Nummer", nb_NO: "Nummer", fi_FI: "Numero" },
            hint: { en_EN: "Matches will be based on if the number matches the range provided." },
        },
        {
            key: "type-check",
            name: { en_EN: "Multiple Choice", sv_SE: "Flervalsalternativ", fi_FI: "Monivalinta", nb_NO: "Flervalgsspørsmål" },
            hint: { en_EN: "Select between one or more predefined values." },
        },
        {
            key: "type-date",
            name: { en_EN: "Date Age", sv_SE: "Datumålder", fi_FI: "Päivämääräikä", nb_NO: "Datoalder" },
            hint: { en_EN: "The input will be a date, matches will be based on the time passed since that date." },
        },
        {
            key: "type-country",
            name: { en_EN: "Country", sv_SE: "Land", nb_NO: "Land", fi_FI: "Maa" },
            hint: { en_EN: "Match against country codes." },
        },

    ];
    return list;
};

export const getPredefinedIndicator = (key: string, assets: RiskAssets): RiskIndicatorDefinition => {
    const randomKey = getRandomString(8);
    const indicator: RiskIndicatorDefinition = {
        key: randomKey,
        name: { en_EN: randomKey },
        weight: 100,
        hrm: 0,
        lrm: 0,
        valueType: RiskValueType.MultipleChoice,
        sources: [],
        values: [],
        isFixed: true,
        logic: [],
        scores: {},
        unknownScore: 9,
        hints: {},
        params: {},
    };

    if (key === "reg-country") {
        indicator.name = { en_EN: "Registration Country", sv_SE: "Registreringsland", nb_NO: " Registreringsland" };
        indicator.valueType = RiskValueType.Country;
        indicator.sources = [{
            source: RiskValueSource.CompanyInfo,
            key: "reg-country",
        }];
        indicator.scores = getDefaultScores(indicator.valueType, assets);
    }

    if (key === "nace") {
        indicator.name = { en_EN: "Business code", sv_SE: "Branschkod", fi_FI: "Toimialakoodi", nb_NO: "Virksomhetskode" };
        indicator.valueType = RiskValueType.NaceCode;
        indicator.sources = [{
            source: RiskValueSource.CompanyInfo,
            key: "nace-codes",
        }];
        indicator.scores = getDefaultScores(indicator.valueType, assets);
    }

    if (key === "high-risk-country") {
        indicator.name = { en_EN: "High Risk Country", sv_SE: "Högriskländ", nb_NO: " Høyrisikoland ", fi_FI: " Korkean riskin maa" };
        indicator.valueType = RiskValueType.HighRiskCountry;
        indicator.sources = [{
            source: RiskValueSource.CompanyInfo,
            key: "reg-country",
        }];
        indicator.scores = getDefaultScores(indicator.valueType, assets);
    }

    if (key === "pep") {
        indicator.name = { en_EN: "PEP", sv_SE: "PEP träffar", nb_NO: "PEP treff", fi_FI: "PEP osumat" };
        indicator.valueType = RiskValueType.PepHit;
        indicator.sources = [{
            source: RiskValueSource.Role,
            key: "key.any.pep",
        }];
        indicator.scores = getDefaultScores(indicator.valueType, assets);
    }

    if (key === "type-string") {
        indicator.name = { en_EN: "Free Text Input", sv_SE: "Fritextinmatning", fi_FI: "Vapaa teksi  syöttö", nb_NO: "Fritekstinndata" };
        indicator.valueType = RiskValueType.FreeText;
        indicator.isFixed = false;
    }
    if (key === "type-number") {
        indicator.name = { en_EN: "Number Input", sv_SE: "Sifferinmatning", fi_FI: "Numerosyöttö", nb_NO: "Tallinntasting" };
        indicator.valueType = RiskValueType.Number;
        indicator.isFixed = false;
    }
    if (key === "type-check") {
        indicator.name = { en_EN: "Multiple Choice", sv_SE: "Flervalsalternativ", fi_FI: "Monivalinta", nb_NO: "Flervalgsspørsmål" };
        indicator.valueType = RiskValueType.MultipleChoice;
        indicator.isFixed = false;
    }
    if (key === "type-date") {
        indicator.name = { en_EN: "Date Input", sv_SE: "Datumålder", fi_FI: "Päivämääräikä", nb_NO: "Datoalder" };
        indicator.valueType = RiskValueType.DateAge;
        indicator.isFixed = false;
    }
    if (key === "type-country") {
        indicator.name = { en_EN: "Country", sv_SE: "Land", nb_NO: "Land", fi_FI: "Maa" };
        indicator.valueType = RiskValueType.Country;
    }

    if (key === "listed") {
        indicator.name = { en_EN: "Listed", sv_SE: "Börsnoterad", fi_FI: "Pörssinoteerattu", nb_NO: "Børsnotert" };
        indicator.valueType = RiskValueType.YesNo;
        indicator.values.push({
            key: "yes",
            mode: RiskValueMode.Option,
            name: { en_EN: "Yes", sv_SE: "Ja", nb_NO: "Ja", fi_FI: "Kyllä" },
        });
        indicator.values.push({
            key: "no",
            mode: RiskValueMode.Option,
            name: { en_EN: "No", sv_SE: "Nej", nb_NO: "Nei", fi_FI: "Ei" },
        });
        indicator.scores = {
            yes: 1,
            no: 9,
        };
        indicator.sources = [{
            source: RiskValueSource.CompanyInfo,
            key: "listed",
        }];
    }

    if (key === "company-type") {
        indicator.name = { en_EN: "Company Type", sv_SE: "Bolagsform", nb_NO: " Foretaksform", fi_FI: "Yhtiömuoto" };
        indicator.sources = [{
            source: RiskValueSource.CompanyInfo,
            key: "company-type",
        }];
        indicator.valueType = RiskValueType.CompanyType;
        indicator.scores = getDefaultScores(indicator.valueType, assets);
    }

    if (key === "registration-date") {
        indicator.name = { en_EN: "Registration Date", sv_SE: "Registreringsdatum", nb_NO: "Registreringsdato", fi_FI: "Rekisteröintipäivä" };
        indicator.valueType = RiskValueType.DateAge;
        indicator.isFixed = false;
        indicator.sources = [{
            source: RiskValueSource.CompanyInfo,
            key: "registration-date",
        }];
    }

    if (key === "turnover") {
        indicator.name = { en_EN: "Turnover", sv_SE: "Omsättning", nb_NO: "Omsetning", fi_FI: "Liikevaihto" };
        indicator.valueType = RiskValueType.Number;
        indicator.isFixed = false;
        indicator.sources = [{
            source: RiskValueSource.CompanyInfo,
            key: "turnover",
        }];
    }

    return indicator;
};
