<template>
    <div class="flex flex-col min-w-full">
        <textarea
            v-if="isMultiline"
            class="fbav3-input-text"
            :id="uniqueId"
            :value="modelValue"
            :placeholder="placeholder"
            @input="onInput"
            @keyup.enter="onEnterClicked"
            :rows="5"
            :readonly="readonly"
            :aria-multiline="true"
        />
        <input
            v-else
            class="fbav3-input-text"
            :id="uniqueId"
            :type="isPassword ? 'password' : ''"
            :value="modelValue"
            :placeholder="placeholder"
            @input="onInput"
            @keyup.enter="onEnterClicked"
            :readonly="readonly"
            :maxlength="maxLength"
            @blur="onBlur"
        />
        <p class=" text-danger-default text-sm p-1" v-if="error">
            {{ error }}
        </p>
    </div>
</template>
<script lang="ts">
import { defineComponent, type PropType } from "vue";
import { TextInputType } from "./input_helper";

export default defineComponent({
    name: "InputText",
    data() {
        return {
            TextInputType,
            error: "",
            touched: false,
        };
    },
    props: {
        modelValue: { type: [String, Number] as PropType<string | number>, required: true },
        uniqueId: { type: String as PropType<string>, required: true },
        isPassword: { type: Boolean as PropType<boolean>, default: false },
        placeholder: { type: String as PropType<string>, default: "" },
        isMultiline: { type: Boolean as PropType<boolean>, default: false },
        readonly: { type: Boolean as PropType<boolean>, default: false },
        valueType: { type: String as PropType<TextInputType.Text | TextInputType.Date | TextInputType.Number | TextInputType.Email>, default: TextInputType.Text },
        maxLength: { type: Number as PropType<number>, default: 50 },
        required: { type: Boolean as PropType<boolean>, default: false },
    },
    emits: ["update:modelValue", "enter", "validationError", "setDate"],
    methods: {
        onInput(event: Event) {
            this.touched = true;
            let value = (event.target as HTMLInputElement).value;
            // Prevent only spaces
            if (value.trim() === "" && value.length > 0) {
                value = "";
                (event.target as HTMLInputElement).value = value;
                this.validate(value);
                return;
            }
            if (this.valueType === TextInputType.Number) {
                value = value.replace(/[^0-9]/g, ""); // Allow only numbers
            }

            (event.target as HTMLInputElement).value = value;
            this.validate(value);
        },

        onBlur(event: Event) {
            const value = (event.target as HTMLInputElement).value;
            if (!value) {
                return;
            }
            (event.target as HTMLInputElement).value = value.trim();
            if (this.touched) {
                this.validate(value.trim());
            }
        },

        validate(value: string) {
            this.error = "";
            const trimmedValue = value.trim();
            if (this.required && trimmedValue === "") {
                this.error = this.$t("common.requiredField");
            } else if (this.valueType === TextInputType.Email) {
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (!emailRegex.test(trimmedValue)) {
                    this.error = this.$t("common.invalidEmail");
                }
            }
            this.$emit("validationError", this.error);
            this.$emit("update:modelValue", trimmedValue);
        },

        onEnterClicked() {
            this.$emit("enter");
        },
    },
});
</script>
<style scoped>
.fbav3-input-text {
    width: 100%;
    min-height: 42px;
    padding: 0px 8px;
    font-size: 16px;
    font-weight: 400;
    color: #444444;
    border-radius: 4px;
    border: solid 1px #d5d5d5;
    box-sizing: border-box;
}
</style>
