import { RegularSettings } from "../settings/settings";
import { Event } from "./events";
import i18n, { i18localeToVerified } from "@/i18n";
const { t } = i18n.global;

console.log("locale.ts", i18n.global.locale.value);
let approvalName : string | undefined = "";
export enum EventType {
    ProjectCreated = "project_created",
    ProjectUpdated = "project_updated",
    ProjectCompanyLookup = "project_company_lookup",

    FormCompleted = "form_completed",
    FormSubmitted = "form_submitted",
    FormSaved = "form_saved",

    AssessmentLocked = "assessment_locked",
    AssessmentUnlocked = "assessment_unlocked",
    AssessmentSealed = "assessment_sealed",

    ApprovalAborted = "approval_aborted",
    ApprovalApproved = "approval_approved",
    ApprovalDenied = "approval_denied",
    ApprovalStarted = "approval_started",
    ApproverApproved = "approver_approved",
    ApproverDenied = "approver_denied",

    PersonCreated = "person_created",
    PersonMonitorToggled = "person_monitor_toggled",

    LinkCreated = "link_created",
    LinkUpdated = "link_updated",
}

export interface EventDisplayData {
    title: string;
    description: string;
    icon: string;
    userName: string;
    dateString: string;
}

export const getEventDisplayData = (event: Event, settings: RegularSettings): EventDisplayData => {
    const display: EventDisplayData = {
        title: event.type,
        description: event.type,
        icon: "circle-question",
        userName: "Unknown User",
        dateString: formartEventDate(event.timestamp),
    };
    if (event.data?.issuer?.name) display.userName = event.data?.issuer?.name;

    const eventType = event.type as EventType;
    if (event.data?.approval?.name) {
        const lang = i18localeToVerified(i18n.global.locale.value);
        const approvalNameObject = settings.approvalConfigs?.find(approvalConfig => approvalConfig.key === event.data?.approval?.name)?.name;
        if (approvalNameObject) { approvalName = approvalNameObject[lang] || approvalNameObject.en_EN; }
    }

    switch (eventType) {
    case EventType.ProjectCreated: return getProjectCreated(event, display);
    case EventType.ProjectUpdated: return getProjectUpdated(event, display);
    case EventType.ProjectCompanyLookup: return getCompanyLookupDone(event, display);

    case EventType.FormCompleted: return getFormCompleted(event, display);

    case EventType.AssessmentLocked: return getAssessmentLocked(event, display);
    case EventType.AssessmentUnlocked: return getAssessmentUnlocked(event, display);
    case EventType.AssessmentSealed: return getAssessmentSealed(event, display);

    case EventType.ApprovalAborted: return getApprovalAborted(event, display);
    case EventType.ApprovalApproved: return getApprovalApproved(event, display);
    case EventType.ApprovalDenied: return getApprovalDenied(event, display);
    case EventType.ApprovalStarted: return getApprovalStarted(event, display);
    case EventType.ApproverApproved: return getApproverApproved(event, display);
    case EventType.ApproverDenied: return getApproverDenied(event, display);
    case EventType.FormSaved: return getFormSaved(event, display);
    case EventType.FormSubmitted: return getFormSubmitted(event, display);

    case EventType.PersonMonitorToggled: return getPersonMonitorToggled(event, display);

    case EventType.LinkCreated: return getLinkCreated(event, display);
    }

    return display;
};

export const formartEventDate = (eventDate: number) => {
    const date = new Date(eventDate).toLocaleDateString(undefined, {
        month: "short",
        year: "numeric",
        day: "numeric",
        minute: "2-digit",
        hour: "2-digit",
        hour12: true,
    });
    return date.toString();
};

const getProjectCreated = (event: Event, display: EventDisplayData): EventDisplayData => {
    display.icon = "briefcase";
    display.title = t("eventText.customerCreated");
    return display;
};

const getProjectUpdated = (event: Event, display: EventDisplayData): EventDisplayData => {
    display.icon = "briefcase";
    display.title = t("eventText.customerUpdated");
    return display;
};

const getCompanyLookupDone = (event: Event, display: EventDisplayData): EventDisplayData => {
    display.icon = "briefcase";
    display.title = t("eventText.companyLookupDone");
    return display;
};

const getFormCompleted = (event: Event, display: EventDisplayData): EventDisplayData => {
    display.icon = "file-circle-check";
    // display.title = `Form "${event.data?.form?.name}" completed`;
    display.title = t("eventText.formCompleted", { formName: event.data?.form?.name });
    return display;
};

const getFormSubmitted = (event: Event, display: EventDisplayData): EventDisplayData => {
    display.icon = "add";
    display.title = t("eventText.formSubmitted", { formName: event.data?.form?.name });
    return display;
};

const getFormSaved = (event: Event, display: EventDisplayData): EventDisplayData => {
    display.icon = "success";
    display.title = t("eventText.formSaved", { formName: event.data?.form?.name });
    return display;
};

const getAssessmentLocked = (event: Event, display: EventDisplayData): EventDisplayData => {
    display.icon = "lock";
    display.title = t("eventText.assessmentLocked");
    return display;
};

const getAssessmentUnlocked = (event: Event, display: EventDisplayData): EventDisplayData => {
    display.icon = "lock-open";
    display.title = t("eventText.assessmentUnlocked");
    return display;
};

const getAssessmentSealed = (event: Event, display: EventDisplayData): EventDisplayData => {
    display.icon = "file-zipper";
    display.title = t("eventText.assessmentSealed");
    return display;
};

const getApprovalAborted = (event: Event, display: EventDisplayData): EventDisplayData => {
    display.icon = "ban";
    // display.title = `Approval process aborted (${event.data?.approval?.name})`;
    display.title = t("eventText.approvalAborted", { approvalName: approvalName });
    return display;
};

const getApprovalApproved = (event: Event, display: EventDisplayData): EventDisplayData => {
    display.icon = "check";
    display.title = t("eventText.approvalApproved", { approvalName: approvalName });
    return display;
};

const getApprovalDenied = (event: Event, display: EventDisplayData): EventDisplayData => {
    display.icon = "xmark";
    display.title = t("eventText.approvalDenied", { approvalName: approvalName });
    return display;
};

const getApprovalStarted = (event: Event, display: EventDisplayData): EventDisplayData => {
    display.icon = "bell-concierge";
    display.title = t("eventText.approvalStarted", { approvalName: approvalName });
    return display;
};

const getApproverApproved = (event: Event, display: EventDisplayData): EventDisplayData => {
    display.icon = "check";
    display.title = t("eventText.assessmentApproved");
    return display;
};

const getApproverDenied = (event: Event, display: EventDisplayData): EventDisplayData => {
    display.icon = "xmark";
    display.title = t("eventText.approvalDeclined");
    return display;
};

const getLinkCreated = (event: Event, display: EventDisplayData): EventDisplayData => {
    display.icon = "people-arrows";
    // display.title = `${event.data?.person?.name} was linked to ${event.data?.project?.name} with role(s) ${event.data?.roles?.join(", ")}`;
    display.title = t("eventText.personLinkedToProjectWithRoles",
        { personName: event.data?.person?.name, projectName: event.data?.project?.name, roles: event.data?.roles?.map((role: string) => t("core.roles." + role)).join(", ") });
    return display;
};

const getPersonMonitorToggled = (event: Event, display: EventDisplayData): EventDisplayData => {
    const state = !!event.data?.monitor;
    display.icon = state ? "magnifying-glass-plus" : "magnifying-glass-minus";
    // display.title = `${state ? "Started" : "Stopped"} monitoring ${event.data?.resource?.name}`;
    display.title = state ? t("eventText.personMonitoringStarted", { resourceName: event.data?.resource?.name }) : t("eventText.personMonitoringStopped", { resourceName: event.data?.resource?.name });
    return display;
};
