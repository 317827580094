import { Verified } from "../verified_plugin";
import { Address } from "@/types/address";
import { DateObject } from "@/types/date";

export enum PersonScreeningProvider {
    Pliance = "pliance",
}

export interface PersonScreeningMatch {
    matchId: string;
    aliasId: string;
    pep: boolean;
    sanction: boolean;
    sip: boolean;
    rca: boolean;
    givenName: string;
    familyName: string;
    score: number;
}

export interface PersonScreeningHit {
    nationalIdentificationNumber?: string;
    pep: boolean;
    sanction: boolean;
    sip: boolean;
    rca: boolean;
    names: {
        givenName: string;
        familyName: string;
        type: string; // Description of name. I.e. "Primary Name" or "Spelling Variation"
    }[],
    birthdates: DateObject[],
    addresses: Address[],
    countries: string[],
    nationalities: string[],
    roles: {
        description: string;
        active: boolean;
        from: DateObject;
        to: DateObject;
    }[];
    relations: {
        givenName: string;
        familyName: string;
        relationType: string;
        relationPersonId: string;
        pep: boolean;
        rca: boolean;
        sanction: boolean;
        sip: boolean;
    }[];
    gender?: string;
    active?: boolean;
    deceased?: boolean;
    images: string[],
    lists: string[];
    notes: string[];
    sources: string[];
    score: number;
    scoreDescription: string; // TODO move to frontend or store as enum instead of string
}

export interface PersonScreening {
    id: string;
    namespace: string;
    personId: string;
    source: PersonScreeningProvider;
    externalSourceId: string;
    falsePositive: boolean;
    match: PersonScreeningMatch;
    hit: PersonScreeningHit;
}

export type screenPersonOptions = {
    personId: string;
    reference?: string | null;
    givenName: string;
    familyName: string;
    dateOfBirth?: string | null;
};

export const updateMonitoringStatus = async (status: boolean, personId: string) => {
    const baseUrl = "/api/aml/v1/persons";
    try {
        const response = await fetch(`${baseUrl}/${personId}/monitor`, {
            method: "PUT",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
            body: JSON.stringify({
                monitored: status,
            }),
        });
        const res = await response.json();

        if (!response.ok) {
            console.log(response);
        }
        return res;
    } catch (error) {
        console.log(error);
        throw (error);
    }
};

export const deletePersonScreening = async(personId: string) => {
    try {
        const result = await fetch(`/api/aml/v1/screening/person/${personId}`, {
            method: "delete",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
        });
        console.log(result);
    } catch (error) {
        throw new Error(`${error}`);
    }
};

export const getPersonScreeningHits = async(personId: string): Promise<{ hits: PersonScreening[], total: number }> => {
    try {
        const result = await fetch(`/api/aml/v1/persons/${personId}/hits`, {
            method: "get",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
        });
        const screening = await result.json();
        console.log(screening, "screeing hit");
        return screening;
    } catch (error) {
        throw new Error(`${error}`);
    }
};

export const updatePersonScreeningFalsePositives = async (personId: string, payload: {[key: string]: boolean}) => {
    try {
        const result = await fetch(`/api/aml/v1/persons/${personId}/mark-false-positives`, {
            method: "put",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
            body: JSON.stringify(payload),
        });
        const screening = await result.json();
        console.log(screening, "screeing hit");
        return screening;
    } catch (error) {
        throw new Error(`${error}`);
    }
};
