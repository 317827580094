<template>
    <PopupDialog
        :is-visible="true"
        :header="$t('idcontrol.manualIdHeading')"
        :progress="dialog"
        :show-cancel-button="true"
        :show-action-button="true"
        :action-text="$t('idcontrol.save')"
        @close-modal="onCloseModal"
        :icon="'address-card'"
        @action="manuallyAddIdControl"
    >
        <div class="w-full border border-solid border-light rounded-lg p-5">
            <div>
                <div class="ml-2">
                    {{ $t('idcontrol.manualComment') }}
                </div>
                <InputGroup :width="12">
                    <InputLabel
                        :text="'SSN'"
                        :unique-id="'ssn'"
                        required
                    />
                    <InputText
                        v-model="ssn"
                        :unique-id="'ssn'"
                        :placeholder="$t('common.socialSecurityNumber')"
                        required
                        maxlength="12"
                        :value-type="TextInputType.Number"
                        @validation-error="handleSsnValidation"
                    />
                </InputGroup>
                <InputGroup :width="12">
                    <textarea
                        v-model="comment"
                        :unique-id="'comment'"
                        :placeholder="$t('idcontrol.comment')"
                        class="border rounded-md border-gray-300 p-2"
                    />
                </InputGroup>
            </div>
        </div>
    </PopupDialog>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import PopupDialog from "@/ui/PopupDialog.vue";
import { IDControlState, Person, PersonWithRole } from "@/lib/persons/person";
import InputLabel from "@/ui/inputs/InputLabel.vue";
import InputGroup from "@/ui/inputs/InputGroup.vue";
import InputText from "@/ui/inputs/InputText.vue";
import { updateIdControlDetails } from "@/lib/persons/update_person";
import { TextInputType } from "@/ui/inputs/input_helper";

export default defineComponent({
    name: "ManualIdControl",
    components: {
        PopupDialog,
        InputGroup,
        InputLabel,
        InputText,
    },
    props: {
        person: {
            type: Object as PropType<PersonWithRole | Person>,
            required: true,
        },
    },
    data() {
        return {
            dialog: { isWorking: false, statusText: this.$t("common.loading"), isError: false, errorText: "" },
            ssn: "",
            comment: "",
            TextInputType,
        };
    },
    emits: ["close-modal", "refresh-persons"],
    methods: {
        onCloseModal() {
            this.$emit("close-modal");
        },
        handleSsnValidation(error: string) {
    this.dialog.isError = !!error;
    this.dialog.errorText = error;
},
        async manuallyAddIdControl() {
            try {
                this.dialog.isWorking = true;
                const idControlData = {
                    status: IDControlState.Completed,
                    ssn: this.ssn,
                    comment: this.comment,
                    method: "manual",
                    source: "manual",
                    updatedAt: Date.now(),
                    matchIndicator: 8,
                };

                await updateIdControlDetails(this.person.id, idControlData);
                this.refreshPersons();
                this.onCloseModal();
            } catch (error) {
                this.dialog.isError = true;
                this.dialog.errorText = this.$t("idcontrol.errorText");
                this.dialog.isWorking = false;
            }
        },
        refreshPersons() {
            this.$emit("refresh-persons", this.person.id);
        },
    },
});
</script>
