<template>
    <div class="amlv3-customer">
        <div
            class="amlv3-customer-content"
            :class="[
                {'amlv3-customer-content-menu': showMenu },
            ]"
        >

            <div class="amlv3-customer-header">
                <div class="amlv3-customer-header-grow">
                    <div class="amlv3-customer-header-icon">
                        <Icon class="w-[32px] h-[32px]" :icon="displayIcon" />
                    </div>
                    <div class="amlv3-customer-header-text">
                        {{ project.name }}
                    </div>
                    <IdentitySelector
                        v-if="project.type === ProjectType.Company"
                        :project="project"
                        @request-project-refresh="$emit('request-project-refresh', $event)"
                    />
                    <!-- <div v-if="project.type === ProjectType.Company" class="amlv3-customer-header-info">
                        {{ 'ORG # ' + project.identity }}
                    </div> -->
                </div>

                <div class="amlv3-customer-header-actions">
                    <div
                        class="amlv3-customer-header-action"
                        :class="[
                            {'amlv3-customer-header-action-working': isUpdating },
                        ]"
                        :tooltip="$t('project.reloadData')"
                        @click="triggerUpdate"
                    >
                        <FontIcon v-if="!isUpdating" :icon="'rotate'" />
                        <SpinnerVerified
                            v-if="isUpdating"
                            :size="14"
                            :hide-icon="true"
                        />
                    </div>
                    <div
                        class="amlv3-customer-header-action"
                        :tooltip="$t('project.deleteProject')"
                        @click="$emit('delete')"
                    >
                        <FontIcon :icon="'trash-can'" />
                    </div>
                </div>
            </div>

            <div class="mt-3 mb-2">
                <slot name="tabs" />
            </div>

            <div class="border-b border-gray-300" />

            <div>
                <slot name="content" />
            </div>

        </div>
        <div class="amlv3-customer-menu-container" v-if="showMenu">
            <div class="amlv3-customer-menu">
                <slot name="menu" />
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";

import { Project, ProjectType } from "@/lib/projects/projects";

import FontIcon from "@/ui/FontIcon.vue";
import Icon from "../../components/Icon.vue";
import SpinnerVerified from "@/ui/SpinnerVerified.vue";
import { updateProject, UpdateProjectOptions } from "@/lib/projects/update_project";
import { getProjectProcess } from "@/lib/projects/get_project";
import IdentitySelector from "@/components/customer/IdentitySelector.vue";

export default defineComponent({
    name: "CustomerLayout",
    components: {
        FontIcon,
        SpinnerVerified,
        IdentitySelector,
        Icon,
    },
    emits: ["delete", "request-project-refresh"],
    props: {
        project: { type: Object as PropType<Project>, required: true },
        showMenu: { type: Boolean as PropType<boolean>, required: true },
    },
    data() {
        return {
            isUpdating: false,
            ProjectType,
        };
    },
    computed: {
        displayIcon() {
            switch (this.project.type) {
            case "person":
                return "person-blue";
            case "comapany" as string:
                return "company";

            default:
                return "company";
            }
        }
    },
    methods: {

        async triggerUpdate() {
            if (this.isUpdating) return;
            if (!this.project) return;

            this.isUpdating = true;

            const options: UpdateProjectOptions = {
                workflow: this.project.workflow,
            };
            const response = await updateProject(this.project.id, options);
            if (response.responseInfo.isError) {
                console.error("Error:", response.responseInfo.parsedError?.message || "Unknown error");
            } else {
                const projectResponse = await getProjectProcess(this.project.id);
                this.$emit("request-project-refresh", projectResponse);
            }

            this.isUpdating = false;
        },

    },
});
</script>
<style>
.amlv3-customer {
    display: flex;
}

.amlv3-customer-content {
    flex-grow: 1;
    padding: 30px 50px;
    padding-bottom: 40px;
}

.amlv3-customer-content-menu {
    padding-right: 20px;
}

.amlv3-customer-header {
    display: flex;
}

.amlv3-customer-header-grow {
    flex-grow: 1;
    display: flex;
}

.amlv3-customer-header-icon {
    margin-top: 3px;
}

.amlv3-customer-header-text {
    margin-left: 15px;
    font-family: "Roboto Slab";
    font-size: 28px;
    font-weight: 400;
    color: #444444;
}

.amlv3-customer-header-info {
    margin-top: 8px;
    margin-left: 15px;
    height: 28px;
    padding: 4px 12px;
    font-size: 14px;
    font-weight: 500;
    color: #444444;
    border-radius: 17px;
    background-color: white;
}

.amlv3-customer-header-actions {
    display: flex;
    gap: 10px;
}

.amlv3-customer-header-action {
    color: dimgray;
    cursor: pointer;
}

.amlv3-customer-header-action:hover {
    opacity: 0.7;
}

.amlv3-customer-header-action-working {
    padding-top: 5px;
    padding-right: 6px;
    cursor: initial;
}
.amlv3-customer-header-action-working:hover {
    opacity: 1;
}

.amlv3-customer-menu-container {
    flex-shrink: 0;
    padding-top: 15px;
    padding-right: 15px;
}

.amlv3-customer-menu {
    width: 460px;
    min-height: 500px;
    padding: 15px 20px;
    border-radius: 8px;
    background-color: white;
}
</style>
