import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PopupDialogPrompt = _resolveComponent("PopupDialogPrompt")!
  const _component_PopupDialog = _resolveComponent("PopupDialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_PopupDialog, {
      "is-visible": _ctx.dialog.visible,
      header: _ctx.$t('common.actions.addEditor'),
      icon: 'add',
      "show-cancel-button": true,
      "show-action-button": true,
      "action-text": _ctx.$t('common.actions.addEditorYesText'),
      "cancel-button-text": _ctx.$t('common.actions.addEditorNoText'),
      progress: _ctx.dialog,
      onCloseModal: _ctx.onClose,
      onAction: _ctx.addEditor
    }, {
      default: _withCtx(() => [
        _createVNode(_component_PopupDialogPrompt, {
          "prompt-text": _ctx.$t('project.forms.addEditor.promptText'),
          "info-text": _ctx.$t('project.forms.addEditor.hintText'),
          "extra-info-text": _ctx.form.name
        }, null, 8, ["prompt-text", "info-text", "extra-info-text"])
      ]),
      _: 1
    }, 8, ["is-visible", "header", "action-text", "cancel-button-text", "progress", "onCloseModal", "onAction"])
  ]))
}