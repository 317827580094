<template>
    <Layout>
        <Nav />
        <Main>
            <section role="region" class="flex w-full min-h-screen font-roboto">
                <section
                    role="region"
                    aria-labelledby="customers list filters"
                    class="min-h-screen w-1/4 px-6 py-7 border-r bg-white"
                >
                    <div aria-labelledby="filterHeader" class="flex justify-between items-center mb-8">
                        <span class="text-2xl">{{ $t('customers.title') }}</span>
                        <a class="text-sm text-primary-default" @click="clearFilters">{{ $t('customers.clearAll') }}</a>
                    </div>
                    <div class="flex flex-col gap-4">
                        <div role="search" class="relative rounded-md h-12">
                            <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                <Icon
                                    class="h-4 w-4"
                                    aria-hidden="true"
                                    :icon="'search'"
                                />
                            </div>
                            <input
                                :placeholder="$t('customers.searchCustomer')"
                                type="text"
                                name="search"
                                id="searchInput"
                                v-model="searchQuery"
                                @input="debouncedSearch"
                                class="block w-full h-full rounded-[100px] border border-light py-1.5 pl-10 placeholder:font-normal placeholder:text-light-gray sm:text-sm sm:leading-4"
                            />
                        </div>
                        <div role="option" aria-labelledby="risk options">
                            <label class="text-xs leading-3 font-semibold">{{ $t('customers.filters.risk').toUpperCase() }}</label>
                            <div class="mt-2">
                                <InputCheckbox
                                    v-model="selectedRisk"
                                    :items="riskTypes"
                                    unique-id="unique-checkbox-id"
                                />
                            </div>
                        </div>
                        <div aria-labelledby="customer managers">
                            <label class="text-xs leading-3 font-semibold">{{ $t('customers.filters.assignee').toUpperCase() }}</label>
                            <InputDropdown
                                v-model="selectedCustomerManager"
                                :items="customerManagers"
                            />
                        </div>
                        <div aria-labelledby="assessment states">
                            <label class="text-xs leading-3 font-semibold">{{ $t('customers.filters.status').toUpperCase() }}</label>
                            <InputDropdown
                                v-model="selectedAssesment"
                                :items="assesmentTypes"
                                :searchable="false"
                            />
                        </div>
                        <div aria-labelledby="workflow types">
                            <label class="text-xs leading-3 font-semibold">{{ $t('customers.filters.workflow').toUpperCase() }}</label>
                            <InputDropdown
                                v-model="selectedWorkflow"
                                :items="getWorkflowItemsWithCustomerCount"
                            />
                        </div>
                        <div aria-labelledby="updated">
                            <label class="text-xs leading-3 font-semibold">{{ $t('customers.filters.updated').toUpperCase() }}</label>
                            <div class="flex">
                                <DatePicker
                                    placeholder-text="From"
                                    bg-color="bg-white"
                                    :is-range="true"
                                    ref="datePickerRef"
                                    class="grow basis-0  hover:cursor-pointer"
                                    v-model="selectedDate"
                                />
                                <div class="w-1 h-1"></div>
                                <!-- <DatePicker placeholderText="To" bgColor="bg-white"
                                    class="grow basis-0 hover:cursor-pointer" modelValue="toDate">
                                </DatePicker> -->
                            </div>
                        </div>
                        <div aria-labelledby="project entities">
                            <label class="text-xs leading-3 font-semibold">{{ $t('customers.filters.projectEntities').toUpperCase() }}</label>
                            <div class="mt-2">
                                <InputCheckbox
                                    :items="entityTypes"
                                    v-model="selectedEntity"
                                    unique-id="enty-types-id"
                                />
                            </div>
                        </div>
                    </div>
                </section>

                <section class="w-3/4 h-full p-4">
                    <div class="" v-if="projectsLoading">
                        <div class="">
                            <LoadAnimationBar :height="90" />
                            <LoadAnimationBar :height="90" />
                            <LoadAnimationBar :height="90" />
                            <LoadAnimationBar :height="90" />
                            <LoadAnimationBar :height="90" />
                            <LoadAnimationBar :height="90" />
                            <LoadAnimationBar :height="90" />
                            <LoadAnimationBar :height="90" />
                            <LoadAnimationBar :height="90" />
                            <LoadAnimationBar :height="90" />
                        </div>
                    </div>
                    <div
                        v-if="!projectsLoading"
                        role="region"
                        aria-labelledby="customers list"
                        class=""
                    >
                        <div class="w-full h-full">
                            <div class="flex items-center mb-5">
                                <span
                                    class="px-4 py-2 text-xl text-center flex font-medium rounded-full border border-light"
                                >{{
                                    customers.total }}</span>
                                <span class="text-xl font-medium ml-3">{{ $t('customers.resultsFound', customers.total) }}</span>
                            </div>
                            <div class="flex flex-col" v-if="customers.projects.length > 0">
                                <ul role="list" class="divide-y divide-light border-light">
                                    <li
                                        v-for="(project, index) in paginatedProjects"
                                        :key="'project_' + index"
                                        class="overflow-hidden hover:shadow-lg"
                                        :class="{
                                            'rounded-t-lg': index === 0,
                                            'rounded-b-lg': index === customers.projects.length - 1
                                        }"
                                    >
                                        <CustomerCard
                                            @click="$router.push({ path: `/aml/customers/${project.id}/overview` })"
                                            :details="project"
                                            :risk-threshold="riskTypes"
                                            :employees="customerManagers"
                                        />
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="w-full mt-3">
                            <Pagination
                                :items-count="customers.total"
                                :items-per-page="itemsPerPage"
                                :current-page="currentPage"
                                @change-page="changePage"
                                @next-page="nextPage"
                                @prev-page="prevPage"
                            />
                        </div>
                    </div>
                </section>

            </section>
        </Main>
    </Layout>
</template>

<script lang="ts">
import { queryProjects } from "@/lib/projects/get_project";
import { Project } from "@/lib/projects/projects";
import { defineComponent, ref } from "vue";
import { DatePicker, Icon, Layout, Main, Nav } from "../components";
import CustomerCard from "../components/CustomerCard.vue";
import Pagination from "../components/Pagination.vue";
import store from "../store";
import debounce from "debounce";
import InputCheckbox from "@/ui/inputs/InputCheckBox.vue";
import { CheckboxItem, DropdownItem } from "@/ui/inputs/input_helper";
import InputDropdown, { Option } from "@/ui/inputs/InputDropdown.vue";
import { format } from "date-fns";
import { Employee } from "@/lib/common/identity";
import { RiskThreshold } from "@/lib/settings/settings";
import { WorkflowConfig } from "@/types/flow_types";
import LoadAnimationBar from "@/ui/LoadAnimationBar.vue";

export default defineComponent({
    name: "Customers",
    components: {
        Nav,
        Layout,
        Main,
        Icon,
        DatePicker,
        CustomerCard,
        Pagination,
        InputDropdown,
        InputCheckbox,
        LoadAnimationBar,
    },
    data() {
        return {
            store,
            projectsLoading: true,
            customers: {
                total: 0,
                projects: [] as Project[],
            },
            currentPage: 1,
            itemsPerPage: 10,
            searchQuery: "",
            fromDate: null as string | null,
            toDate: null as string | null,
            selectedProjects: [],
            selectedCustomerManager: "",
            selectedRisk: ref<string[]>([]),
            selectedEntity: ref<string[]>([]),
            selectedAssesment: "",
            selectedDate: {} as any,
            selectedWorkflow: "",
            employees: [] as Employee[],
            workFlowItems: [] as Option[],
        };
    },
    methods: {
        async fetchProjects() {
            this.projectsLoading = true;
            try {
                const offset = this.currentPage === 0 ? this.currentPage = 0 : this.currentPage - 1;

                const entity = Object.values(this.selectedEntity);

                // todo: check for unintended consequenses
                const risk = Object.values(this.selectedRisk);
                if (risk.length > 0 && risk[0] === "") {
                    risk.splice(0, 1);
                }

                const params: any = {
                    offset,
                    limit: this.itemsPerPage,
                    searchQuery: this.searchQuery,
                    fromDate: this.fromDate,
                    toDate: this.toDate,
                    assignee: this.selectedCustomerManager ? this.selectedCustomerManager : null,
                    type: entity,
                    risk: risk,
                    assessmentStatus: this.selectedAssesment,
                    workflow: this.selectedWorkflow !== "all" ? this.selectedWorkflow : "",

                };
                const { total, projects } = await queryProjects(
                    params.offset,
                    params.limit,
                    params.searchQuery,
                    params.fromDate,
                    params.toDate,
                    params.assignee,
                    undefined,
                    params.workflow,
                    params.risk,
                    params.type,
                    params.assessmentStatus,
                );
                this.customers.total = total;
                if (total === 0) {
                    this.currentPage = 0;
                }
                this.customers.projects = projects;
                this.updateUrlParams();
            } catch (err) {
                console.error(err);
            } finally {
                this.projectsLoading = false;
            }
        },
        nextPage() {
            if (this.currentPage < Math.ceil(this.customers.total / this.itemsPerPage)) {
                this.currentPage++;
                this.fetchProjects();
            }
        },
        handleSelectedCustomerManager(newValue: string) {
            this.selectedCustomerManager = newValue;
            this.fetchProjects();
        },
        handleSelectedAssesment(newValue: string) {
            this.selectedAssesment = newValue;
            this.fetchProjects();
        },
        prevPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
                this.fetchProjects();
            }
        },
        changePage(page: number) {
            this.currentPage = page;
            this.fetchProjects();
        },
        debouncedSearch: debounce(function (this: any) {
            this.currentPage = 1;
            this.fetchProjects();
        }, 300),
        onDateChange() {
            if (this.fromDate && this.toDate) {
                this.fetchProjects();
            }
        },
        handleSelectedDateChange(date: any) {
            if (date.start !== "" && date.end !== "") {
                this.fromDate = format(date.start, "yyyy-MM-dd");
                this.toDate = format(date.end, "yyyy-MM-dd");
                this.updateUrlParams();
            }
        },
        clearSelectedDates() {
            const datePickerComponent = (this.$refs.datePickerRef as any);
            if (datePickerComponent && typeof datePickerComponent.clearDate === "function") {
                datePickerComponent.clearDate();
            }
        },
        clearFilters() {
            this.searchQuery = "";
            this.fromDate = null;
            this.toDate = null;
            this.currentPage = 1;
            this.selectedCustomerManager = "";
            this.selectedEntity = [];
            this.selectedRisk = [];
            this.selectedAssesment = "";
            this.selectedWorkflow = "";
            this.clearSelectedDates();
            this.fetchProjects();
        },
        updateUrlParams() {
            const query: any = {
                companyId: this.$route.query.companyId,
                searchQuery: this.searchQuery,
                fromDate: this.fromDate,
                toDate: this.toDate,
                currentPage: this.currentPage.toString(),
                assesmentStatus: this.selectedAssesment,
                selectedRisk: Array.isArray(this.selectedRisk) ? this.selectedRisk.join(",") : this.selectedRisk,
                workflow: this.selectedWorkflow,
                assignee: this.selectedCustomerManager ?? null,
            };
            this.$router.push({ query });
        },
        async fetchEmployees() {
            const employees: Employee[] = await this.$assets.getEmployees();
            this.employees = employees;
        },
    },
    computed: {
        paginatedProjects(): Project[] {
            return this.customers.projects;
        },
        getWorkflowItemsWithCustomerCount() : DropdownItem[] | [] {
            const workflows = this.$config.settings.workflows || [];

            if (workflows) {
                const workflowDropdownItems = [{ text: this.$t("customers.flows.all"), value: "", icon: "list-check" }] as Option[];
                workflows.forEach((workflow: WorkflowConfig) => {
                    const projectCount = this.customers.projects.filter((p: Project) => p.workflow === workflow.key).length;
                    workflowDropdownItems.push({ text: `${this.$ct(workflow.name)}(${projectCount})`, value: workflow.key, icon: "list-check" });
                });
                return workflowDropdownItems;
            }
            return [];
        },
        riskTypes(): CheckboxItem[] {
            const config = this.$config.settings.riskConfigs;
            const _riskType = config?.[0].thresholds.map((setting: RiskThreshold) => {
                return {
                    key: setting.key,
                    text: this.$ct(setting.text),
                    color: setting.color,
                };
            });

            return _riskType as CheckboxItem[];
        },

        entityTypes() : CheckboxItem[] {
            return [{ key: "person", text: this.$t("customers.entityTypes.person") },
                { key: "company", text: this.$t("customers.entityTypes.company") },
            ] as CheckboxItem[];
        },
        assesmentTypes(): DropdownItem[] {
            return [
                { text: this.$t("customers.assesmentTypes.all"), value: "", icon: "hourglass" },
                { text: this.$t("customers.assesmentTypes.pendingInternal"), value: "pending_internal", icon: "hourglass-start" },
                { text: this.$t("customers.assesmentTypes.pendingExternal"), value: "pending_external", icon: "hourglass-half" },
                { text: this.$t("customers.assesmentTypes.completed"), value: "completed", icon: "hourglass-end" },
                { text: this.$t("customers.assesmentTypes.unknown"), value: "unknown", icon: "question" },
            ] as Option[];
        },
        customerManagers() : Option[] {
            const managers: Option[] = [];
            managers.push({
                value: "",
                text: this.$t("customers.allAssignee"),
                icon: "user-group",
            });

            this.employees.forEach((employee: Employee) => managers.push({
                value: employee.uid,
                text: employee.givenName + " " + employee.familyName,
                icon: "user",
            }));
            return managers;
        },
    },
    watch: {
        searchQuery() {
            this.debouncedSearch();
        },
        toDate() {
            this.currentPage = 1;
            this.onDateChange();
        },
        selectedCustomerManager() {
            this.currentPage = 1;
            this.fetchProjects();
        },
        selectedRisk() {
            this.currentPage = 1;
            this.fetchProjects();
        },
        selectedEntity() {
            this.currentPage = 1;
            this.fetchProjects();
        },
        selectedAssesment() {
            this.currentPage = 1;
            this.fetchProjects();
        },

        selectedDate() {
            this.currentPage = 1;
            this.handleSelectedDateChange(this.selectedDate);
            this.fetchProjects();
        },
        selectedWorkflow() {
            this.currentPage = 1;
            this.fetchProjects();
        },

    },
    async mounted() {
        const query = this.$route.query;
        this.searchQuery = typeof query.searchQuery === "string" ? query.searchQuery : "";
        this.fromDate = typeof query.fromDate === "string" ? query.fromDate : null;
        this.toDate = typeof query.toDate === "string" ? query.toDate : null;
        this.currentPage = parseInt(query.currentPage as string) || 1;
        this.selectedAssesment = typeof query.assesmentStatus === "string" ? query.assesmentStatus : "";
        this.selectedCustomerManager = typeof query.assignee === "string" ? query.assignee : "";
        this.selectedRisk = Array.isArray(query.selectedRisk)
            ? (query.selectedRisk as string[])
            : typeof query.selectedRisk === "string"
                ? query.selectedRisk.split(",").map((item: string) => item.trim())
                : [];
        this.selectedWorkflow = typeof query.workflow === "string" ? query.workflow : "";
        this.updateUrlParams();
        await this.fetchProjects();
        await this.fetchEmployees();
    },
});
</script>

<style scoped>
.flex-grow {
    flex-grow: 1;
}
</style>
