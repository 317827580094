<template>
    <div class="flex">
        <div class="py-8 px-6 min-w-[485px] grow">
            <div class="flex gap-3">
                <Icon class="w-8 h-8 shrink-0" :icon="displayIcon" />
                <div class="flex flex-wrap items-center gap-x-3 gap-y-2">
                    <div class="font-roboto-slab leading-[1.25] text-[28px] text-body overflow-anywhere">
                        {{ project.name }}
                    </div>
                    <IdentitySelector
                        v-if="project.type === ProjectType.Company"
                        :project="project"
                        @request-project-refresh="$emit('request-project-refresh', $event)"
                    />
                </div>
                <div class="flex gap-3 ml-auto">
                    <div
                        class="text-gray-500 cursor-pointer"
                        :tooltip="$t('project.access.iconTooltip')"
                        @click="accessPopupOpen = true"
                    >
                        <FontIcon icon="share-nodes" />
                    </div>
                    <CustomerAccessPopup
                        v-if="accessPopupOpen"
                        :project="project"
                        @close="accessPopupOpen = false"
                        @request-project-refresh="$emit('request-project-refresh', $event)"
                    />
                    <div
                        class="text-gray-500 cursor-pointer"
                        :tooltip="$t('project.deleteProject')"
                        @click="$emit('delete')"
                    >
                        <FontIcon :icon="'trash-can'" />
                    </div>
                </div>
            </div>
            <div class="mt-3 mb-2 border-b border-gray-300">
                <slot name="tabs" />
            </div>
            <div>
                <slot name="content" />
            </div>
        </div>
        <div class="pt-4 pr-4" v-if="showMenu">
            <div class="bg-white rounded-lg py-4 px-5 min-h-[500px] w-[400px] xl:w-[460px]">
                <slot name="menu" />
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Project, ProjectType } from "@/lib/projects/projects";
import FontIcon from "@/ui/FontIcon.vue";
import Icon from "../../components/Icon.vue";
import IdentitySelector from "@/components/customer/IdentitySelector.vue";
import CustomerAccessPopup from "@/components/customer/CustomerAccessPopup.vue";

export default defineComponent({
    name: "CustomerLayout",
    components: {
        FontIcon,
        IdentitySelector,
        Icon,
        CustomerAccessPopup,
    },
    emits: ["delete", "request-project-refresh"],
    props: {
        project: { type: Object as PropType<Project>, required: true },
        showMenu: { type: Boolean as PropType<boolean>, required: true },
    },
    data() {
        return {
            ProjectType,
            accessPopupOpen: false,
        };
    },
    computed: {
        displayIcon() {
            switch (this.project.type) {
            case "person":
                return "person-blue";
            case "company":
                return "company";
            default:
                return "company";
            }
        },
    },
});
</script>
<style scoped>
.overflow-anywhere{
    overflow-wrap: anywhere;
}
</style>
