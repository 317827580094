<template>
    <section>
        <label class="font-bold">{{ $t('settings.persons.personMergingTitle') }}</label>
        <div v-if="settings?.settings?.personMerging" class="flex gap-2">
            <SwitchInput v-model="settings.settings.personMerging.enabled" />
            <label>{{ $t('settings.persons.personMergingEnable') }}</label>
            <FontIcon
                icon="circle-question"
                class="text-gray-500 cursor-help"
                :size="18"
                :tooltip="$t('settings.persons.personMergingEnableTooltip')"
            />
        </div>
    </section>

    <div class="my-10"></div>

    <div>
        <ActionButton
            @btn-clicked="save"
            :disabled="!changed"
            :loading="saving"
            class="block text-xs font-bold"
        >
            {{ $t('settings.persons.save') }}
        </ActionButton>
    </div>

    <div v-if="saveSuccess" class="text-green-600 p-2 px-3 bg-green-100 mt-6 rounded-md inline-flex items-center gap-3">
        <Icon class="w-5 h-5 shrink-0" icon="ep:success-filled" />
        <span>{{ $t('settings.persons.saveSuccess') }}</span>
    </div>

    <div v-if="saveError" class="text-danger-dark p-2 px-3 bg-danger-light mt-6 rounded-md inline-flex items-center gap-3">
        <Icon class="w-5 h-5 shrink-0" icon="aml:notification" />
        <span>{{ $t('settings.persons.saveError') }}</span>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import FontIcon from "@/ui/FontIcon.vue";
import InputCheckBox from "@/ui/inputs/InputCheckBox.vue";
import { CheckboxItem } from "@/ui/inputs/input_helper";
import ActionButton from "@/components/ActionButton.vue";
import { updateSettings } from "@/lib/settings/update_settings";
import { Setting } from "@/lib/settings/settings";
import { querySettings } from "@/lib/settings/get_settings";
import SwitchInput from "@/components/SwitchInput.vue";
import Icon from "@/components/Icon.vue";

export default defineComponent({
    name: "PersonSettings",
    components: {
        FontIcon,
        InputCheckBox,
        ActionButton,
        SwitchInput,
        Icon,
    },
    data() {
        return {
            originalSettings: {} as Setting,
            settings: {} as Setting,
            saving: false,
            saveSuccess: false,
            saveError: false,
        };
    },
    computed: {
        changed(): boolean {
            return JSON.stringify(this.originalSettings) !== JSON.stringify(this.settings);
        },
    },
    methods: {
        setSettings() {
            this.originalSettings = this.$config.rawSettings;
            this.settings = { ...this.$config.rawSettings };
        },
        async save() {
            this.saveError = false;
            this.saveSuccess = false;
            this.saving = true;
            try {
                await updateSettings(this.settings.settings);
                const settings = await querySettings();
                this.$config.update(settings);
                this.setSettings();
                this.saveSuccess = true;
            } catch (err) {
                this.saveError = true;
            } finally {
                this.saving = false;
            }
        },
    },
    mounted() {
        this.setSettings();
    },
});
</script>
