<template>
    <div>
        <input
            :placeholder="$t('common.actions.search')"
            type="text"
            name="search"
            id="searchInput"
            v-model="searchQuery"
            @input="debouncedSearch"
            class="block w-full h-full rounded-[100px] border border-light-gray border-solid py-1.5 pl-10 placeholder:font-normal placeholder:text-light-gray sm:text-sm sm:leading-4 mb-4"
        />
        <RiskNestedList
            :items="nestedData.items"
            :options="nestedData.options"
            :selected-code="value"
            :selectable-level="selectableLevel"
            @item-selected="onItemSelected"
        />

    </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";

import { AssessmentValue } from "@/lib/assessments/assessments";
import { RiskValueType } from "@/lib/risk/risk_matrix";
import RiskNestedList from "@/views/risk/controls/RiskNestedList.vue";
import { RiskAssets } from "@/views/risk/risk_assets";
import { getNestedData, RiskNestedItem, RiskNestedListData } from "@/views/risk/risk_nested_list";
import debounce from "debounce";
import i18n, { i18localeToVerified } from "@/i18n";

export default defineComponent({
    name: "IndicatorNestedList",
    components: {
        RiskNestedList,
    },
    emits: ["updated"],
    props: {
        assets: { type: Object as PropType<RiskAssets>, required: true },
        inputValueType: { type: String as PropType<RiskValueType>, required: true },
        existingValue: { type: Object as PropType<AssessmentValue | null>, default: null },
    },
    data() {
        return {
            value: "",
            isValid: false,
            selectableLevel: 2,
            searchQuery: "",
            nestedData: { items: [], options: { limit: 0 } } as RiskNestedListData,
            unfilteredNestedData: { items: [], options: { limit: 0 } } as RiskNestedListData,
        };
    },
    methods: {

        onItemSelected(item: RiskNestedItem) {
            if (this.value === item.code) {
                this.value = "";
            } else {
                this.value = item.code;
            }
            this.isValid = (this.value !== "");
            this.handleUpdate();
        },

        handleUpdate() {
            this.$emit("updated", this.value, this.isValid);
        },

        debouncedSearch: debounce(function (this: any) {
            this.nestedData.items = this.filterNestedArray(this.unfilteredNestedData.items);
        }, 400),

        // recursive function that can search endlessly inside a nested array
        filterNestedArray(unfilteredNestedArray: RiskNestedItem[]) {
            return unfilteredNestedArray.reduce((filteredResults: RiskNestedItem[], item: RiskNestedItem) => {
                if (this.checkNestedItem(item)) {
                    filteredResults.push({ ...item });
                } else if (item.items) {
                    const filteredItems = this.filterNestedArray(item.items);
                    if (filteredItems.length > 0) {
                        filteredResults.push({ ...item, items: filteredItems });
                    }
                }
                return filteredResults;
            }, []);
        },

        checkNestedItem(item: RiskNestedItem) {
            const currentLanguage = i18localeToVerified(i18n.global.locale.value);
            if (
                item.text[currentLanguage]?.toLowerCase().includes(String(this.searchQuery).toLowerCase()) ||
                String(item?.code).toLowerCase().includes(String(this.searchQuery).toLowerCase())
            ) {
                return true;
            }
            return false;
        },
    },
    mounted() {
        if (this.inputValueType === RiskValueType.NaceCode) {
            this.selectableLevel = 3;
        }
        this.nestedData = getNestedData(this.inputValueType, this.assets);
        // add deep copy for search
        this.unfilteredNestedData = JSON.parse(JSON.stringify(this.nestedData));
        if (this.existingValue !== null) this.value = this.existingValue.value;
    },
});
</script>
<style scoped></style>
