import { ApiResponse, parseLocalError, parseRemoteError } from "../error_parser";
import { Verified } from "../verified_plugin";
import { Form, FormScope } from "./forms";

export enum FormStartMode {
    Create = "create",
    Send = "send",
}

export type CreateFormOptions = {
    startMode: FormStartMode;
    contextId: string;
    scopes: FormScope[];
    templateSlug: string;
    templateKey: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    model: any;
};

export const createProjectForm = async (options: CreateFormOptions): Promise<{ formLink: string, form: Form | null, responseInfo: ApiResponse }> => {
    try {
        console.log("options", options);
    //    options.templateSlug = "rkaoqnt8a";
        const response = await fetch(`/api/aml/v1/projects/${options.contextId}/forms`, {
            method: "POST",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
            body: JSON.stringify({
                ...options,
            }),
        });

        const body = await response.json();

        if (!response.ok) {
            return {
                formLink: "",
                form: null,
                responseInfo: { isError: true, parsedError: parseRemoteError(body) },
            };
        }

        return {
            formLink: body.formLink,
            form: body.form as Form,
            responseInfo: { isError: false, parsedError: null },
        };
    } catch (error) {
        return {
            formLink: "",
            form: null,
            responseInfo: { isError: true, parsedError: parseLocalError(error) },
        };
    }
};

export const createPersonForm = async (options: CreateFormOptions): Promise<{ formLink: string, form: Form | null, responseInfo: ApiResponse }> => {
    try {
        console.log("options", options);
        const response = await fetch(`/api/aml/v1/persons/${options.contextId}/forms`, {
            method: "POST",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
            body: JSON.stringify({
                ...options,
            }),
        });

        const body = await response.json();

        if (!response.ok) {
            return {
                formLink: "",
                form: null,
                responseInfo: { isError: true, parsedError: parseRemoteError(body) },
            };
        }

        return {
            formLink: body.formLink,
            form: body.form as Form,
            responseInfo: { isError: false, parsedError: null },
        };
    } catch (error) {
        return {
            formLink: "",
            form: null,
            responseInfo: { isError: true, parsedError: parseLocalError(error) },
        };
    }
};
