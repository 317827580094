<template>
    <div>
        <div class="flex">
            <!-- List of groups -->
            <div class="w-[150px]">
                <div class="flex items-center">
                    <h2 class="flex-1 text-lg font-bold">
                        User Groups
                    </h2>
                    <Icon
                        @click="createPolicyGroup"
                        :class="
                            'w-6 cursor-pointer text-primary-default hover:text-primary-dark' +
                                (createGroupBtnLoading ? ' animate-spin' : '')
                        "
                        :icon="createGroupBtnLoading ? 'tabler:loader-2' : 'aml:round'"
                    />
                </div>

                <div class="mt-5">
                    <div v-if="groupsLoading">
                        <div class="flex-1">
                            <div class="h-48 bg-gray-300 rounded-md animate-pulse"></div>
                        </div>
                    </div>
                    <div
                        v-if="!groupsLoading"
                        v-for="(group, index) in groups"
                        :key="group.id"
                    >
                        <div
                            @click="selectGroup(index)"
                            :class="{
                                'group-label': true,
                                'active-group-label': group.id == selectedGroup.id,
                            }"
                        >
                            <span class="flex-1 text-sm text-ellipsis overflow-hidden text-nowrap">{{ group.name }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Selected group -->
            <div class="flex-1 ml-6 pl-6 border-l border-gray-300 mb-5">
                <div v-if="groupsLoading">
                    <div class="mt-2 w-[150px] h-8 mb-3 bg-gray-300 rounded-md animate-pulse"></div>
                    <div class="h-[290px] bg-gray-300 rounded-md animate-pulse"></div>
                    <div class="mt-7 h-[480px] bg-gray-300 rounded-md animate-pulse"></div>
                    <div class="mt-7 h-[480px] bg-gray-300 rounded-md animate-pulse"></div>
                </div>

                <template v-if="!groupsLoading && !groups.length">
                    <div class="p-1">
                        <span>No user groups created, click the
                            <Icon
                                @click="createPolicyGroup"
                                :class="
                                    'inline-block w-4 cursor-pointer text-primary-default hover:text-primary-dark -mb-[3px]' +
                                        (createGroupBtnLoading ? ' animate-spin' : '')
                                "
                                :icon="createGroupBtnLoading ? 'tabler:loader-2' : 'aml:round'"
                            />
                            icon to create a new one</span>
                    </div>
                </template>

                <template v-if="!groupsLoading && groups.length">
                    <div class="text-lg font-bold border-b-2 border-gray-700 inline-block">
                        <div class="flex items-center">
                            <span class="pr-4">{{ selectedGroup.name }}</span>
                        </div>
                    </div>

                    <div class="my-5"></div>

                    <!-- Display -->
                    <div class="rounded-md bg-white shadow-md p-4">
                        <form @submit.prevent>
                            <div class="flex flex-col">
                                <label
                                    class="mb-2 text-xs uppercase font-bold"
                                    for="group-name"
                                >Group name <span class="text-red-600">*</span></label>
                                <InputText
                                    v-model="groupToUpdateCopy.name"
                                    id="group-name"
                                    unique-id="group-name"
                                    class="pr-3"
                                    required
                                />
                            </div>

                            <div class="my-7"></div>

                            <div class="flex flex-col">
                                <label
                                    class="mb-2 text-xs uppercase font-bold"
                                    for="group-description"
                                >Group description <span class="text-red-600"></span></label>
                                <div class="flex items-center gap-2">
                                    <textarea
                                        v-model="groupToUpdateCopy.description"
                                        id="group-description"
                                        class="p-3 border border-gray-300 rounded-md mr-4"
                                        type="text"
                                    ></textarea>
                                </div>
                            </div>

                            <div class="flex mt-5">
                                <ActionButton
                                    @btn-clicked="updatePolicyGroupInfo(selectedGroupIndex)"
                                    :disabled="!groupInfoHasChanged"
                                    :loading="saveGroupInfoBtnLoading"
                                    class="block text-xs font-bold"
                                >
                                    Save changes
                                </ActionButton>
                            </div>
                        </form>
                    </div>

                    <!-- Members -->
                    <div class="rounded-md bg-white shadow-md p-4 mt-7">
                        <div v-if="selectedGroup.members.length" class="flex text-xs uppercase font-bold mb-2">
                            <div class="w-[200px]">
                                Members
                            </div>
                            <div class="flex-1">
                                Email
                            </div>
                            <div class="text-right">
                                <Icon
                                    v-show="groupMemberUpdatesInProgress > 0"
                                    class="w-5 text-primary-default animate-spin -my-1"
                                    icon="tabler:loader-2"
                                />
                            </div>
                        </div>
                        <div v-if="!selectedGroup.members.length" class="text-sm">
                            No members added yet
                        </div>
                        <div v-for="member in selectedGroup.members" :key="member">
                            <div class="flex items-center py-2 border-t text-sm">
                                <div class="w-[200px]">
                                    {{
                                        memberToEmployee(member)
                                            ? memberToEmployee(member)?.givenName + " " + memberToEmployee(member)?.familyName
                                            : member
                                    }}
                                </div>
                                <div class="flex-1">
                                    {{ memberToEmployee(member)?.email }}
                                </div>
                                <div class="text-right">
                                    <span
                                        @click="removeMemberFromGroup(member)"
                                        class="text-red-600 hover:text-red-700 cursor-pointer text-xs underline decoration-dotted"
                                    >Remove from group</span>
                                </div>
                            </div>
                        </div>

                        <div class="mt-3 bg-gray-100 p-3 rounded-md">
                            <h2 class="font-bold text-[11px] uppercase">
                                Add a user
                            </h2>
                            <div v-if="!employeesNotInGroup.length" class="text-sm mt-3">
                                No users to add
                            </div>
                            <div v-if="employeesNotInGroup.length" class="overflow-y-scroll pr-3 mt-2 max-h-[200px] min-h-[100px]">
                                <div v-for="employee in employeesNotInGroup">
                                    <div class="flex items-center py-2 border-t text-sm">
                                        <div class="w-[190px]">
                                            {{ employee.givenName + " " + employee.familyName }}
                                        </div>
                                        <div class="flex-1">
                                            {{ employee.email }}
                                        </div>
                                        <div class="text-right">
                                            <span
                                                @click="addMemberToGroup(employee.link?.uid)"
                                                class="text-blue-600 hover:text-blue-700 cursor-pointer text-xs underline decoration-dotted"
                                            >Add to group</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Policies -->
                    <div class="rounded-md bg-white shadow-md p-4 pb-2 mt-7">
                        <div class="flex text-xs uppercase font-bold mb-2">
                            <div class="flex-1">
                                Permissions
                            </div>
                            <div class="text-right">
                                <Icon
                                    v-show="groupPolicyUpdatesInProgress > 0"
                                    class="w-5 text-primary-default animate-spin -my-1"
                                    icon="tabler:loader-2"
                                />
                            </div>
                        </div>
                        <div v-for="(enabled, policy) in groupToUpdateCopy.policiesMap" class="flex items-center border-t py-2">
                            <div class="flex-1">
                                <div class="font-bold text-sm">
                                    {{ policy }}
                                </div>
                                <div class="text-xs text-gray-700 mt-1">
                                    {{ policy + "_description" }}
                                </div>
                            </div>
                            <div class="text-right flex items-center">
                                <SwitchInput
                                    @change="updateGroupPolicy(policy.toString(), groupToUpdateCopy.policiesMap[policy])"
                                    v-model="groupToUpdateCopy.policiesMap[policy]"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="mt-5 flex justify-end">
                        <ActionButton
                            @btn-clicked="deleteGroup"
                            :loading="groupDeleteInProgress"
                            theme="danger"
                            class="block text-xs font-bold"
                        >
                            Delete group
                        </ActionButton>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { getAvailablePolicies } from "@/lib/policy/policies";
import { defineComponent } from "vue";
import {
    PolicyGroup,
    addMembersToPolicyGroup,
    addPolicyToGroup,
    createPolicyGroup,
    deletePolicyGroup,
    getPolicyGroups,
    removeMembersFromPolicyGroup,
    removePolicyFromGroup,
    updatePolicyGroup,
} from "../../lib/policy/policy_group";
import ActionButton from "../ActionButton.vue";
import Icon from "../Icon.vue";
import SwitchInput from "../SwitchInput.vue";
import InputText from "@/ui/inputs/InputText.vue";

interface Employee {
    id: string;
    givenName: string;
    familyName: string;
    email: string;
    department: string;
    link: {
        uid: string;
    };
}

interface CompanyData {
    employees: Employee[];
}

export default defineComponent({
    name: "PolicyGroups",
    components: {
        Icon,
        ActionButton,
        SwitchInput,
        InputText,
    },
    computed: {
        selectedGroup(): PolicyGroup {
            return this.groups[this.selectedGroupIndex];
        },

        groupInfoHasChanged(): boolean {
            // name has changed
            if (this.groupToUpdateCopy.name !== this.selectedGroup.name) {
                return true;
            }

            // description has changed
            if (this.groupToUpdateCopy.description !== this.selectedGroup.description) {
                return true;
            }

            return false;
        },

        groupPoliciesHasChanged(): boolean {
            // policy has been added
            for (const policy of this.groupToUpdateCopy.policies) {
                if (!this.selectedGroup.policies.includes(policy)) {
                    return true;
                }
            }

            // policy has been removed
            for (const policy of this.selectedGroup.policies) {
                if (!this.groupToUpdateCopy.policies.includes(policy)) {
                    return true;
                }
            }

            return false;
        },

        employeesNotInGroup(): Employee[] {
            const notInGroup: Employee[] = [];

            for (const employee of this.companyEmployees) {
                if (this.selectedGroup.members.includes(employee.link?.uid)) {
                    continue;
                }

                notInGroup.push(employee);
            }

            return notInGroup;
        },
    },
    data() {
        return {
            // groups
            groupsLoading: true,
            createGroupBtnLoading: false,
            groups: [] as PolicyGroup[],

            // currently selected group
            selectedGroupIndex: 0,
            groupToUpdateCopy: {
                id: "",
                namespace: "",
                name: "",
                description: "",
                policies: [],
                members: [],
                policiesMap: {} as { [key: string]: boolean },
            } as PolicyGroup & { policiesMap: { [key: string]: boolean } },
            saveGroupInfoBtnLoading: false,
            groupPolicyUpdatesInProgress: 0,
            groupMemberUpdatesInProgress: 0,
            groupDeleteInProgress: false,

            // company employees
            companyEmployees: [] as Employee[],
            companyEmployeesLoading: false,

            // policies
            availablePolicies: [] as string[],
            availablePoliciesLoading: false,
        };
    },
    methods: {
        selectGroup(index: number) {
            this.saveGroupInfoBtnLoading = false;
            this.groupPolicyUpdatesInProgress = 0;
            this.groupMemberUpdatesInProgress = 0;
            this.groupDeleteInProgress = false;

            if (!this.groups.length || this.groups.length - 1 < index) {
                console.error("Invalid group index");
                return;
            }

            const policiesMap: { [key: string]: boolean } = {};
            for (const policy of this.availablePolicies) {
                policiesMap[policy] = this.groups[index].policies.includes(policy);
            }

            this.groupToUpdateCopy = { ...this.groups[index], policiesMap };
            this.selectedGroupIndex = index;
        },

        async fetchGroups() {
            this.groupsLoading = true;
            this.groups = [];

            try {
                const res = await getPolicyGroups();
                this.groups = res.policyGroups;
            } catch (err) {
                console.error(err);
            } finally {
                this.groupsLoading = false;
            }

            if (this.groups.length) {
                this.selectGroup(this.selectedGroupIndex);
            }
        },

        async createPolicyGroup() {
            this.createGroupBtnLoading = true;
            try {
                const policyGroup = await createPolicyGroup({ name: "New Group" });
                this.groups.push(policyGroup);
                this.selectGroup(this.groups.length - 1);
            } catch (err) {
                console.error(err);
            } finally {
                this.createGroupBtnLoading = false;
            }
        },

        async updatePolicyGroupInfo(selectedGroupIndex: any) {
            if (this.groupToUpdateCopy.name === "") {
                return;
            }
            this.saveGroupInfoBtnLoading = true;
            try {
                const policyGroup = await updatePolicyGroup(this.groupToUpdateCopy.id, {
                    name: this.groupToUpdateCopy.name,
                    description: this.groupToUpdateCopy.description,
                });

                this.groups[selectedGroupIndex] = policyGroup;
            } catch (err) {
                console.error(err);
            } finally {
                this.saveGroupInfoBtnLoading = false;
            }
        },

        async fetchCompanyEmployees() {
            this.companyEmployeesLoading = true;
            try {
                const response = await fetch(`/api/companies/${this.$verified.companyId}`, {
                    method: "GET",
                    headers: {
                        ...this.$verified.authHeaders,
                        "content-type": "application/json",
                    },
                });

                const body = await response.json();

                if (!response.ok) {
                    throw new Error(body);
                }

                const companyData = body as CompanyData;
                this.companyEmployees = companyData.employees;
            } catch (err) {
                console.error(err);
            } finally {
                this.companyEmployeesLoading = false;
            }
        },

        memberToEmployee(member: string): Employee | undefined {
            return this.companyEmployees.find((x) => x.link?.uid === member);
        },

        async addMemberToGroup(member: string) {
            // Update frontend immediately
            const employee = this.companyEmployees.find((x) => x.link?.uid === member);
            if (!employee) {
                return;
            }
            this.selectedGroup.members.push(employee.link?.uid);

            const groupId = this.selectedGroup.id;
            try {
                this.groupMemberUpdatesInProgress++;
                await addMembersToPolicyGroup(groupId, [employee.link?.uid]);
            } catch (err) {
                console.error(err);

                // If something went wrong with the request, rubberband the frontend back to the correct state
                try {
                    this.selectedGroupIndex = 0;
                    await this.fetchGroups();
                    for (const [i, group] of this.groups.entries()) {
                        if (group.id === groupId) {
                            this.selectedGroupIndex = i;
                        }
                    }
                } catch (err2) {
                    console.error(err2);
                }
            } finally {
                this.groupMemberUpdatesInProgress--;
            }
        },

        async removeMemberFromGroup(member: string) {
            // Update frontend immediately
            this.selectedGroup.members.splice(this.selectedGroup.members.indexOf(member), 1);

            const groupId = this.selectedGroup.id;
            try {
                this.groupMemberUpdatesInProgress++;
                await removeMembersFromPolicyGroup(groupId, [member]);
            } catch (err) {
                console.error(err);

                // If something went wrong with the request, rubberband the frontend back to the correct state
                try {
                    this.selectedGroupIndex = 0;
                    await this.fetchGroups();
                    for (const [i, group] of this.groups.entries()) {
                        if (group.id === groupId) {
                            this.selectedGroupIndex = i;
                        }
                    }
                } catch (err2) {
                    console.error(err2);
                }
            } finally {
                this.groupMemberUpdatesInProgress--;
            }
        },

        async fetchAvailablePolicies() {
            this.availablePoliciesLoading = true;
            try {
                const policies = await getAvailablePolicies();
                this.availablePolicies = policies;
            } catch (err) {
                console.error(err);
            } finally {
                this.availablePoliciesLoading = false;
            }
        },

        async updateGroupPolicy(policy: string, state: boolean) {
            const groupId = this.selectedGroup.id;

            // We do it this way so the loading indicator is visible at all times when any update
            // is in progress. If we simply disable the spinner when the promise is complete it may
            // flicker if more than one update is in progress at a time
            this.groupPolicyUpdatesInProgress++;
            try {
                if (state) {
                    this.selectedGroup.policies.push(policy);
                    await addPolicyToGroup(groupId, [policy]);
                } else {
                    this.selectedGroup.policies.splice(this.selectedGroup.policies.indexOf(policy), 1);
                    await removePolicyFromGroup(groupId, [policy]);
                }
            } catch (err) {
                console.error(err);
            } finally {
                this.groupPolicyUpdatesInProgress--;
            }
        },

        async deleteGroup() {
            this.groupDeleteInProgress = true;
            try {
                await deletePolicyGroup(this.selectedGroup.id);
                this.groups.splice(this.selectedGroupIndex, 1);
                this.selectGroup(0);
            } catch (err) {
                console.error(err);
            }
        },
    },
    async mounted() {
        // do this before fetchGroups as it will try to present the first
        // group, which will need the available policies
        await this.fetchAvailablePolicies();

        // can be done simultaneously
        this.fetchGroups();
        this.fetchCompanyEmployees();
    },
});
</script>

<style scoped lang="scss">
.group-label {
    @apply py-2 px-3 flex items-center gap-2 rounded-md mb-2 cursor-pointer bg-gray-200 hover:bg-gray-300;
}

.active-group-label {
    @apply bg-primary-default hover:bg-primary-default cursor-default text-white;
}
</style>
