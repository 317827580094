import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-753d90aa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card-container flex flex-col bg-white rounded px-5" }
const _hoisted_2 = { class: "divide-y-2 divide-gray-300" }
const _hoisted_3 = { class: "card-container-grid grid gap-2" }
const _hoisted_4 = { class: "flex gap-2" }
const _hoisted_5 = {
  key: 0,
  class: "srv3-step-icon-text"
}
const _hoisted_6 = {
  key: 1,
  class: "srv3-step-icon-icon"
}
const _hoisted_7 = {
  key: 2,
  class: "srv3-step-icon-icon"
}
const _hoisted_8 = { class: "flex flex-col gap-1 pr-4" }
const _hoisted_9 = { class: "text-base font-bold" }
const _hoisted_10 = { class: "flex flex-wrap gap-1" }
const _hoisted_11 = {
  key: 0,
  class: "srv3-step-pill-dot"
}
const _hoisted_12 = { class: "srv3-step-pill-text" }
const _hoisted_13 = { class: "pl-[48px]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontIcon = _resolveComponent("FontIcon")!
  const _component_StepActions = _resolveComponent("StepActions")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.steps, (step, i) => {
        return (_openBlock(), _createElementBlock("li", {
          class: "py-5 relative",
          key: 's' + i
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", {
                class: _normalizeClass(["shrink-0 w-10 h-10 rounded-full bg-[#F4F4F4]", [
                                { 'srv3-step-icon-completed': step.statusType === _ctx.StatusType.Completed },
                                { 'srv3-step-icon-error': step.statusType === _ctx.StatusType.Error },
                                { 'srv3-step-icon-warning': step.statusType === _ctx.StatusType.Warning },
                            ]])
              }, [
                (step.statusType === _ctx.StatusType.Default || step.statusType === _ctx.StatusType.InProgress)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(i + 1), 1))
                  : _createCommentVNode("", true),
                (step.statusType === _ctx.StatusType.Completed)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _createVNode(_component_FontIcon, {
                        icon: 'check',
                        size: 20
                      })
                    ]))
                  : _createCommentVNode("", true),
                (step.statusType === _ctx.StatusType.Error || step.statusType === _ctx.StatusType.Warning)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      _createVNode(_component_FontIcon, {
                        icon: 'circle-exclamation',
                        size: 20
                      })
                    ]))
                  : _createCommentVNode("", true)
              ], 2),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("h4", _hoisted_9, _toDisplayString(_ctx.$ct(step.name)), 1),
                _createElementVNode("div", _hoisted_10, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(step.pills, (status, j) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: _normalizeClass(["flex h-[28px] py-[3px] px-[10px] rounded-[14px] bg-[#F4F4F4]", [
                                        { 'srv3-step-pill-progress': status.type === _ctx.StatusType.InProgress },
                                        { 'srv3-step-pill-completed': status.type === _ctx.StatusType.Completed },
                                        { 'srv3-step-pill-error': status.type === _ctx.StatusType.Error },
                                        { 'srv3-step-pill-warning': status.type === _ctx.StatusType.Warning },
                                    ]]),
                      key: 'p' + j
                    }, [
                      (status.type !== _ctx.StatusType.Default)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_11))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$ct(status.text)), 1)
                    ], 2))
                  }), 128))
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_13, [
              (_openBlock(), _createBlock(_component_StepActions, {
                key: 'sa' + i,
                step: step,
                onAction: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('action', $event)))
              }, null, 8, ["step"]))
            ])
          ])
        ]))
      }), 128))
    ])
  ]))
}