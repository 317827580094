import { FlowAction } from "@/types/flow_types";
import { Form, FormActor, FormFlowStatus } from "./forms";
import i18n from "@/i18n";
const { t } = i18n.global;

export const getFormFromAction = (forms: Form[], action: FlowAction): Form | null => {
    console.log(forms, "Forms");
    const formKey = action.params.formKey || "";
    for (const form of forms) {
        if (form.templateKey === formKey && form.isSelected) {
            return form;
        }
    }
    return null;
};

export const getFormMainPdfFileId = (form: Form): string => {
    console.log("form", form);

    return "";
};

export const getActorStatusText = (form: Form, actor: FormActor): string => {
    if (actor.rejected) return t("project.forms.status.rejected");
    if (actor.role === "signer" && form.formStatus === FormFlowStatus.Aborted) {
        return t("project.forms.status.aborted");
    }
    if (actor.completed) return t("project.forms.status.completed");
    return t("project.forms.status.pending");
};

export const getActorRoleText = (actor: FormActor): string => {
    if (actor.role === "editor") return t("project.forms.roles.editor");
    if (actor.role === "signer") return t("project.forms.roles.signer");
    return actor.role;
};
