import i18n from "@/i18n";

const { t, d } = i18n.global;
export const formatDateObject = (dateInput: any) => {
    if (!dateInput) return t("project.tabs.company.blocks.general.valueUndefined");

    const { year, month, day } = dateInput;

    if (year && month && day) {
        return d(new Date(year, month - 1, day), "long");
    } else if (year && month) {
        return d(new Date(year, month - 1), "long");
    } else if (year) {
        return year.toString();
    }

    return t("project.tabs.company.blocks.general.valueUndefined");
};

export const validateDateOfBirth = (input: string): DateOfBirthResult => {
    const dateRegex = /^((?:19|20)\d\d)-(0?[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;
    const match = input.match(dateRegex);

    if (!match) {
        return new DateOfBirthResult(false);
    }

    if (!isDateNotInFuture(new Date(input))) {
        return new DateOfBirthResult(false);
    }

    return new DateOfBirthResult(true, match);
};

function isDateNotInFuture(date: Date): boolean {
    const currentDate = new Date();
    return date <= currentDate;
}

export class DateOfBirthResult {
    public isValid: boolean;
    public match: RegExpMatchArray | null;

    constructor(isValid: boolean, match: RegExpMatchArray | null = null) {
        this.isValid = isValid;
        this.match = match;
    }
}
