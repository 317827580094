<template>
    <PopupDialog
        :is-visible="togglescreeningmodal"
        header="Screening Monitor"
        :show-cancel-button="true"
        :show-action-button="true"
        :action-text="$t('screening.addMonitor')"
        :action-icon="'link'"
        :icon="'link'"
        :progress="dialog"
        @action="initiateScreening"
        @close-modal="emitCloseModal"
    >
        <div class="box-border w-full">
            <div role="modalBody" class="rounded-lg pb-4 border border-light border-solid">
                <div role="info" class="space-y-3 text-sm">
                    <div class="flex gap-1 bg-background p-4">
                        <!-- <Icon class="w-8 h-8" icon="info" /> -->
                        <div>
                            <span class="font-medium">{{ $t('screening.startMonitoring.text1') }}
                                <span
                                    @click="$router.push({ path: `/aml/persons/${person.id}` })"
                                    class="text-primary-default"
                                >{{ person.data.person_info.givenName }} {{ person.data.person_info.familyName }} </span>
                                {{ $t('screening.startMonitoring.text2') }}</span>
                            <ul class="list-disc">
                                <li>
                                    {{ $t('screening.startMonitoring.info.item1') }}
                                </li>
                                <li>{{ $t('screening.startMonitoring.info.item2') }}</li>
                            </ul>
                        </div>
                    </div>
                    <div class="space-y-2  pt-4 pl-4">
                        <label class="text-sm">{{ $t('screening.startMonitoring.info.item3') }}:</label>
                        <div class="flex justify-between">
                            <div class="space-y-1">
                                <div class="flex gap-3">
                                    <Icon class="w-5 h-5" icon="search" /><span class="text-sm bold">PEP EDGE</span>
                                </div>
                                <div class="flex gap-3">
                                    <Icon class="w-5 h-5" icon="search" /><span class="text-sm bold">EU CONSOLIDATED</span>
                                </div>
                                <div class="flex gap-3">
                                    <Icon class="w-5 h-5" icon="search" /><span class="text-sm bold">UN CONSOLIDATED</span>
                                </div>
                            </div>
                            <div>
                                <!-- <span> screening Icon </span> -->
                            </div>
                        </div>
                    </div>
                </div>
                <!--
                <div role="form" class="p-4">
                    <label class="font-semibold text-sm">Select data to use for Screening Monitor:</label>
                    <div class="mt-2 space-x-6 flex">
                        <div class="flex flex-col items-center border-2 grow basis-0 border-primary-default border-solid rounded-lg p-4">
                            <input name="notification-method" type="radio" :checked="true" class="h-6 w-6 border-light self-start" />
                            <div class="block mt-1">
                                <label class="font-medium">SSN (recommended)</label>
                                <label class="text-sm">
                                    Monitor screening lists using the person’s Social Security Number (very accurate)
                                </label>
                            </div>
                        </div>
                        <div class="flex flex-col items-center border-2 grow basis-0 border-primary-default border-solid rounded-lg p-4">
                            <input name="notification-method" type="radio" :checked="false" class="h-6 w-6 border-light self-start" />
                            <div class="block mt-1">
                                <label class="font-medium">Name & D.O.B.</label>
                                <label class="text-sm"> Monitor screening lists using person’s name & date of birth (less accurate) </label>
                            </div>
                        </div>
                    </div>
                    <div class="mt-2">
                        <label for="ssn" class="block text-sm font-medium leading-6 text-gray-900"
                            >SSN* (YYMMDD-XXXX - taken from ID Control)</label
                        >
                        <div class="mt-2">
                            <input
                                type="text"
                                name="ssn"
                                id="ssn"
                                class="block w-full border border-light rounded-md py-3 px-2 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>
                </div>
                -->
            </div>
        </div>
    </PopupDialog>
</template>
<script lang="ts">
import PopupDialog from "@/ui/PopupDialog.vue";
import { defineComponent, PropType } from "vue";
import { updateMonitoringStatus } from "@/lib/screening/screen_person";
import { Person, PersonWithRole } from "@/lib/persons/person";
import Icon from "@/components/Icon.vue";
export default defineComponent({
    name: "ScreenPersonPopup",
    components: {
        Icon,
        PopupDialog,
    },
    data() {
        return {
            dialog: { visible: true, valid: true, isWorking: false, statusText: "", isError: false, errorText: "" },
        };
    },

    props: {
        togglescreeningmodal: {
            type: Boolean,
            required: true,
        },
        person: {
            type: Object as PropType<PersonWithRole | Person>,
            required: true,
        },
    },
    emits: ["closeModal", "refreshPersons"],
    methods: {
        emitCloseModal() {
            this.$emit("closeModal");
        },

        async initiateScreening() {
            try {
                this.dialog.isWorking = true;
                this.dialog.isError = false;
                console.log(JSON.stringify(this.person.data.person_info));
                await updateMonitoringStatus(true, this.person.id);
                this.emitCloseModal();
                this.dialog.isWorking = false;
            } catch {
                this.dialog.isError = true;
                this.dialog.errorText = "Unknown Error";
            }
        },
    },
});
</script>
