<template>
    <div class="grid grid-cols-[auto_500px] gap-4 mt-6" v-if="!isLoading">
        <div class="bg-white p-5 rounded-lg min-h-[1200px]">
            <IndicatorList
                :assessment="currentAssessment"
                :selected-key="selectedIndicator ? selectedIndicator.key : ''"
                @selected="onSelectIndicator"
            />
        </div>
        <div
            v-if="matrixDefinition"
            class=" bg-white p-5 rounded-lg self-start sticky top-5"
            :style="scrolled ? 'height: calc(100vh - 40px)' : `height: calc(100vh - ${165 - scrollTop}px);`"
        >
            <div v-if="!selectedIndicator">
                <div>
                    <RiskSliderBox
                        v-if="riskConfig"
                        :project="project"
                        :assessment="currentAssessment"
                        :risk-config="riskConfig"
                        :matrix-definition="matrixDefinition"
                        @request-project-refresh="$emit('request-project-refresh', $event)"
                        @new-assessment="newDialog.visible = true"
                    />
                </div>
                <div class="mt-4">
                    <RiskComments
                        :project="project"
                        :assessment="currentAssessment"
                        :matrix-definition="matrixDefinition"
                        @request-project-refresh="$emit('request-project-refresh', $event)"
                    />
                </div>
                <div v-if="!currentAssessment.locked" class="mt-4">
                    <FlowRequirements
                        :project="project"
                        :requirements="flowRenderData.requirements"
                    />
                </div>
            </div>
            <div style="height: 100%;" v-if="selectedIndicator && selectedDefinition">
                <IndicatorDetails
                    :key="selectedDefinition.key"
                    :assets="riskAssets"
                    :indicator="selectedIndicator"
                    :matrix="matrixDefinition"
                    :definition="selectedDefinition"
                    :project="project"
                    :assessment="currentAssessment"
                    @close-modal="onSelectIndicator('')"
                    @request-project-refresh="$emit('request-project-refresh', $event)"
                />
            </div>
        </div>
        <NewAssessmentDialog
            v-if="newDialog.visible"
            :project="project"
            @close-modal="newDialog.visible = false"
            @request-project-refresh="$emit('request-project-refresh', $event)"
        />
    </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";
import IndicatorDetails from "@/components/assessment/IndicatorDetails.vue";
import IndicatorList from "@/components/assessment/IndicatorList.vue";
import NewAssessmentDialog from "@/components/assessment/NewAssessmentDialog.vue";
import RiskComments from "@/components/assessment/RiskComments.vue";
import RiskSliderBox from "@/components/assessment/RiskSliderBox.vue";
import { Assessment, RiskIndicator } from "@/lib/assessments/assessments";
import { Project } from "@/lib/projects/projects";
import { getRiskMatrix } from "@/lib/risk/get_matrices";
import { RiskIndicatorDefinition, RiskMatrixDefinition } from "@/lib/risk/risk_matrix";
import { RiskConfig } from "@/lib/settings/settings";
import { FlowRenderData } from "@/types/flow_types";
import { RiskAssets } from "../risk/risk_assets";
import FlowRequirements from "@/components/assessment/FlowRequirements.vue";
import { loadAssessments } from "@/lib/assessments/assessment_helper";

export default defineComponent({
    name: "AssessmentTab",
    components: {
        IndicatorList,
        RiskSliderBox,
        IndicatorDetails,
        RiskComments,
        NewAssessmentDialog,
        FlowRequirements,
    },
    emits: ["request-project-refresh"],
    props: {
        project: { type: Object as PropType<Project>, required: true },
        assessments: { type: Array as PropType<Assessment[]>, required: true },
        flowRenderData: { type: Object as PropType<FlowRenderData>, required: true },
    },
    watch: {
        assessments: {
            deep: true,
            handler() {
                this.loadAssessments();
                this.onSelectIndicator(this.selectedIndicator ? this.selectedIndicator.key : "");
            },
        },
    },
    data() {
        return {
            scrollThreshold: 130,
            scrollTop: 0,
            scrolled: false,
            isLoading: true,
            riskAssets: {} as RiskAssets,
            currentAssessment: {} as Assessment,
            previousAssessments: new Array<Assessment>(),
            matrixDefinition: null as RiskMatrixDefinition | null,
            riskConfig: null as RiskConfig | null,
            selectedIndicator: null as RiskIndicator | null,
            selectedDefinition: null as RiskIndicatorDefinition | null,
            lockDialog: { visible: false },
            newDialog: { visible: false },
            isOverriding: false,
        };
    },
    methods: {
        handleScroll() {
            const element = document.getElementById("aml-main");
            if (element) {
                if (element.scrollTop > this.scrollThreshold && !this.scrolled) {
                    this.scrolled = true;
                }
                if (element.scrollTop <= this.scrollThreshold && this.scrolled) {
                    this.scrolled = false;
                }
                this.scrollTop = element.scrollTop;
            }
        },
        onSelectIndicator(key: string) {
            if (!this.matrixDefinition) {
                this.selectedIndicator = null;
                return;
            }
            for (const i in this.currentAssessment.indicators) {
                if (this.currentAssessment.indicators[i].key === key) {
                    this.selectedIndicator = this.currentAssessment.indicators[i];
                    for (const j in this.matrixDefinition.indicators) {
                        if (this.matrixDefinition.indicators[j].key === this.selectedIndicator.key) {
                            this.selectedDefinition = this.matrixDefinition.indicators[j];
                            return;
                        }
                    }
                }
            }
            this.selectedDefinition = null;
            this.selectedIndicator = null;
        },

        loadAssessments() {
            const loadResult = loadAssessments(this.assessments);
            this.currentAssessment = loadResult.currentAssessment;
            this.previousAssessments = loadResult.previousAssessments;
        },

        async fetchRiskMatrix() {
            this.isLoading = true;
            const response = await getRiskMatrix(this.currentAssessment.matrixKey);
            if (response.responseInfo.isError || !response.matrix) {
                /* this.promoteDialog.isError = true;
                this.promoteDialog.errorText = response.responseInfo.parsedError?.message || 'Unknown error'; */
            } else {
                this.matrixDefinition = response.matrix;
                this.riskAssets = await this.$assets.getRiskAssets();
            }
            this.isLoading = false;
        },
    },
    created() {
        this.loadAssessments();
        this.fetchRiskMatrix();
    },
    mounted() {
        const element = document.getElementById("aml-main");
        if (element) {
            element.addEventListener("scroll", this.handleScroll);
        }
        if (this.$config.settings.riskConfigs) {
            this.riskConfig = this.$config.settings.riskConfigs[0];
        }
    },
    unmounted() {
        const element = document.getElementById("aml-main");
        if (element) {
            element.removeEventListener("scroll", this.handleScroll);
        }
    },
});
</script>
