<template>
    <div>
        <div class="p-5 border border-gray-200 rounded-xl">
            <div class="flex gap-3">
                <div class="flex-1 flex flex-col">
                    <p class="font-bold">
                        <InputLabel
                            :text="$t('newCustomer.selectCustomer')"
                            :required="true"
                            unique-id="company-country"
                        />
                    </p>
                    <p class="text-sm text-light-gray px-1">
                        {{ $t("newCustomer.info") }}
                    </p>
                </div>
                <InputGroup :width="4">
                    <CountryDropdown v-model="config.country" :lowercase="true" />
                </InputGroup>

            </div>

            <div class="my-2 mx-1 border-b border-gray-200"></div>

            <div class="flex flex-col gap-4">
                <CountryCompanySearch
                    v-if="config.country == 'se' && !company.manual"
                    country="se"
                    @company-data-updated="updateCompany"
                    @set-manual="(searchedCompanyName: string) => setManual(searchedCompanyName)"
                />
                <CountryCompanySearch
                    v-if="config.country == 'no' && !company.manual"
                    country="no"
                    @company-data-updated="updateCompany"
                    @set-manual="(searchedCompanyName: string) => setManual(searchedCompanyName)"
                />
                <CountryCompanySearch
                    v-if="config.country == 'fi' && !company.manual"
                    country="fi"
                    @company-data-updated="updateCompany"
                    @set-manual="(searchedCompanyName: string) => setManual(searchedCompanyName)"
                />
                <CountryCompanySearch
                    v-if="config.country == 'dk' && !company.manual"
                    country="dk"
                    @company-data-updated="updateCompany"
                    @set-manual="company.manual = true"
                />
                <OtherCompanySearch
                    v-if="!['se', 'no', 'fi', 'dk'].includes(config.country) || company.manual"
                    :country="config.country"
                    @company-data-updated="updateCompany"
                    :searched-company="searchedCompanyName"
                />

                <div v-if="!company.manual && company.name" class="flex text-sm">
                    <div class="flex-1">
                        <div class="text-gray-500 mb-1">
                            {{ $t("newCustomer.labelCompanyName") }}:
                        </div>
                        <div class="font-bold text-gray-700">
                            {{ company.name }}
                        </div>
                    </div>
                    <div class="flex-1">
                        <div class="text-gray-500 mb-1">
                            {{ $t("newCustomer.labelOrgNumber") }}:
                        </div>
                        <div class="font-bold text-gray-700">
                            {{ company.registrationNumber }}
                        </div>
                    </div>
                </div>
                <ExistingCompanyProcessSelector v-if="projects.length > 0" :projects="projects" />
            </div>
        </div>

        <div class="mt-5 flex gap-5">
            <div class="flex-1 border border-gray-200 rounded-xl p-5">
                <WorkflowSelector
                    class="-mb-2"
                    v-model="flowModel"
                    :conditions="flowConditions"
                />
            </div>
            <div v-if="canCreateConfidentialProject" class="flex-1 border border-gray-200 rounded-xl p-5">
                <div class="-mb-2">
                    <InputLabel
                        :text="$t( 'newCustomer.selectConfidentiality' )"
                        unique-id="confidentiality"
                        :required="true"
                    />
                    <InputRadio
                        v-model="confidentialRadio.selectedKey"
                        :items="confidentialRadio.options"
                        :unique-id="'confidentiality'"
                    />
                </div>
                <div v-if="confidentialRadio.selectedKey == 'confidential'" class="text-sm mt-4 italic">
                    {{ $t( 'newCustomer.confidentialityConfidentialInfo' ) }}
                </div>
            </div>
        </div>

        <div>
            <AsyncStatus :progress="progress" />
        </div>

        <ActionButton
            @btn-clicked="submit"
            :disabled="!canSubmit"
            :loading="progress.isWorking"
            class="mt-8 ml-auto mr-0 block"
        >
            {{ $t("newCustomer.btnAddCompany") }}
        </ActionButton>
    </div>
</template>
<script lang="ts">
import { attachCompanyData, enabledBlocks } from "@/lib/projects/attach_company_data";
import { createProject } from "@/lib/projects/create_project";
import { updateProjectDataBlocks } from "@/lib/projects/data";
import { Project, ProjectType } from "@/lib/projects/projects";
import { FlowConditions } from "@/types/flow_types";
import { AsyncProgress } from "@/types/ui_types";
import AsyncStatus from "@/ui/AsyncStatus.vue";
import { defineComponent } from "vue";
import ActionButton from "../ActionButton.vue";
import Flag from "../Flag.vue";
import CountryCompanySearch from "./CountryCompanySearch.vue";
import OtherCompanySearch from "./OtherCompanySearch.vue";
import WorkflowSelector from "./WorkflowSelector.vue";
import CountryDropdown from "@/ui/inputs/CountryDropdown.vue";
import InputGroup from "@/ui/inputs/InputGroup.vue";
import InputLabel from "@/ui/inputs/InputLabel.vue";
import InputRadio from "@/ui/inputs/InputRadio.vue";
import ExistingCompanyProcessSelector from "./ExistingCompanySelector.vue";
import { queryProjects } from "@/lib/projects/get_project";
import { CheckboxItem } from "@/ui/inputs/input_helper";
import { Policy } from "@/lib/platform";
import { updateCompanyMonitoringStatus } from "@/lib/screening/screen_company";

enum CompanyCountry {
    Sweden = "se",
    Norway = "no",
    Finland = "fi",
    Denmark = "dk",
    Other = "other",
}

export type CompanyData = {
    name: string;
    registrationNumber: string;
    country: string;
    underConstruction: boolean;
    manual: boolean;
};

export default defineComponent({
    name: "CompanyCustomerCreator",
    components: {
        CountryCompanySearch,
        OtherCompanySearch,
        Flag,
        ActionButton,
        WorkflowSelector,
        AsyncStatus,
        CountryDropdown,
        InputGroup,
        InputLabel,
        InputRadio,
        ExistingCompanyProcessSelector,
    },
    computed: {
        canSubmit(): boolean {
            if (this.config.country === CompanyCountry.Other) {
                return !!this.company.name.trim() && !!this.company.registrationNumber.trim();
            } else {
                return !!this.company.name.trim() && !!this.company.registrationNumber.trim();
            }
        },
    },
    data() {
        return {
            CompanyCountry,
            config: {
                country: this.$config.backoffice?.lookup?.defaultCountry || "no",
            },
            company: {
                name: "",
                registrationNumber: "",
                country: "",
                manual: false,
            } as CompanyData,
            confidentialRadio: {
                selectedKey: "regular", // regular/confidential
                options: [
                    {
                        key: "regular",
                        textKey: "newCustomer.confidentialityRegular",
                    },
                    {
                        key: "confidential",
                        textKey: "newCustomer.confidentialityConfidential",
                    },
                ] as CheckboxItem[],
            },
            errorMsg: "",
            flowModel: "",
            flowConditions: {
                type: ProjectType.Company,
                countries: [CompanyCountry.Norway],
                flags: [],
            } as FlowConditions,
            progress: {
                isWorking: false,
                statusText: "",
                isError: false,
                errorText: "",
            } as AsyncProgress,
            projects: [] as Project[],
            searchedCompanyName: "",
            canCreateConfidentialProject: false,
        };
    },
    watch: {
        "config.country"(newValue: string) {
            console.log(newValue);
            this.changeCountry(newValue);
            this.company.manual = false;
            this.projects = [];
        },
        "company.manual"(newValue: boolean) {

        },
    },
    methods: {
        setManual(searchedCompany: string) {
            this.company.manual = true;
            this.searchedCompanyName = searchedCompany;
        },
        changeCountry(country:string) {
            if (["se", "no", "fi", "dk"].includes(country)) {
                this.flowConditions.countries = [this.config.country];
            } else {
                this.flowConditions.countries = [];
            }
        },

        async updateCompany(val: CompanyData) {
            if (val.registrationNumber) {
                const { projects } = await queryProjects(
                    0,
                    0,
                    "",
                    undefined,
                    undefined,
                    undefined,
                    val.registrationNumber,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                );
                this.projects = projects;
            }

            this.company.name = val.name;
            this.company.registrationNumber = val.registrationNumber;
            this.company.country = val.country ?? this.config.country;
            this.company.manual = val.manual;
        },
        async submit() {
            // 1. Create a new project with the company name as project name
            // 2. If customerType is "other", manually set project company data for name, orgNr and country
            // 2. If customerType is not "other", make request to attach project company data from a provider lookup
            // 3. Forward user to the overview of the created project

            // If attaching company data through provider lookup fails (i.e. if the provider is down) we still
            // forward the user to the created project, they will see a warning telling them to retry the
            // company data lookup later

            const confidential = this.confidentialRadio.selectedKey === "confidential";
            this.progress.isError = false;
            this.progress.isWorking = true;
            this.progress.statusText = this.$t("project.creatingProject");

            // Create project
            let project: Project;
            const response = await createProject({
                name: this.company.name,
                type: ProjectType.Company,
                identity: this.company.registrationNumber,
                country: this.company.country.toLowerCase(),
                workflow: this.flowModel,
                confidential,
            });
            if (response.responseInfo.isError || !response.project) {
                this.progress.errorText = response.responseInfo.parsedError?.message || "Unknown Error";
                this.progress.isError = true;
                this.progress.isWorking = false;
                return;
            } else {
                project = response.project;
            }

            console.log("COMPANY", this.company);

            // Manual company data
            if (this.company.manual) {
                try {
                    const res = await updateProjectDataBlocks(project.id, {
                        company_info: {
                            name: this.company.name,
                            registrationNumber: this.company.registrationNumber,
                            country: this.company.country,
                        },
                    });

                    console.log(res);
                } catch (err) {
                    console.error(err);
                }
            }
            // Attach company data with lookup
            else if (this.company.registrationNumber && this.company.country) {
                try {
                    console.log("doing company lookup");
                    const result = await attachCompanyData({
                        projectId: project.id,
                        orgnr: this.company.registrationNumber,
                        country: this.company.country.toLowerCase(),
                        dataBlocks: enabledBlocks,
                    });
                    console.log(result);
                } catch (err) {
                    // The API is responsible for adding warnings to the project if a company lookup fails so we do nothing here
                    console.error(err);
                }
            } else {
                console.log("no orgnr or country specified, skipping company lookup");
            }

            if (this.$config.backoffice?.featureFlags?.autoMonitorCompanyProjects) {
                try {
                    const result = await updateCompanyMonitoringStatus(true, project.id);
                    console.log(result);
                } catch (err) {
                    // Don't stop the creation flow if this fails, just log and carry on
                    console.error(err);
                }
            }

            this.progress.isWorking = false;
            this.$router.push({ path: `/aml/customers/${project.id}/overview` });
        },
    },
    async mounted() {
        const policies = await this.$assets.getPolicies();
        if (policies.includes(Policy.ProjectsRemoveDefaultAclEntries)) {
            this.canCreateConfidentialProject = true;
        }
    },
});
</script>

<style scoped lang="scss">
.company-country-btn {
    @apply py-1 px-3 flex gap-2 items-center rounded-full text-primary-default border border-gray-200 hover:cursor-pointer hover:border-primary-default text-xs font-bold;

    span {
        @apply mr-1;
    }
}

.company-country-btn-active {
    @apply bg-primary-default text-white border-primary-default;
}
</style>
