<template>
    <div>
        <div class="flex flex-wrap mt-2 gap-2">

            <CustomerWorkflowSelector
                :project="project"
                :assessment="currentAssessment"
                @request-project-refresh="$emit('request-project-refresh', $event)"
            />
            <AssigneeSelector
                :project="project"
                @request-project-refresh="$emit('request-project-refresh', $event)"
            />
            <ManagerSelector
                :project="project"
                @request-project-refresh="$emit('request-project-refresh', $event)"
            />
            <CustomerTopAction
                v-if="project.externalId"
                :icon="'key'"
                :text="project.externalId"
                :is-clickable="true"
                :tooltip="$t('project.external.title')"
            />

            <CustomerTopAction
                v-if="project.confidential"
                :icon="'lock'"
                :text="$t('project.access.confidential')"
                :tooltip="$t('project.access.iconTooltip')"
                :is-clickable="true"
                @click="accessPopupOpen = true"
            />
            <CustomerAccessPopup
                v-if="accessPopupOpen"
                :project="project"
                @close="accessPopupOpen = false"
                @request-project-refresh="$emit('request-project-refresh', $event)"
            />

        </div>
        <div class="my-4 pl-1">
            <StatusAndText
                :text="getStatusText()"
                :is-completed="project.assessment.sealed"
            />
        </div>
        <div>
            <StepRenderer
                :render-data="flowRenderData"
                @action="$emit('action', $event)"
                @request-project-refresh="$emit('request-project-refresh', $event)"
            />
        </div>
    </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";
import CustomerWorkflowSelector from "@/components/customer/CustomerWorkflowSelector.vue";
import StepRenderer from "@/components/customer/StepRenderer.vue";
import { Project } from "@/lib/projects/projects";
import { FlowRenderData } from "@/types/flow_types";
import StatusAndText from "@/ui/StatusAndText.vue";
import { LocalizedStringMap } from "@/lib/common/common";
import AssigneeSelector from "@/components/customer/AssigneeSelector.vue";
import ManagerSelector from "@/components/customer/ManagerSelector.vue";
import CustomerTopAction from "@/components/customer/CustomerTopAction.vue";
import { addAccessedProjectToLocalStorage } from "@/lib/common/localStorage";
import { Assessment } from "@/lib/assessments/assessments";
import CustomerAccessPopup from "@/components/customer/CustomerAccessPopup.vue";

export default defineComponent({
    components: {
        StepRenderer,
        CustomerWorkflowSelector,
        StatusAndText,
        AssigneeSelector,
        CustomerTopAction,
        ManagerSelector,
        CustomerAccessPopup,
    },
    emits: ["request-project-refresh", "action"],
    props: {
        project: { type: Object as PropType<Project>, required: true },
        assessments: { type: Array as PropType<Assessment[]>, required: true },
        currentAssessment: { type: Object as PropType<Assessment>, required: true },
        flowRenderData: { type: Object as PropType<FlowRenderData>, required: true },
        workflowTexts: { type: Object as PropType<LocalizedStringMap>, required: true },
    },
    data() {
        return {
            accessPopupOpen: false,
        };
    },
    methods: {
        getStatusText(): string {
            if (this.workflowTexts[this.flowRenderData.statusKey]) {
                return this.$ct(this.workflowTexts[this.flowRenderData.statusKey]);
            }
            return this.flowRenderData.statusKey;
        },
    },
    mounted() {
        addAccessedProjectToLocalStorage(this.project);
    },
});
</script>
<style scoped>

.amlv3-overviewtab-status {
    margin-top: 15px;
    margin-bottom: 15px;
    padding-left: 5px;
}
</style>
