<template>
    <PopupDialog
        :is-visible="dialog.visible"
        :header="'Id Control Details'"
        :icon="'address-card'"
        :progress="dialog"
        :show-cancel-button="true"
        @close-modal="() => $emit('close-modal')"
    >
        <div class="bg-white rounded-lg shadow-lg p-3 max-w-md mx-auto border border-solid border-light space-y-3">
            <div class="bg-primary-selected">
                <span class="text-sm font-medium text-body pl-2">
                    {{ $t('idcontrol.summary') }}
                </span>
            </div>

            <div class="w-full h-[1px] bg-light"></div>

            <div class="grid grid-cols-2 gap-4">
                <div>
                    <p class="text-sm">
                        {{ $t('common.person_info.name') }}
                    </p>
                    <p v-if="idContolData.givenName || idContolData.familyName" class="text-base font-medium text-body break-words">
                        ( {{ idContolData.givenName }} {{ idContolData.familyName }}) :
                    </p>

                    <p v-else class="text-base font-medium text-body break-words">
                        {{ noData }}
                    </p>
                </div>
                <div>
                    <p class="text-sm ">
                        {{ $t('common.person_info.dateOfBirth') }}
                    </p>
                    <p class="text-base font-medium text-body break-words">
                        {{ (!idContolData.dateOfBirth || idContolData.dateOfBirth === "N/A") ? noData : $d(formaDate(idContolData.dateOfBirth), "long") }}
                    </p>
                </div>
                <div>
                    <p class="text-sm ">
                        {{ $t('common.person_info.ssn') }}
                    </p>
                    <p class="text-base font-medium text-body break-words">
                        {{ idContolData.ssn ? idContolData.ssn : noData }}
                    </p>
                </div>
                <!--
                <div>
                    <p class="text-sm ">
                        {{ $t('common.person_info.email') }}
                    </p>
                    <p class="text-base font-medium text-body break-words">
                        {{ idContolData.email ?? noData }}
                    </p>
                </div>
-->
                <div>
                    <p class="text-sm ">
                        {{ $t('idcontrol.method') }}
                    </p>
                    <p class="text-base font-medium text-body break-words first-letter:uppercase">
                        {{ idControlMethodToName(idContolData.method) }}
                    </p>
                </div>
                <!-- <div>
                    <p class="text-sm ">
                        {{ $t('idcontrol.datetime') }}
                    </p>
                    <p class="text-base font-medium text-body break-words">
                        {{ $d(idContolData.updatedAt, "longDateTime") }}
                    </p>
                </div> -->
                <div>
                    <p class="text-sm ">
                        {{ $t('idcontrol.datetime') }}
                    </p>
                    <p class="text-base font-medium text-body break-words">
                        {{ $d(idContolData.updatedAt, "longDateTime") }}
                    </p>
                </div>
                <div v-if="idContolData.comment">
                    <p class="text-sm ">
                        {{ $t('idcontrol.comment') }}
                    </p>
                    <p class="text-base font-medium text-body break-words">
                        {{ idControlMethodToName(idContolData.comment) }}
                    </p>
                </div>
            </div>

            <div class="flex gap-2 border border-solid border-light rounded-sm p-3">
                <div>
                    <p class="text-sm">
                        {{ $t('idcontrol.result') }}: <span class="font-medium">{{ matchIndicatorText }}</span>
                    </p>
                    <!--
                    <div class="flex gap-2 text-primary-default">
                        <Icon icon="report" class="w-4 h-5 self-start" />
                        <a href="" class=" decoration-solid decoration-primary-default">{{ $t('idcontrol.report') }}</a>
                    </div>
                    -->
                </div>
            </div>
        </div>

    </PopupDialog>
</template>
<script lang="ts">
import Icon from "@/components/Icon.vue";
import { defineComponent, PropType } from "vue";
import { DateObject, IDControlDetails } from "@/lib/persons/person";
import PopupDialog from "@/ui/PopupDialog.vue";
import { idControlMethodToName } from "@/lib/common/utility";
import { formatDateObject } from "@/lib/date_object_formatter";

export default defineComponent({
    name: "IdControlDetailsDialog",
    components: {
        Icon,
        PopupDialog,
    },
    data() {
        return {
            dialog: { visible: true, isWorking: false, statusText: "Loading", isError: false, errorText: "" },
            idControlMethodToName,
        };
    },
    emits: ["close-modal"],
    methods: {

    },
    props: {
        idContolData: {
            type: Object as PropType<IDControlDetails>,
            required: true,
            default: () => ({}),
        },
    },
    computed: {
        noData(): string {
            return this.$t("project.tabs.company.blocks.general.valueUndefined");
        },
        formaDate(date: DateObject) {
            return formatDateObject(date);
        },
        matchIndicatorText(): string {
            console.log(this.idContolData);
            switch (this.idContolData.matchIndicator) {
            case 7: return this.$t("common.idControlMatchIndicator.weak_match_name");
            case 6: return this.$t("common.idControlMatchIndicator.partial_match_name");
            case 5: return this.$t("common.idControlMatchIndicator.full_match_name");
            case 4: return this.$t("common.idControlMatchIndicator.match_dob");
            case 3: return this.$t("common.idControlMatchIndicator.weak_match_name_and_dob");
            case 2: return this.$t("common.idControlMatchIndicator.partial_match_name_and_dob");
            case 1: return this.$t("common.idControlMatchIndicator.full_match_name_and_dob");
            case 8: return this.$t("common.idControlMatchIndicator.manual_id");
            default:("common.id");
                return this.$t("common.idControlMatchIndicator.no_match");
            }
        },
    },
    created() {
        console.log("created", this.idContolData);
    },
    mounted() {},
});
</script>
