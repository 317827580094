import { RiskValueType } from "@/lib/risk/risk_matrix";
import { RiskAssets } from "./risk_assets";
import { getCountries } from "./special_types/country";
import { getNaceCodes } from "./special_types/nace_code";
import { getHighRiskCountries } from "./special_types/high_risk_country";
import { LocalizedString } from "@/lib/common/common";
import { getPepHit } from "./special_types/pep_hit";
import { getCompanyTypes } from "./special_types/company_type";

export interface RiskNestedItem {
    level: number;
    scoreKey: string;
    code: string;
    text: LocalizedString;
    expanded: boolean;
    showCode: boolean;
    codeWidth: number;
    codeIsCountry?: boolean;
    items?: RiskNestedItem[];
}

export interface RiskNestedOptions {
    limit: number;
}

export interface RiskNestedListData {
    items: RiskNestedItem[];
    options: RiskNestedOptions;
}

export interface RiskScoreMap {
    [key: string]: string;
}

export const createScoreMapRecursive = (items: RiskNestedItem[], scoreMap: RiskScoreMap, parentKey: string): void => {
    for (const i in items) {
        const item = items[i];
        scoreMap[item.scoreKey] = parentKey;
        if (item.items) createScoreMapRecursive(item.items, scoreMap, item.scoreKey);
    }
};

export const getNestedData = (valueType: RiskValueType, assets: RiskAssets): RiskNestedListData => {
    const data = {
        items: [],
        options: {
            limit: 0,
        },
    };

    if (valueType === RiskValueType.NaceCode) {
        return getNaceCodes(assets);
    }

    if (valueType === RiskValueType.Country) {
        return getCountries(assets);
    }

    if (valueType === RiskValueType.HighRiskCountry) {
        return getHighRiskCountries(assets);
    }

    if (valueType === RiskValueType.PepHit) {
        return getPepHit(assets);
    }

    if (valueType === RiskValueType.CompanyType) {
        return getCompanyTypes(assets);
    }

    return data;
};
