<template>
    <div>
        <PopupDialog
            :is-visible="dialog.visible"
            :header="$t('common.actions.addEditor')"
            :icon="'add'"
            :show-cancel-button="true"
            :show-action-button="true"
            :action-text="$t('common.actions.addEditorYesText')"
            :cancel-button-text="$t('common.actions.addEditorNoText')"
            :progress="dialog"
            @close-modal="onClose"
            @action="addEditor"
        >
            <PopupDialogPrompt
                :prompt-text="$t('project.forms.addEditor.promptText')"
                :info-text="$t('project.forms.addEditor.hintText')"
                :extra-info-text="form.name"
            />
        </PopupDialog>
    </div>

</template>

<script lang="ts">
import { PropType, defineComponent } from "vue";

import PopupDialog from "@/ui/PopupDialog.vue";
import PopupDialogPrompt from "@/ui/dialog/PopupDialogPrompt.vue";
import { Form } from "@/lib/forms/forms";
import { addEditorToForm } from "@/lib/forms/add_form_editor";

export default defineComponent({
    name: "AddFormEditorDialog",
    components: {
        PopupDialog,
        PopupDialogPrompt,

    },
    emits: ["close-modal", "request-project-refresh", "editor-added"],

    props: {
        form: { type: Object as PropType<Form>, required: true },
    },
    data() {
        return {
            dialog: {
                visible: true,
                valid: true,
                isWorking: false,
                statusText: "",
                isError: false,
                errorText: "",
            },
        };
    },
    methods: {
        onClose() {
            this.$emit("close-modal");
        },
        async addEditor () {
            this.dialog.isError = false;
            this.dialog.isWorking = true;
            this.dialog.statusText = "updating form";
            const response = await addEditorToForm(this.form.id);

            if (response.responseInfo.isError) {
                this.dialog.errorText = response.responseInfo.parsedError?.message || "Unknown error";
                this.dialog.isError = true;
            } else {
                this.$emit("request-project-refresh");
                this.dialog.isWorking = false;
                this.$emit("editor-added", this.form);
                this.onClose();
            }
        },
    },
});
</script>
