<template>
    <div class="assessment-attachments">
        <InputCheckbox2
            v-model="checkboxState"
            :items="checkboxItems"
            :unique-id="'attachment-checkbox'"
        />
        <div
            v-if="selectedState.length === 0"
            class="text-red-500 mt-2 text-sm"
        >
            {{ $ct('Please select at least one attachment') }}
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import InputCheckbox2 from "@/ui/inputs/InputCheckbox2.vue";
import { CheckboxItem, CheckboxModel } from "@/ui/inputs/input_helper";

interface AssessmentAttachment {
    attachmentSource: string;
    attachmentSourceId: string;
    attachmentId: string;
    contentType?: string;
    name: string;
}

export default defineComponent({
    name: "AttachmentSelector",

    components: {
        InputCheckbox2,
    },

    props: {
        attachments: {
            type: Array as PropType<AssessmentAttachment[]>,
            required: true,
        }
    },

    emits: ["update:attachments"],

    data() {
        return {
            selectedState: [...this.attachments],
            checkboxState: this.initializeCheckboxState(),
        };
    },

    computed: {
        checkboxItems(): CheckboxItem[] {
            return this.attachments.map(attachment => ({
                key: attachment.attachmentId,
                text: { en_EN: attachment.name },
                textKey: attachment.attachmentSource,
            }));
        }
    },

    methods: {
        initializeCheckboxState(): CheckboxModel {
            const state: CheckboxModel = {};
            this.attachments?.forEach(attachment => {
                state[attachment.attachmentId] = true;
            });
            return state;
        },

        updateSelectedState(checkboxState: CheckboxModel) {
            // Filter attachments based on checkbox state
            this.selectedState = this.attachments.filter(
                attachment => checkboxState[attachment.attachmentId]
            );
            // Emit the filtered attachments
            this.$emit("update:attachments", this.selectedState);
        }
    },

    watch: {
        checkboxState: {
            handler(newState: CheckboxModel) {
                this.updateSelectedState(newState);
            },
            deep: true
        },

        attachments: {
            immediate: true,
            handler(newAttachments: AssessmentAttachment[]) {
                const newCheckboxState = this.initializeCheckboxState();
                this.checkboxState = newCheckboxState;
                this.updateSelectedState(newCheckboxState);
            }
        }
    }
});
</script>
