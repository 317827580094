<template>
    <div class="border border-slate-300 flex flex-col rounded-lg mb-8">
        <div class="relative">
            <HalfDoughnutChart :chart-data="chartData" :chart-options="chartOptions" />
            <div class="absolute top-2/3 left-1/2 transform -translate-x-1/2 pb-4 -translate-y-1/2 text-center">
                <p class="text-3xl font-roboto-slab">
                    {{ totalClients }}
                </p>
                <p>{{ $t("common.totalClients") }}</p>
            </div>
        </div>
        <div class="-mt-10 px-5">
            <div class="flex sm:flex-col justify-around gap-2 w-full pb-5">
                <div
                    v-for="(label, index) in labels"
                    :key="label"
                    class="flex justify-between text-sm"
                >
                    <div class="hidden sm:block">
                        {{ label }}
                    </div>
                    <div class="flex gap-3 items-center justify-between">
                        <div class="w-2 h-2 font-medium rounded-full " :style="`background-color: ${backgroundColor[index]}`"></div>
                        <div class="w-6 text-right">
                            {{ (Math.round(riskCounts[index]/totalClients*100) || 0) + "%" }}
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <!--<div class="grid grid-cols-1 sm:grid-cols-2 shrink-0 gap-4 mb-8">
        <StatBox :value="25" label="Ongoing assesments" />
        <StatBox :value="152" label="Total Customers" />
    </div>-->
</template>

<script lang="ts">
import HalfDoughnutChart from "@/components/HalfDoughnutChart.vue";
import { defineComponent } from "vue";
import StatBox from "./StatBox.vue";
import { getProjectRiskStatistics } from "@/lib/statistics/get_risk_distribution";

export default defineComponent({
    components: {
        HalfDoughnutChart,
        StatBox,
    },
    data() {
        return {
            stats: [] as {risk: string, count: number }[],
        };
    },
    computed: {
        notAssessed(): any {
            return this.$t("assessment.notAssessed");
        },
        labels(): string[] {
            return this.stats.map(s => {
                if (s.risk === "NA") {
                    return this.notAssessed;
                }
                const thresholds = this.$config.settings.riskConfigs?.[0].thresholds;
                return this.$ct(thresholds?.find(t => t.key === s.risk)?.text) ?? this.notAssessed;
            });
        },
        backgroundColor(): string[] {
            const thresholds = this.$config.settings.riskConfigs?.[0].thresholds;
            return this.stats.map(s => {
                return thresholds?.find(t => t.key === s.risk)?.color ?? "gray";
            });
        },
        riskCounts(): number[] {
            return this.stats.map(s => s.count);
        },
        totalClients(): number {
            return this.stats.reduce((acc, curr) => acc + curr.count, 0);
        },
        chartData(): any {
            const data = {
                type: "doughnut",
                labels: [] as string[],
                datasets: [{
                    data: [] as number[],
                    backgroundColor: [] as string[],
                }],
            };
            const stats = this.stats;
            stats.sort((a, b) => b.count - a.count);
            return {
                ...data,
                labels: this.labels,
                datasets: [{
                    data: this.riskCounts,
                    backgroundColor: this.backgroundColor,
                }],
            };
        },
        chartOptions(): any {
            return {
                responsive: true,
                maintainAspectRatio: true,
                cutout: "80%",
                rotation: -90,
                circumference: 180,
                layout: {
                    padding: {
                        left: 20,
                        right: 20,
                        top: 5,
                        bottom: 0,
                    },
                },
                plugins: {
                    title: {
                        display: true,
                        text: this.$t("common.riskDistribution") + ":",
                        position: "top",
                        align: "start",
                        padding: {
                            top: 20, // Reduced top padding
                            bottom: -10,
                            left: 0,
                            right: 0,
                        },
                        font: {
                            size: 18,
                            weight: "normal",
                        },
                    },
                    tooltip: {
                        xAlign: "right",
                        yAlign: "bottom",
                    },
                    legend: {
                        display: false,
                    },
                },
            };
        },
    },
    async created() {
        this.stats = await getProjectRiskStatistics();
    },
});
</script>

<style scoped>
/* You can add some additional styling if needed */
</style>
