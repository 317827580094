import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center justify-between bg-white py-3 rounded-lg mt-0 px-5" }
const _hoisted_2 = { class: "flex flex-1 justify-between sm:hidden" }
const _hoisted_3 = { class: "hidden sm:flex sm:flex-1 sm:items-center sm:justify-between" }
const _hoisted_4 = { class: "text-sm" }
const _hoisted_5 = { class: "font-medium" }
const _hoisted_6 = { class: "font-medium" }
const _hoisted_7 = {
  class: "isolate inline-flex -space-x-px rounded-md",
  "aria-label": "Pagination"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("a", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.prevPage && _ctx.prevPage(...args))),
        class: _normalizeClass(['relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium', _ctx.prevDisabled ? 'text-gray-400 bg-gray-100 cursor-not-allowed' : 'text-gray-700 hover:bg-gray-50'])
      }, _toDisplayString(_ctx.$t('common.pagination.previous')), 3),
      _createElementVNode("a", {
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.nextPage && _ctx.nextPage(...args))),
        class: _normalizeClass(['relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium', _ctx.nextDisabled ? 'text-gray-400 bg-gray-100 cursor-not-allowed' : 'text-gray-700 hover:bg-gray-50'])
      }, _toDisplayString(_ctx.$t('common.pagination.next')), 3)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", null, [
        _createElementVNode("p", _hoisted_4, [
          _createTextVNode(_toDisplayString(_ctx.$t('common.page')) + " " + _toDisplayString(" ") + " ", 1),
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.currentPage), 1),
          _createTextVNode(" " + _toDisplayString(" ") + " " + _toDisplayString(_ctx.$t("common.of")) + " " + _toDisplayString(" ") + " ", 1),
          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.pages.length), 1),
          _createTextVNode(" " + _toDisplayString(" "))
        ])
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("nav", _hoisted_7, [
          _createElementVNode("div", {
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.prevPage && _ctx.prevPage(...args))),
            class: _normalizeClass(['relative inline-flex items-center px-2', _ctx.prevDisabled ? 'text-gray-400 cursor-not-allowed' : 'hover:cursor-pointer text-primary-default'])
          }, [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('common.pagination.previous')), 1)
          ], 2),
          _createVNode(_component_Icon, {
            icon: "vertical-line",
            class: ""
          }),
          _createElementVNode("div", {
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.nextPage && _ctx.nextPage(...args))),
            class: _normalizeClass(['relative inline-flex items-center px-2', _ctx.nextDisabled ? 'text-gray-400 cursor-not-allowed' : 'hover:cursor-pointer text-primary-default'])
          }, [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('common.pagination.next')), 1)
          ], 2)
        ])
      ])
    ])
  ]))
}