import { withKeys as _withKeys, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-32aa1b4a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-col min-w-full" }
const _hoisted_2 = ["id", "value", "placeholder", "readonly"]
const _hoisted_3 = ["id", "type", "value", "placeholder", "readonly", "maxlength"]
const _hoisted_4 = {
  key: 2,
  class: "text-danger-default text-sm p-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isMultiline)
      ? (_openBlock(), _createElementBlock("textarea", {
          key: 0,
          class: "fbav3-input-text",
          id: _ctx.uniqueId,
          value: _ctx.modelValue,
          placeholder: _ctx.placeholder,
          onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args))),
          onKeyup: _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.onEnterClicked && _ctx.onEnterClicked(...args)), ["enter"])),
          rows: 5,
          readonly: _ctx.readonly,
          "aria-multiline": true
        }, null, 40, _hoisted_2))
      : (_openBlock(), _createElementBlock("input", {
          key: 1,
          class: "fbav3-input-text",
          id: _ctx.uniqueId,
          type: _ctx.isPassword ? 'password' : '',
          value: _ctx.modelValue,
          placeholder: _ctx.placeholder,
          onInput: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args))),
          onKeyup: _cache[3] || (_cache[3] = _withKeys(
//@ts-ignore
(...args) => (_ctx.onEnterClicked && _ctx.onEnterClicked(...args)), ["enter"])),
          readonly: _ctx.readonly,
          maxlength: _ctx.maxLength
        }, null, 40, _hoisted_3)),
    (_ctx.error)
      ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.error), 1))
      : _createCommentVNode("", true)
  ]))
}