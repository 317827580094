import i18n from "@/i18n";

const { t, d } = i18n.global;
export const formatDateObject = (dateInput: any) => {
    if (!dateInput) return t("project.tabs.company.blocks.general.valueUndefined");

    const { year, month, day } = dateInput;

    if (year && month && day) {
        return d(new Date(year, month - 1, day), "long");
    } else if (year && month) {
        return d(new Date(year, month - 1), "long");
    } else if (year) {
        return year.toString();
    }

    return t("project.tabs.company.blocks.general.valueUndefined");
};
