import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-sm warning"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AssesmentAttachmentsSelector = _resolveComponent("AssesmentAttachmentsSelector")!
  const _component_PopupDialogInfoIconAndText = _resolveComponent("PopupDialogInfoIconAndText")!
  const _component_PopupDialogSplit = _resolveComponent("PopupDialogSplit")!
  const _component_PopupDialog = _resolveComponent("PopupDialog")!

  return (_openBlock(), _createBlock(_component_PopupDialog, {
    "is-visible": _ctx.dialog.visible,
    header: _ctx.$t('assessment.seal.sealAction'),
    icon: 'file-zipper',
    "show-cancel-button": true,
    "show-action-button": true,
    "action-text": _ctx.$t('assessment.seal.sealAction'),
    "action-icon": 'file-zipper',
    "is-action-disabled": !_ctx.dialog.valid,
    progress: _ctx.dialog,
    onAction: _ctx.lockCurrentAssessment,
    onCloseModal: _ctx.onClose
  }, {
    default: _withCtx(() => [
      _createVNode(_component_PopupDialogSplit, {
        style: {"min-width":"500px","max-width":"560px"},
        "prompt-text": _ctx.$t('assessment.seal.sealAction'),
        "prompt-hint": _ctx.$t('assessment.seal.hint1')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_AssesmentAttachmentsSelector, {
            attachments: _ctx.attachments,
            "onUpdate:attachments": _ctx.handleAttachmentChange
          }, null, 8, ["attachments", "onUpdate:attachments"]),
          _createVNode(_component_PopupDialogInfoIconAndText, {
            "is-warning": !_ctx.dialog.valid,
            text: _ctx.$t(_ctx.dialog.valid ? 'assessment.seal.hint2' : 'assessment.seal.missingRequirements')
          }, null, 8, ["is-warning", "text"])
        ]),
        _: 1
      }, 8, ["prompt-text", "prompt-hint"]),
      (_ctx.showAttachmentWarning)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, " You are about to seal an asssessment without any attachment "))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["is-visible", "header", "action-text", "is-action-disabled", "progress", "onAction", "onCloseModal"]))
}