import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-513fc810"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mt-1" }
const _hoisted_2 = { class: "amlv3-levents-item-icon" }
const _hoisted_3 = { class: "amlv3-levents-item-text" }
const _hoisted_4 = { class: "amlv3-levents-item-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontIcon = _resolveComponent("FontIcon")!
  const _component_MenuInfoLine = _resolveComponent("MenuInfoLine")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.eventsDisplayData, (event, i) => {
      return (_openBlock(), _createElementBlock("div", {
        key: 'mle' + i,
        class: "amlv3-levents-item even:bg-white odd:bg-background py-1 px-2"
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_FontIcon, {
            icon: event.icon,
            size: 12
          }, null, 8, ["icon"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, _toDisplayString(event.title), 1),
          _createVNode(_component_MenuInfoLine, {
            info1: event.dateString,
            info2: event.userName
          }, null, 8, ["info1", "info2"])
        ])
      ]))
    }), 128))
  ]))
}