<template>
    <PopupDialog
        :is-visible="dialog.visible"
        :header="$t('assessment.seal.sealAction')"
        :icon="'file-zipper'"
        :show-cancel-button="true"
        :show-action-button="true"
        :action-text="$t('assessment.seal.sealAction')"
        :action-icon="'file-zipper'"
        :is-action-disabled="!dialog.valid"
        :progress="dialog"
        @action="lockCurrentAssessment"
        @close-modal="onClose"
    >
        <PopupDialogSplit
            style="min-width: 500px; max-width: 560px;"
            :prompt-text="$t('assessment.seal.sealAction')"
            :prompt-hint="$t('assessment.seal.hint1')"
        >
            <AssesmentAttachmentsSelector
                :attachments="attachments"
                @update:attachments="handleAttachmentChange"
            />
            <PopupDialogInfoIconAndText
                :is-warning="!dialog.valid"
                :text="$t(dialog.valid ? 'assessment.seal.hint2' : 'assessment.seal.missingRequirements')"
            />
        </PopupDialogSplit>
        <div v-if="showAttachmentWarning" class="text-sm warning">
            You are about to seal an asssessment without any attachment
        </div>
    </PopupDialog>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Assessment } from "@/lib/assessments/assessments";
import { sealAssessment } from "@/lib/assessments/seal_assessment";
import { Project } from "@/lib/projects/projects";
import PopupDialog from "@/ui/PopupDialog.vue";
import PopupDialogSplit from "@/ui/dialog/PopupDialogSplit.vue";
import PopupDialogInfoIconAndText from "@/ui/dialog/PopupDialogInfoIconAndText.vue";
import { getProjectProcess } from "@/lib/projects/get_project";
import { getAllAssesmentAttachment, AssessmentAttachment } from "@/lib/assessments/get_assessment_attachments";
import AssesmentAttachmentsSelector from "./AssesmentAttachmentsSelector.vue";
import { FlowRenderData } from "@/types/flow_types";

export default defineComponent({
    name: "SealAssessmentDialog",

    components: {
        PopupDialog,
        PopupDialogSplit,
        PopupDialogInfoIconAndText,
        AssesmentAttachmentsSelector,
    },

    emits: ["request-project-refresh", "close-modal"],

    props: {
        project: { type: Object as PropType<Project>, required: true },
        assessment: { type: Object as PropType<Assessment>, required: true },
        flowRenderData: { type: Object as PropType<FlowRenderData>, required: true },
    },

    data() {
        return {
            dialog: {
                visible: true,
                isWorking: false,
                statusText: "",
                isError: false,
                errorText: "",
                valid: false,
            },
            showAttachmentWarning: false,
            attachments: [] as AssessmentAttachment[],
            selectedAttachment: [] as AssessmentAttachment[],
        };
    },

    methods: {
        async lockCurrentAssessment() {
            this.dialog.isError = false;
            this.dialog.isWorking = true;
            this.dialog.statusText = this.$t("assessment.seal.sealProgress");
            if (!this.selectedAttachment.length) {
                this.showAttachmentWarning = true;
            }
            const response = await sealAssessment(this.project.id, this.assessment.id, this.selectedAttachment);
            if (response.responseInfo.isError) {
                this.dialog.errorText = response.responseInfo.parsedError?.message || "Unknown error";
                this.dialog.isError = true;
            } else {
                const projectResponse = await getProjectProcess(this.project.id);
                this.$emit("request-project-refresh", projectResponse);
                this.onClose();
            }

            this.dialog.isWorking = false;
        },

        async getAttachments() {
            this.showAttachmentWarning = false;
            const response = await getAllAssesmentAttachment(this.project.id, this.assessment.id);
            if (response.responseInfo.isError) {
                this.dialog.errorText = response.responseInfo.parsedError?.message || "Unknown error";
                this.dialog.isError = true;
            } else {
                this.attachments = response.attachments;
                this.selectedAttachment = response.attachments;
            }
        },

        handleAttachmentChange(newAttachments: AssessmentAttachment[]) {
            this.selectedAttachment = newAttachments;
        },

        onClose() {
            this.$emit("close-modal");
        },
    },
    watch: {
        showAttachmentWarning() {},
    },
    async mounted() {
        await this.getAttachments();
        let valid = true;
        if (this.flowRenderData.requirements) {
            for (const requirement of this.flowRenderData.requirements) {
                if (!requirement.completed) valid = false;
            }
        }
        this.dialog.valid = valid;
    },
});
</script>
<style>
.warning{
   color: #B62537;
}
</style>
