<template>
    <section aria-describedby="existing company selector" class=" bg-primary-background/30 rounded-lg p-4 space-3">
        <div class="flex gap-3 items-center">
            <Icon icon="existing-process" class="w-10 h-10 p-2 bg-primary-default rounded-full hidden lg:block" />
            <div class="space-2">
                <label class="mb-0 font-bold block">{{ $t('newCustomer.headerExistingCompany') }}</label>
                <span class="text-sm">{{ $t('newCustomer.existingCompanyInstruction') }}</span>
            </div>
        </div>
        <div class="grid grid-cols-2 gap-3 pt-3">
            <div
                v-for="(company, index) in projects"
                :key="index"
                class="bg-white p-3 rounded-md cursor-pointer hover:outline hover:outline-2 outline-primary-default"
                @click="$router.push({ path: `/aml/customers/${company.id}/overview` })"
            >
                <label class="block font-medium m-0 cursor-pointer text-sm">{{ company.name }}</label>
                <label class="block text-gray-500 m-0 cursor-pointer text-xs">{{ workflow(company.workflow) }}</label>
            </div>
        </div>
    </section>
</template>

<script lang="ts">
import { Project } from "@/lib/projects/projects";
import Icon from "../Icon.vue";
import { defineComponent, PropType } from "vue";

export default defineComponent({
    name: "ExistingCompanyProcessSelector",
    components: {
        Icon,
    },
    props: {
        projects: {
            type: [] as PropType<Project[]>,
            required: true,
        },
    },
    data() {
        return {

        };
    },
    methods: {
        workflow(workflow: string): string {
            const workflows = this.$config.settings.workflows;
            const workFlowDetails = workflows?.find(w => w.key === workflow);
            if (workFlowDetails) {
                return this.$ct(workFlowDetails.name);
            }
            return "";
        },
    },

    computed: {

    },
});

</script>
