import { downloadFormFile } from "./types/form";
import { downloadAssessmentFile } from "./types/assessment";
import { PDFDocument } from "pdf-lib";

export enum PdfViewType {
    Form = "form",
    Assessment = "assessment",
}

export enum PdfViewFileType {
    Form = "form",
    Assessment = "assessment",
    Project = "project"
}

export interface PdfViewFile {
    id: string;
    type: PdfViewFileType;
    name: string;
    param1?: string;
    param2?: string;
    file?: File | Blob;
    attachments: PdfViewFile[];
}

export interface PdfViewParams {
    type: PdfViewType;
    files: PdfViewFile[];
    initialId?: string;
}

export const getFile = async (file: PdfViewFile) => {
    switch (file.type) {
    case PdfViewFileType.Form: return await downloadFormFile(file);
    case PdfViewFileType.Assessment: return await downloadAssessmentFile(file);
    }
    return null;
};

export const mergePDFs = async (files: Promise<Blob>[]): Promise<Blob> => {
    if (!files || files.length === 0) {
        throw new Error("No FIles provided");
    }

    const validBlobs = await Promise.all(files.map(async (blobPromise) => {
        try {
            const blob = await blobPromise;
            return blob || null;
        } catch {
            return null;
        }
    }));
    const resolvedFiles = validBlobs.filter((blob): blob is Blob => blob !== null);

    if (resolvedFiles.length === 0) {
        throw new Error("No valid files found to merge");
    }
    const mergedPdf = await PDFDocument.create();

    for (const pdfFile of resolvedFiles) {
        try {
            const pdfBytes = await pdfFile.arrayBuffer();
            const pdf = await PDFDocument.load(pdfBytes);
            const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
            copiedPages.forEach((page) => mergedPdf.addPage(page));
        } catch (error) {
            console.log("Error processing pdf file:", error);
        }
    }
    if (mergedPdf.getPageCount() === 0) {
        throw new Error("No valid PDF pages could be merged");
    }
    const mergedPdfFile = await mergedPdf.save();
    return new Blob([mergedPdfFile], { type: "application/pdf" });
};

export const downloadBlob = (blob: Blob, fileName: string) => {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
};
