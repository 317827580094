import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full border border-solid border-light rounded-lg p-5" }
const _hoisted_2 = { class: "ml-2" }
const _hoisted_3 = ["placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputLabel = _resolveComponent("InputLabel")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_InputGroup = _resolveComponent("InputGroup")!
  const _component_PopupDialog = _resolveComponent("PopupDialog")!

  return (_openBlock(), _createBlock(_component_PopupDialog, {
    "is-visible": true,
    header: _ctx.$t('idcontrol.manualIdHeading'),
    progress: _ctx.dialog,
    "show-cancel-button": true,
    "show-action-button": true,
    "action-text": _ctx.$t('idcontrol.save'),
    onCloseModal: _ctx.onCloseModal,
    icon: 'address-card',
    onAction: _ctx.manuallyAddIdControl
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('idcontrol.manualComment')), 1),
          _createVNode(_component_InputGroup, { width: 12 }, {
            default: _withCtx(() => [
              _createVNode(_component_InputLabel, {
                text: 'SSN',
                "unique-id": 'ssn',
                required: ""
              }),
              _createVNode(_component_InputText, {
                modelValue: _ctx.ssn,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.ssn) = $event)),
                "unique-id": 'ssn',
                placeholder: _ctx.$t('common.socialSecurityNumber'),
                required: "",
                maxlength: "12",
                "value-type": _ctx.TextInputType.Number,
                onValidationError: _ctx.handleSsnValidation
              }, null, 8, ["modelValue", "placeholder", "value-type", "onValidationError"])
            ]),
            _: 1
          }),
          _createVNode(_component_InputGroup, { width: 12 }, {
            default: _withCtx(() => [
              _withDirectives(_createElementVNode("textarea", {
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.comment) = $event)),
                "unique-id": 'comment',
                placeholder: _ctx.$t('idcontrol.comment'),
                class: "border rounded-md border-gray-300 p-2"
              }, null, 8, _hoisted_3), [
                [_vModelText, _ctx.comment]
              ])
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }, 8, ["header", "progress", "action-text", "onCloseModal", "onAction"]))
}