<template>
    <div class="pdf-attachment-sidebar border rounded-lg border-gray-200 p-4">
        <h3 class="font-medium mb-4 text-primary">
            Attachments
        </h3>
        <div
            v-for="attachment in attachments"
            :key="attachment.id"
            class="attachment-item cursor-pointer p-2  rounded flex items-center gap-2 transition-all duration-200 ease-in-out"
            :class="{'bg-blue-50': currentAttachmentId === attachment.id,
                     'hover:bg-blue-100': currentAttachmentId !== attachment.id}"
        >
            <div class="flex items-center gap-2 w-full">
                <div @click.stop="$emit('download', attachment)">
                    <FontIcon :icon="'download'" :size="8" />
                </div>
                <div class="truncate flex-1 min-w-0" @click.stop="$emit('select-attachment', attachment)">
                    <div class="truncate">
                        {{ attachment.name }}
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import FontIcon from "@/ui/FontIcon.vue";
import { PdfViewFile } from "./pdf_view_helper";

export default defineComponent({
    name: "PdfAttachmentSidebar",
    components: {
        FontIcon,
    },
    props: {
        attachments: {
            type: Array as PropType<PdfViewFile[]>,
            required: true,
        },
        currentAttachmentId: {
            type: String,
            default: null,
        },
    },
    emits: ["select-attachment", "download"],
});
</script>

<style scoped>
.pdf-attachment-sidebar {
    width: 13rem;
    overflow-y: auto;
    background-color: rgb(243 244 246);
}
.attachment-item {
    transition: background-color 0.2s ease-in-out;
}
.attachment-item:hover {
    background-color: #ebf8ff;
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
</style>
