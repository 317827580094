import { useI18n } from "vue-i18n";

export const idControlMethodToName = (method: string) => {
    const { t } = useI18n();
    switch (method) {
        case "bankid-no":
            return t("common.norwegian") + " BankID";

        case "bankid-se":
            return t("common.swedish") + " BankID";

        case "bankid-fi":
            return t("common.finnish") + " BankID";

        case "manual":
            return t("common.manual");

        case "unknown":
            return t("common.unknown");

        default:
            return method;
    }
};
