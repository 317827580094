import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 1,
  style: {"padding":"50px 0px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormFrame = _resolveComponent("FormFrame")!
  const _component_ButtonSingle = _resolveComponent("ButtonSingle")!
  const _component_SpinnerVerified = _resolveComponent("SpinnerVerified")!
  const _component_PopupDialog = _resolveComponent("PopupDialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_PopupDialog, {
      "is-visible": _ctx.dialog.visible,
      progress: _ctx.dialog,
      header: _ctx.title,
      icon: 'list-check',
      "show-cancel-button": true,
      "show-action-button": true,
      "is-action-disabled": _ctx.loading || _ctx.state.isLoading,
      "action-text": _ctx.$t('common.actions.save'),
      "action-icon": 'floppy-disk',
      onAction: _ctx.onSave,
      onCloseModal: _ctx.onClose
    }, {
      buttons: _withCtx(() => [
        _createVNode(_component_ButtonSingle, {
          "is-rounded": "",
          disabled: _ctx.isLoading || !_ctx.state.isValid,
          text: _ctx.$t('common.actions.submit'),
          onClicked: _ctx.onSubmitForm,
          "icon-right": "share"
        }, null, 8, ["disabled", "text", "onClicked"])
      ]),
      default: _withCtx(() => [
        (!_ctx.loading)
          ? (_openBlock(), _createBlock(_component_FormFrame, {
              key: 0,
              ref: 'formFrame',
              "form-url": _ctx.formLink,
              onStateChanged: _ctx.onStateChanged,
              onSaved: _ctx.onSaved,
              onSubmitted: _ctx.onSubmitted
            }, null, 8, ["form-url", "onStateChanged", "onSaved", "onSubmitted"]))
          : _createCommentVNode("", true),
        (_ctx.loading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_component_SpinnerVerified, { size: 45 })
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["is-visible", "progress", "header", "is-action-disabled", "action-text", "onAction", "onCloseModal"])
  ]))
}