<template>
    <div class="border rounded-md border-light border-solid p-2 break-all relative">
        <div class="flex gap-3">
            <div :class="'flex-0 w-[4px] rounded-full ' + (falsePositive ? 'bg-gray-300' : 'bg-yellow-500')">
            </div>
            <div class="flex-1 text-sm">
                <div :class="'font-bold hover:cursor-pointer' + (falsePositive ? ' opacity-50' : '')" @click="collapsed = !collapsed">
                    {{ matchName }}
                </div>
                <div class="flex text-[10px] gap-1 justify-start items-center hover:cursor-pointer" @click="collapsed = !collapsed">
                    <div
                        v-if="matchFlags.length"
                        v-for="flag in matchFlags"
                        :key="flag"
                        :class="'border rounded-full px-2 bg-background' + (falsePositive ? ' opacity-50' : '')"
                    >
                        {{ flag }}
                    </div>
                    <div v-if="!matchFlags.length" class="border rounded-full px-1 bg-green-300">
                        {{ $t('project.tabs.persons.screeningHitsDialog.noFlags') }}
                    </div>
                </div>
                <div v-if="!collapsed" class="mt-2 text-xs flex flex-col gap-2">
                    <div>
                        <div>{{ $t('project.tabs.persons.screeningHitsDialog.matchScore') }}</div>
                        <div v-if="hit?.hit?.score && hit?.hit?.scoreDescription" class="font-bold">
                            {{ hit?.hit?.score || $t('project.tabs.persons.screeningHitsDialog.unknown') }} - {{ hit?.hit?.scoreDescription }}
                        </div>
                    </div>

                    <div v-if="hit?.hit?.birthdates?.length">
                        <div>{{ $t('project.tabs.persons.screeningHitsDialog.birthdate') }}</div>
                        <div v-for="dob in hit?.hit?.birthdates" class="font-bold">
                            {{ dob?.year || "----" }}/{{ dob?.month || "--" }}/{{ dob?.day || "--" }}
                        </div>
                    </div>

                    <div v-if="hit?.hit?.gender">
                        <div>{{ $t('project.tabs.persons.screeningHitsDialog.gender') }}</div>
                        <div class="font-bold">
                            {{ hit?.hit?.gender || $t('project.tabs.persons.screeningHitsDialog.unknown') }}
                        </div>
                    </div>

                    <div v-if="hit?.hit?.nationalities?.length">
                        <div>{{ $t('project.tabs.persons.screeningHitsDialog.nationalities') }}</div>
                        <div class="font-bold">
                            {{ hit?.hit?.nationalities?.map((x: string) => $t("countries." + x.toUpperCase())).join(", ") }}
                        </div>
                    </div>

                    <div v-if="hit?.hit?.lists?.length">
                        <div>{{ $t('project.tabs.persons.screeningHitsDialog.sanctionLists') }}</div>
                        <div v-for="list in hit?.hit?.lists" class="font-bold ">
                            {{ list }}
                        </div>
                    </div>

                    <div v-if="hit?.hit?.roles?.length">
                        <div>{{ $t('project.tabs.persons.screeningHitsDialog.roles') }}</div>
                        <div v-for="role in hit?.hit?.roles?.filter(x => x.description)" class="bg-background rounded-md mb-2 p-2">
                            <div class="font-bold">
                                {{ role?.description }}
                            </div>
                            <div>{{ $t('project.tabs.persons.screeningHitsDialog.roleActive') }} <span class="font-bold">{{ role?.isActive === true ? $t('project.tabs.persons.screeningHitsDialog.yes') : role?.isActive === 'false' ? $t('project.tabs.persons.screeningHitsDialog.no') : $t('project.tabs.persons.screeningHitsDialog.unknown') }}</span></div>
                            <div>{{ $t('project.tabs.persons.screeningHitsDialog.from') }} <span class="font-bold">{{ role?.sinceYear || '----' }}/{{ role?.sinceMonth || '--' }}/{{ role?.sinceDay || '--' }}</span> {{ $t('project.tabs.persons.screeningHitsDialog.to') }} <span class="font-bold">{{ role?.toYear || '----' }}/{{ role?.toMonth || '--' }}/{{ role?.toDay || '--' }}</span></div>
                        </div>
                    </div>

                    <div v-if="hit?.hit?.relations?.length">
                        <div>{{ $t('project.tabs.persons.screeningHitsDialog.relations') }}</div>
                        <div v-for="relation in hit?.hit?.relations" class="bg-background rounded-md mb-2 p-2">
                            <div class="font-bold">
                                {{ relation.firstName }} {{ relation.lastName }}
                            </div>
                            <br>
                            <div>{{ $t('project.tabs.persons.screeningHitsDialog.relationType') }} <span class="font-bold">{{ relation.relationType }}</span></div>
                            <div>{{ $t('project.tabs.persons.screeningHitsDialog.relationActive') }} <span class="font-bold">{{ relation.isActive ? $t('common.yes') : $t('common.no') }}</span></div>
                            <div>{{ $t('project.tabs.persons.screeningHitsDialog.relationPep') }} <span class="font-bold">{{ relation.isPep ? $t('common.yes') : $t('common.no') }}</span></div>
                            <div>{{ $t('project.tabs.persons.screeningHitsDialog.relationSanction') }} <span class="font-bold">{{ relation.isSanction ? $t('common.yes') : $t('common.no') }}</span></div>
                            <div>{{ $t('project.tabs.persons.screeningHitsDialog.relationSip') }} <span class="font-bold">{{ relation.isSip ? $t('common.yes') : $t('common.no') }}</span></div>
                        </div>
                    </div>

                    <div v-if="hit?.hit?.sources?.length">
                        <div class="font-bold">
                            {{ $t('project.tabs.persons.screeningHitsDialog.sources') }}
                        </div>
                        <div class="flex flex-col gap-1">
                            <div v-for="source in hit?.hit?.sources" class="">
                                {{ source }}
                            </div>
                        </div>
                    </div>

                    <div v-if="hit?.hit?.notes?.length">
                        <div class="font-bold">
                            {{ $t('project.tabs.persons.screeningHitsDialog.notes') }}
                        </div>
                        <div class="flex flex-col gap-1">
                            <div v-for="note in hit?.hit?.notes" class="">
                                {{ note }}
                            </div>
                        </div>
                    </div>

                    <div v-if="hit?.hit?.images?.length">
                        <div class="font-bold">
                            {{ $t('project.tabs.persons.screeningHitsDialog.images') }}
                        </div>
                        <div class="flex flex-col gap-1">
                            <div v-for="image in hit?.hit?.images" class="">
                                {{ image }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex items-start text-xs pt-[12px] gap-2 px-2 absolute right-[50px]">
                <input
                    type="checkbox"
                    :id="hit.id"
                    v-model="falsePositive"
                />
                <label class="mt-[-2px]" :for="hit.id">{{ $t('screening.falsePositive') }}</label>
            </div>
            <div @click="collapsed = !collapsed" class="flex p-2 mr-2 text-gray-400 hover:cursor-pointer absolute right-[5px]">
                <FontIcon
                    v-if="collapsed"
                    icon="caret-up"
                    :size="24"
                />
                <FontIcon
                    v-if="!collapsed"
                    icon="caret-down"
                    :size="24"
                />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import FontIcon from "@/ui/FontIcon.vue";

export default defineComponent({
    name: "ScreeningHit",
    components: {
        FontIcon,
    },
    props: {
        hit: {
            type: Object,
            required: true,
        },
    },
    computed: {
        matchName(): string {
            return this.hit?.match?.firstName + " " + this.hit?.match?.lastName;
        },
        matchFlags(): string[] {
            const flags: string[] = [];

            if (this.hit?.match?.pep) {
                flags.push("PEP");
            }

            if (this.hit?.match?.sanction) {
                flags.push("Sanction");
            }

            if (this.hit?.match?.sip) {
                flags.push("SIP");
            }

            if (this.hit?.match?.rca) {
                flags.push("RCA");
            }

            return flags;
        },
    },
    data() {
        return {
            collapsed: true,
            falsePositive: this.hit.falsePositive || false,
        };
    },
    emits: ["updateFalsePositive"],
    watch: {
        hit: {
            deep: true,
            handler: function (val) {
                this.falsePositive = val.falsePositive;
            },
        },
        falsePositive(value) {
            this.$emit("updateFalsePositive", value);
        },
    },
});
</script>

<style scoped>
.border-yellow-200 {
    border-color: #FDE68A;
}
.bg-yellow-50 {
    background-color: #FFFBEB;
}
</style>import { match } from "assert";
