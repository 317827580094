import { AssessmentAttachment } from "@/lib/assessments/get_assessment_attachments";
import { PdfViewFile, PdfViewFileType, PdfViewParams, PdfViewType } from "../pdf_view_helper";
import { downloadAssessmentReport, getAssesmentReport } from "@/lib/assessments/seal_assessment";

export const getAssessmentFileViewParams = (projectId: string, assessmentId: string): PdfViewParams => {
    const params: PdfViewParams = {
        type: PdfViewType.Assessment,
        files: [
            {
                id: assessmentId,
                type: PdfViewFileType.Assessment,
                name: "Assessment",
                param1: projectId,
                param2: assessmentId,
                attachments: [],
            },
        ],
        initialId: "",
    };
    return params;
};

export const downloadAssessmentFile = async (file: PdfViewFile) => {
    const projectId = file.param1;
    const assessmentId = file.param2;
    let attachedFiles = null;

    if (!projectId || !assessmentId) return null;

    const { url, attachments, responseInfo } = await getAssesmentReport(projectId, assessmentId);
    if (!responseInfo.isError && url) {
        const response = await downloadAssessmentReport(url);
        console.log("response", response);
        console.log(attachments, "Attachments")
        if (attachments && attachments.length) {
            attachedFiles = await Promise.all(
                attachments.map(async (attachment: AssessmentAttachment) => {
                    const file = await downloadAssessmentReport(attachment.url);
                    return {
                        id: attachment.attachmentSourceId,
                        name: attachment.name,
                        file,
                    };
                }),
            );
        }

        if (response.file) {
            return {
                file: response.file,
                attachments: attachedFiles,
            };
        }
    }
    return null;
};
