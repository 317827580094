import { LocalizedString } from "@/lib/common/common";
import { RiskValue, RiskValueType } from "@/lib/risk/risk_matrix";

export interface RiskSourceKeyItem {
    key: string;
    name: string;
    text: LocalizedString;
    hint: string;
    type: RiskValueType;
    values?: RiskValue[];
}

export const isValidSource = (source: RiskSourceKeyItem, value: RiskValueType): boolean => {
    if (!value) return true;

    const types = [
        {
            type: RiskValueType.HighRiskCountry,
            accepted: [
                RiskValueType.Country,
            ],
        },
    ];

    if (source.type === value) return true;

    for (const type of types) {
        if (type.type === value) {
            if (type.accepted.includes(source.type)) return true;
        }
    }

    return false;
};

export const getInputValueType = (value: RiskValueType): RiskValueType => {
    console.log(value);
    if (value === RiskValueType.HighRiskCountry) return RiskValueType.Country;
    if (value === RiskValueType.PepHit) return RiskValueType.YesNo;
    if (value === RiskValueType.Listed) return RiskValueType.YesNo;
    if (value === RiskValueType.CompanyType) return RiskValueType.CompanyType;
    if (value === RiskValueType.Turnover) return RiskValueType.Turnover;
    return value;
};

export const getCompanySources = (): RiskSourceKeyItem[] => {
    const list: RiskSourceKeyItem[] = [];

    list.push({
        key: "reg-country",
        name: "Country of Registration",
        text: { en_EN: "Country of Registration" },
        hint: "The country in which the company is registered",
        type: RiskValueType.Country,
    });

    list.push({
        key: "nace-codes",
        name: "Business Codes",
        text: { en_EN: "Business Codes" },
        hint: "A list of business codes (NACE/SNI)",
        type: RiskValueType.NaceCode,
    });

    list.push({
        key: "listed",
        name: "Listed",
        text: { en_EN: "Listed" },
        hint: "Checks if the Company is listed",
        type: RiskValueType.YesNo,
    });

    list.push({
        key: "company-type",
        name: "Company Type",
        text: { en_EN: "Company Type" },
        hint: "The type of company",
        type: RiskValueType.CompanyType,
    });

    list.push({
        key: "registration-date",
        name: "Registration Date",
        text: { en_EN: "Registration Date" },
        hint: "The date the company was registered",
        type: RiskValueType.DateAge,
    });

    list.push({
        key: "turnover",
        name: "Turnover",
        text: { en_EN: "Turnover" },
        hint: "Company last reported turnover",
        type: RiskValueType.Number,
    });
    return list;
};

export const getRoleSources = (): RiskSourceKeyItem[] => {
    const list: RiskSourceKeyItem[] = [];

    list.push({
        key: "residency",
        name: "Country of Residence",
        text: { en_EN: "Country of Residence" },
        hint: "Country of Residence",
        type: RiskValueType.Country,
    });

    list.push({
        key: "citizenship",
        name: "Citizenship",
        text: { en_EN: "Citizenship" },
        hint: "Citizenship/Nationality",
        type: RiskValueType.Country,
    });

    list.push({
        key: "pep",
        name: "PEP Hit",
        text: { en_EN: "PEP Hit" },
        hint: "Checks if the role has a PEP hit",
        type: RiskValueType.PepHit,
    });
    return list;
};
